import React, { useEffect, useState } from "react";
import { Col, Container, Form, Image, Row } from "react-bootstrap";
import { from, fromEvent, of, Subject } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import DynamicHead from "../../components/dynamicHead/dynamicHead";
import Footer from "../../components/footer/footer";
import MoreInformation from "../../components/moreInformation/moreInformation";
import NavBar from "../../components/navBar/navBar";
import ProcessesGrid from "../../components/processesGrid/processesGrid";
import ProcessesGridLoader from "../../components/processesGrid/processesGridLoader";
import utils from "../../utils";
import {
  getUser,
  getCategories,
  getSiteConfig,
  getAllProcess,
} from "../../services/sendRequest";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth/Auth";

interface IAuthor {
  classId: string;
  name: string;
  objectId: string;
  photo: any;
}

const Author = (props) => {
  const pageSize = 9;
  const authorId = props.match.params.uIdentifier;

  const [pageIndex, setPageIndex] = useState<number>(0);
  const [processes, setProcesses] = useState<any>(null);
  const [categories, setCategories] = useState<any[] | null>(null);

  const [alternativeUrls, setAlternativeUrls] = useState<Array<any>>([]);
  const [t, i18n] = useTranslation();

  const [filterCategory, setFilterCategory] = useState<string>("");

  const [sortRef, setSortRef] = useState<HTMLSelectElement | null>(null);
  const [filterSort, setFilterSort] = useState("Mais downloads");

  const { isAuthenticated, user } = useAuth();
  const [author, setAuthor] = useState<IAuthor>({
    classId: "",
    name: "",
    objectId: "",
    photo: {},
  });

  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState("");

  const [showDraft, setShowDraft] = useState(false);

  const [showDraftButton, setShowDraftButton] = useState(true);

  const processesSubject = new Subject<any>();
  const [, setSub] = useState<any>();

  useEffect(() => {
    processesSubject
      .pipe(map(obs => {
        setSub(sub => {
          sub && sub.unsubscribe();
          return obs.subscribe((newProcesses: any) => {
            setProcesses(processes => {
              if (processes && pageIndex) {
                newProcesses.processes = processes.processes.concat(newProcesses.processes);
              }
              return newProcesses;
            });
          });
        })
      }))
      .subscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processesSubject]);

  // Reset Page
  useEffect(() => {
    setProcesses(null);
    setCategories(null);
    setPageIndex(0);

    getUser(authorId).subscribe((user) => {
      setAuthor({
        name: user?.name,
        classId: user?._class._id,
        objectId: user?._id,
        photo: user?.photo,
      });
    });
  }, [authorId]);

  // Reset page by filters
  useEffect(() => {
    setProcesses(null);
    setCategories(null);
    setPageIndex(0);
  }, [showDraft, filterCategory, filterSort]);

  // Reset page by language
  useEffect(() => {
    from(getCategories()).subscribe(setCategories);
  }, [i18n.language]);

  useEffect(() => {
    if (isAuthenticated && showDraftButton && author.objectId && user?.code === author.objectId)
      setShowDraft(true);
    else
      setShowDraft(false);
  }, [showDraftButton, isAuthenticated, user, author]);

  useEffect(() => {
    let sortParams = utils.getOptionFilterField(filterSort);
    let filters: any = {
      sort: {
        [sortParams.optionField]: { order: sortParams.optionSort },
      },
      query: {
        bool: {
          must: [
            { term: { "author._id": author.objectId } },
            { term: { "language.code": i18n.language.substr(0, 2) || "en" } }
          ],
        },
      },
    };

    if (!showDraft) {
      filters.query.bool.must.push({ term: { "isPublished": true } });
    }

    if (filterCategory) {
      filters.query.bool.must.push({
        term: {
          "category._id": filterCategory,
        },
      });
    }

    let params = {
      pageIndex: pageIndex,
      pageSize: pageSize,
    };

    let obs = from(getAllProcess(filters, params))
      .pipe(map((response) => response.result || {}));

    processesSubject.next(obs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDraft, pageIndex, filterCategory, filterSort, i18n.language, author])

  useEffect(() => {
    async function fetchData() {
      let siteConfig = await getSiteConfig({
        string: author.name,
        identifier: "default",
      });
      if (siteConfig) {
        setTitle(siteConfig.title);
      }
      setAlternativeUrls(await utils.setAlternativeUrls(i18n.language));
    }
    fetchData();
  }, [author, i18n.language]);

  useEffect(() => {
    if (sortRef) {
      fromEvent<Event>(sortRef, "change")
        .pipe(
          switchMap((e: Event) => of(sortRef)),
          map((el: HTMLSelectElement) => el.value)
        )
        .subscribe((value) => {
          if (value.length < 1) return;
          setPageIndex(0);
          setFilterSort(value);
        });
    }
  }, [sortRef]);

  useEffect(() => {
    if (processes) {
      setLoading(false);
    }
  }, [processes]);

  const optionsArr = [
    {
      value: "Mais recentes",
      key: t("OPTION_FILTER_KEY_MOST_RECENT"),
    },
    {
      value: "Mais vistos",
      key: t("OPTION_FILTER_KEY_MOST_VIEWED"),
    },
    {
      value: "Melhores avaliações",
      key: t("OPTION_FILTER_KEY_BEST_RATED"),
    },
    {
      value: "Mais downloads",
      key: t("OPTION_FILTER_KEY_MORE_DOWNLOADS"),
    },
  ];

  const filterBar = (
    <Col xs={12} sm={4}>
      <Form.Group>
        <Form.Control
          as="select"
          size="sm"
          custom
          className="form-control-main-drop"
          value={filterCategory}
          //ref={el => setCategoryRef(el)}
          onChange={(e) => {
            setFilterCategory(e.target.value);
            setLoading(true);
          }}
        >
          <option value="">{t("CATEGORY_FILTER_ALL")}</option>
          {categories?.map((category, index) =>
            category.enabled ? (
              <option
                id={category._id}
                key={category._id}
                value={category._id}
                data-name={category.name}
              >
                {category.name}
              </option>
            ) : null
          )}
        </Form.Control>
      </Form.Group>
    </Col>
  );

  const sortBar = (
    <Col xs={12} sm={4}>
      <Form.Group>
        <Form.Control
          as="select"
          size="sm"
          custom
          className="form-control-main-drop"
          ref={(el: any) => setSortRef(el)}
          value={filterSort}
          onChange={() => { }}
        >
          {optionsArr.map((option) => {
            return (
              <option key={option.key} value={option.value}>
                {t(utils.getOptionFilterTitleTag(option.value))}
              </option>
            );
          })}
        </Form.Control>
      </Form.Group>
    </Col>
  );

  return (
    <div id="author">
      <DynamicHead
        title={title}
        description=""
        url={window.location.origin + window.location.pathname}
        imgUrl=""
        alternativeUrlsData={alternativeUrls}
      />

      <NavBar type={"Process"} searchURL="/search" hasSearchBox alternativeUrlsData={alternativeUrls} />
      <hr />
      <Container>
        <h1 className="mb-4">Meus processos</h1>
        <div className="author-info d-flex flex-row align-items-center mb-3">
          {author.photo && author.photo._id ? (
            <Image
              roundedCircle
              className="circular-avatar-navbar author-image"
              src={utils.getPersonImageUrl(author.objectId)}
              alt={author.photo.name}
            />
          ) : (
            <div className="avatar clickable">
              <svg height="50" id="Light" width="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <defs/>
                <title>single-neutral</title>
                <circle cx="12" cy="6.75" fill="none" r="5.5" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3,22.75a9,9,0,0,1,18,0Z" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
          )}

          <h4>{author.name}</h4>
        </div>

        <Row className="process-view-header-container align-items-center">
          {sortBar}
          {filterBar}
          {isAuthenticated && user?.code === author.objectId ?
            <Col xs={12} sm={4}>
              <Form.Group>
                <Form.Check
                  type="switch"
                  id="draft-switch"
                  defaultChecked={true}
                  label={t("SHOW_DRAFTS_SWITCH_LABEL")}
                  onChange={e => { setShowDraftButton(e.target.checked); }
                  }
                />
              </Form.Group>
            </Col>
            : null}
        </Row>

        <div id="process-view">
          {loading ? (
            <ProcessesGridLoader numCards={pageSize} />
          ) : (
            <ProcessesGrid
              processesObj={processes}
              message="AUTHOR_NO_PROCESS"
            />
          )}

          <Row className="mt-5">
            <Col>
              {processes?.processes.length !== processes?.total &&
                (pageIndex + 1) * pageSize < processes?.total ? (
                <span className="link-see-more clickable" onClick={() => setPageIndex(index => index + 1)}>
                  {t("LOAD_MORE")}
                </span>
              ) : null}
            </Col>
          </Row>
        </div>



        <MoreInformation />
      </Container>

      <Footer />
    </div>
  );
};

export default Author;
