import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const ProcessesGridLoader = (props: any) => {
  const [numCards, setNumCards] = useState(6);

  useEffect(() => {
    setNumCards(props.numCards || 6);
  }, [props]);

  function range(n) {
    return [...Array(numCards).keys()]
  }

  return (
    <Row className="m-n5">
      <div id="process-view">
        <div id="processViewLoader" className="flex-gap-column-4">
          {range(numCards).map(index => (
            <Col key={index} xs={12} sm={12} md={6} lg={4} className="p-4">
              <Card className="process-card-loader m-auto">
                <div className="process-view-card-image animated-background"></div>
                <Card.Body className="card-footer-proc">
                  <div className="process-view-card-body">
                    <div className="proc-text animated-background cardTitle"></div>
                    <div className="pac-text animated-background cardCategory"></div>
                  </div>
                  {/* TODO: Divider */}
                  <div></div>
                  <div className="loader-footer-container d-flex flex-row">
                    <div className="assessment">
                      <div className="animated-background cardStars"></div>
                    </div>
                    <div className="assessment">
                      <div className="animated-background cardViews"></div>
                    </div>
                    <div className="assessment">
                      <div className="animated-background cardViews"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </div>
      </div>
    </Row>
  );
}

export default ProcessesGridLoader;