import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';

class GenericCarousel extends Component<any, any> {
    carouselRef: React.RefObject<any>;
    constructor(props: any)
    {
        super(props);
        this.state = {
            index: 0
        }
        this.carouselRef = React.createRef();
    }
    
    prev() {
        this.carouselRef.current?.prev();
    }
    
    next() {
        this.carouselRef.current?.next();
    }
    
    handleChange(index) {
        this.setState({ index: index });
        this.props.onChange?.(index);
    }
    
    render() {
        const {index} = this.state;
        return (
            <div id="generic-carousel">
                <Carousel
                    indicators={false}
                    controls={false}
                    activeIndex={index}
                    ref={this.carouselRef}
                    onSelect={this.handleChange.bind(this)}
                >
                    {this.props.images?.length ? 
                        this.props.images?.map((image, index) => (
                            <Carousel.Item key={index}>
                                <img
                                    className="d-block w-100"
                                    src={image.src}
                                    alt={image.alt}
                                    title={image.alt?.split("-").slice(-1)[0].trim()}
                                />
                            </Carousel.Item>
                        ))
                    : 
                        <Carousel.Item key={index}>
                            <div className="h-100 w-100 d-flex align-items-center">
                                <p className="w-100 text-center">{this.props?.emptyText}</p>
                            </div>
                        </Carousel.Item>
                    }
                </Carousel>
            </div>
        );
    }
}

export default GenericCarousel;