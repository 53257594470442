import React from "react";
import { Col, OverlayTrigger, Row, Tooltip, Badge } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import ShareButtons from "../share-buttons/share-buttons";
import { useAuth } from "../../auth/Auth";

const ProcessNavBar = (props) => {

  const {
    name,
    category,
    viewCount,
    downloadCount,
    processId,
    isPublished,
    editing
  } = props;

  const [t] = useTranslation();
  const { user } = useAuth();

  return (
    <div id="process-navbar">
      <div className="header-process-background">
        <div className="header-process-default">
          <Row>
            <Col xs={12} md={6}>
              <div className="header-process-breadcrumbs">
                <a
                  className="header-process-breadcrumb-left-item header-process-breadcrumbs-text"
                  href="/"
                >
                  Home{" "}
                </a>
                &gt;
                {category && isPublished && !editing ?
                  <a
                    className="header-process-breadcrumb-right-item header-process-breadcrumbs-text"
                    href={`/c/${category.identifier}-${category._id}`}
                  >
                    {category.name}
                  </a>
                  : editing ?
                    <span
                      className="header-process-breadcrumb-right-item header-process-breadcrumbs-text"                      
                    >
                      {t("BPMN_EDITOR_TITLE")}
                    </span>
                  :
                    <a
                      className="header-process-breadcrumb-right-item header-process-breadcrumbs-text"
                      href={`/user-${user?.code}`}
                    >
                      {t("NAVBAR_USER_MENU_MY_PROCESSES")}
                    </a>
                }
              </div>
              <h1 className="object-text">{name} </h1> 
              { isPublished || !processId ? null : <Badge variant="info">{t("DRAFT_LABEL")}</Badge>}              
            </Col>

            <Col xs={12} md={6} className="d-flex justify-content-between justify-content-md-end align-items-center flex-wrap">
              {name !== t('UPLOAD_TITLE') && !editing ?
                <>
                  <div className="d-flex flex-row">
                    {/* Visualizações */}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="tooltip-views">{t('VIEWS')}</Tooltip>}
                    >
                      <div className="header-process-info-itens">
                        <svg height="20" width="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <g>
                            <circle cx="12" cy="12" fill="none" r="3.5" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M23.38,11.67C22.21,10.35,17.56,5.5,12,5.5S1.79,10.35.62,11.67a.52.52,0,0,0,0,.66C1.79,13.65,6.44,18.5,12,18.5s10.21-4.85,11.38-6.17A.52.52,0,0,0,23.38,11.67Z" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
                          </g>
                        </svg>
                        <span className="numb">{viewCount}</span>
                      </div>
                    </OverlayTrigger>
                    {/* Downloads */}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="tooltip-downloads">Downloads</Tooltip>}
                    >
                      <div className="header-process-info-itens ml-2">
                        <svg height="20" width="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <defs/>
                          <title>download-bottom</title>
                          <path d="M23,18.218v1.913A2.87,2.87,0,0,1,20.131,23H3.869A2.869,2.869,0,0,1,1,20.131V18.218" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
                          <line fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" x1="12" x2="12" y1="18.108" y2="1"/>
                          <polyline fill="none" points="19 11.108 12 18.108 5 11.108" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span className="numb">{downloadCount}</span>
                      </div>
                    </OverlayTrigger>

                  </div>

                  <div className="d-flex flex-row">
                    {/* TODO: COMMENT HOM */}
                    {/* <div className="contorn-button header-process-info-itens-follow ml-2">
                      <span className="follow-text">Seguir</span>
                      <StreamlineIcon icon={Add} size={14} stroke="#34434E" />
                    </div> */}

                    {processId ? <ShareButtons processId={processId}></ShareButtons> : null}
                  </div>
                </>
                : null}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
export default ProcessNavBar;
