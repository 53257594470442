import React, { Fragment } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import RoutePrivate from './auth/RoutePrivate';
import history from './auth/history';
import Author from './pages/author/author';
import Home from './pages/home/home';
import Login from './pages/login/login';
import Process from './pages/process/process';
import Search from './pages/search/search';
import Categories from './pages/categories/categories';
import TagsPage from './pages/tags/tags';
import ResourcesHome from './pages/resources/home/home';
import RouteResources from './pages/resources/RouteResources';
import ResourcesSearch from './pages/resources/search/search';
import ScrollToTop from './routing/scroll-to-top';
import ErrorPage from './pages/errorPage/errorPage';
import BpmnEditor from './pages/bpmnEditor/bpmnEditor';
import UploadProcess from './pages/uploadProcess/uploadProcess';

export default function Routes() {
  return (
    <Router history={history}>
      <Fragment>
        <ScrollToTop />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/login" exact component={Login} />
          <Route path="/p/:pIdentifier-:pId([0-9a-f]{24})" exact component={Process} />
          <Route path="/c/:cIdentifier-:cId([0-9a-f]{24})" exact component={Categories} />
          <Route path="/t/:tIdentifier-:tId([0-9a-f]{24})" exact component={TagsPage} />
          <Route path="/search" exact component={Search} />
          <Route path="/user-:uIdentifier" exact component={Author} />
          <Route path="/blog" exact component={ResourcesHome} />
          <Route path="/blog/search" exact component={ResourcesSearch} />
          <RouteResources path="/blog/:rIdentifier-:rId([0-9a-f]{24})" exact />
          <RoutePrivate path="/upload/:pId([0-9a-f]{24})" exact component={UploadProcess} />
          <RoutePrivate path="/edit/:pId([0-9a-f]{24})" exact component={UploadProcess} />
          <RoutePrivate path="/bpmn-editor" exact component={BpmnEditor} key={Date.now()}/>
          <RoutePrivate path="/bpmn-editor/:pId([0-9a-f]{24})?" exact component={BpmnEditor} />
          <Route path="/404" exact component={ErrorPage} />
          <Redirect to="/404" />
        </Switch>
      </Fragment>
    </Router>
  );
}
