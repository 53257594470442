import React, { useEffect, useReducer, useState } from 'react';
import { Form } from 'react-bootstrap';
import { fromEvent } from "rxjs";
import { getFileObj, File } from './uploadProcess';

// File[] => FileList
// function FileListItems(files) {
//     var b = new ClipboardEvent("").clipboardData || new DataTransfer();
//     for (var i = 0, len = files.length; i < len; i++) b.items.add(files[i]);
//     return b.files;
// }

const InputImages = (props: any) => {

    const reducer = (state, action) => {
        switch(action.type) {
            case 'append': {
                return state.concat(action.files);
            }
            case 'remove': {
                return state.filter((value, i) => i !== action.index);
            }
            case 'erase': {
                return [];
            }
            case 'set': {
                return action.files;
            }
        }
    };

    const [refButton, setRefButton] = useState<HTMLInputElement | null>(null);
    const [state, dispatch] = useReducer(reducer, []);

    useEffect(() => {
        props.onChange?.(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    useEffect(() => {
        if (props.value) {
            dispatch({
                type: 'set',
                files: props.value
            });
        }
    }, [props]);

    useEffect(() => {
        if (refButton) {
            fromEvent(refButton, 'change')
                .subscribe((e: Event) => {
                    if (refButton.files) {
                        getFileObj(refButton.files)
                            .then((fs: File[]) => {
                                dispatch({
                                    type: 'append',
                                    files: fs
                                })
                            });
                    }
                    refButton.files = null;
                })
        }
    }, [refButton]);


    return (
        <>
            <Form.File
                accept="image/*"
                id="processImagesUploadInput"
                multiple
                type="file"
                style={{ display: "none" }}
                name="imagesAdd"
                ref={setRefButton}
            />

            <label htmlFor="processImagesUploadInput" className="m-0">
                {props.children}
            </label>
        </>
    )
};

export default InputImages;
