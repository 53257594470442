import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { from, fromEvent, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import DynamicHead from "../../components/dynamicHead/dynamicHead";
import Footer from "../../components/footer/footer";
import MoreInformation from "../../components/moreInformation/moreInformation";
import NavBar from "../../components/navBar/navBar";
import ProcessesGrid from "../../components/processesGrid/processesGrid";
import utils from "../../utils";
import {
  getTags,
  getSiteConfig,
  getAllProcess,
} from "../../services/sendRequest";

const TagsPage = (props) => {
  const pageSize = 9;
  const tagId = props.match.params.tId;

  const [pageIndex, setPageIndex] = useState<number>(0);
  const [processesObj, setProcessesObj] = useState<any>(null);
  const [oldProcessesObj, setOldProcessesObj] = useState<any>(null);

  const [filterRoles] = useState<string[] | null>(null);

  const [sortRef, setSortRef] = useState<HTMLSelectElement | null>(null);
  const [filterSort, setFilterSort] = useState("Mais downloads");

  const [tag, setTag] = useState<any>(null);

  const [title, setTitle] = useState("");
  const [alternativeUrls, setAlternativeUrls] = useState<Array<any>>([]);

  const [t, i18n] = useTranslation();

  function loadProcesses() {
    let sortParams = utils.getOptionFilterField(filterSort);
    let filters: any = {
      sort: {
        [sortParams.optionField]: { order: sortParams.optionSort },
      },
      query: {
        bool: {
          must: [
            { term: { isPublished: true } },
            { term: { "category.enabled": true } },
            { term: { "language.code": i18n.language.substr(0, 2) || "en" } },
            {
              nested: {
                path: "tags",
                query: {
                  bool: {
                    must: [{ match: { "tags._id": tagId } }],
                  },
                },
              },
            },
          ],
        },
      },
    };

    let params = {
      pageIndex: pageIndex,
      pageSize: pageSize,
    };

    from(getAllProcess(filters, params))
      .pipe(map((response) => response.result || {}))
      .subscribe((processesObj) => {
        console.log(processesObj);
        if (oldProcessesObj) {
          processesObj.processes = oldProcessesObj.processes.concat(
            processesObj.processes
          );
        }
        setProcessesObj(processesObj);
      });
  }

  function seeMore() {
    setOldProcessesObj(processesObj);
    setPageIndex(pageIndex + 1);
  }

  useEffect(() => {
    getTags([tagId]).subscribe((tags) => {
      if ((tags || []).length) {
        setTag(tags[0]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    async function fetchData() {
      let siteConfig = await getSiteConfig({
        string: tag.name,
        identifier: "default",
      });

      if (siteConfig) {
        setTitle(siteConfig.title);
      }
      setAlternativeUrls(
        await utils.setAlternativeUrls(i18n.language, tag.identifiers)
      );
    }

    if (tag) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  // Carrega os processos de acordo com os dados dos filtros
  useEffect(() => {
    loadProcesses();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, filterRoles, filterSort, i18n.language]);

  useEffect(() => {
    if (sortRef) {
      fromEvent<Event>(sortRef, "change")
        .pipe(
          switchMap((e: Event) => of(sortRef)),
          map((el: HTMLSelectElement) => el.value)
        )
        .subscribe((value) => {
          if (value.length < 1) return;
          setOldProcessesObj(null);
          setPageIndex(0);
          setFilterSort(value);
        });
    }
  }, [processesObj, sortRef]);

  const optionsArr = [
    {
      value: "Mais recentes",
      key: t("OPTION_FILTER_KEY_MOST_RECENT"),
    },
    {
      value: "Mais vistos",
      key: t("OPTION_FILTER_KEY_MOST_VIEWED"),
    },
    {
      value: "Melhores avaliações",
      key: t("OPTION_FILTER_KEY_BEST_RATED"),
    },
    {
      value: "Mais downloads",
      key: t("OPTION_FILTER_KEY_MORE_DOWNLOADS"),
    },
  ];

  const sortBar = (
    <Col xs={12} sm={4}>
      <Form.Group>
        <Form.Control
          as="select"
          size="sm"
          custom
          className="form-control-main-drop"
          ref={(el: any) => setSortRef(el)}
          value={filterSort}
          onChange={() => {}}
        >
          {optionsArr.map((option) => {
            return (
              <option key={option.key} value={option.value}>
                {t(utils.getOptionFilterTitleTag(option.value))}
              </option>
            );
          })}
        </Form.Control>
      </Form.Group>
    </Col>
  );

  return (
    <div id="tag">
      <DynamicHead
        title={title}
        description=""
        url={window.location.origin + window.location.pathname}
        imgUrl=""
        alternativeUrlsData={alternativeUrls}
      />

      <NavBar type={"Process"} alternativeUrlsData={alternativeUrls} />

      <Container>
        <Row>
          <Col>{tag ? <h1>{tag.name}</h1> : null}</Col>
        </Row>

        <Row className="process-view-header-container">{sortBar}</Row>

        <ProcessesGrid
          processesObj={processesObj}
          message="PROCESS_GRID_MGS_NO_PROCESS_TAGS"
        />

        <Row className="mt-5">
          <Col>
            {processesObj?.processes.length !== processesObj?.total &&
            (pageIndex + 1) * pageSize < processesObj?.total ? (
              <span className="link-see-more clickable" onClick={seeMore}>
                Ver Mais
              </span>
            ) : null}
          </Col>
        </Row>

        <MoreInformation />
      </Container>

      <Footer />
    </div>
  );
};

export default TagsPage;
