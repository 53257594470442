import React, { useContext, useEffect, useReducer } from "react";
import AuthClient from "./AuthClient";
import AuthContext from "./AuthContext";
import { initialAuthState } from './IAuthState';
import { reducer } from "./reducer";
import { hasAuthParams, lt2DaysToExpire } from "./utils";
import history from './history';
import { axiosAPI } from '../services/api';


export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) throw new Error("useAuth must be used within a AuthProvider");
  return context;
}

export default function AuthProvider(opts: any) {

  const { children } = opts;

  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {

    const fn = async () => {
      dispatch({ type: 'INITIALISED' });

      try {
        if (hasAuthParams()) {
          await AuthClient.login();
          history.replace(removeAcessTokenFromUrl());
          dispatch({ type: 'COMPLETE', user: AuthClient.getUser() });
        }
        else {
          if (AuthClient.isAuthenticated()) {
            let user = AuthClient.getUser();
            if (user && lt2DaysToExpire(user.exp)) {
              await AuthClient.renewToken(user.token);
              dispatch({ type: 'COMPLETE', user });
            }
            else {
              dispatch({ type: 'COMPLETE', user });
            }
          }
          else {
            dispatch({ type: 'COMPLETE', user: null });
          }
        }
      }
      catch (error) {
        dispatch({ type: 'ERROR', error: error });
      }
    }
    fn();

    // Interceptor para utilizar token do usuário, caso esteja logado
    axiosAPI().interceptors.request.use(
      async config => {
        const { user, isAuthenticated } = state;
        if (isAuthenticated && user) {
          config.headers.Authorization = `Bearer ${user.token}`;
        }

        return config;
      },
      error => Promise.reject(error)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function removeAcessTokenFromUrl() {
    const url = new URL(window.location.href);
    let queryParams = new URLSearchParams(url.search);

    if (queryParams.has('accessToken')) {
      queryParams.delete('accessToken');
    }

    return url.pathname + queryParams.toString();
  }

  function logout() {
    // dispatch({ type: 'COMPLETE', user: null });
    AuthClient.logout();
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login: AuthClient.loginWithRedirect,
        logout: logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
