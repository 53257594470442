import { map } from 'rxjs/operators';
import { axiosResourcesAPI } from './../../../services/api';
import { User } from '../../../auth/IAuthState';
import { from, Observable } from 'rxjs';
import { iCatalogByLanguage } from '../_models/iCatalogByLanguage';
import { ServiceDesk } from "../_models/ServiceDesk";
import { SydleOneBase } from '../_models/SydleOneBase';

export const SDConfig = {
    classPackageIdentifier: 'com.sydle.servicedesk',
    classIdentifier: 'portalApi',
    portalId: '5fdcbfbc725a64268366caaf',
    portalIdentifier: 'processbox-content',
    servicoId: '5d66ed07e7c98a556a499a36'
};

export const PBConfig = {
    classPackageIdentifier: 'com.sydle.processbox',
    classIdentifier: 'processBoxAPI'
};

export const sendRequest = (classPackageIdentifier: string, classIdentifier: string, method: string, useGet: boolean, data: any, user?: User) => {

    const lang = localStorage.getItem('i18nextLng') || 'en';

    const promise: Promise<any> = new Promise((resolve, reject) => {
        const url = `${classPackageIdentifier}/${classIdentifier}/${method}`;
        (!useGet || JSON.stringify(data).length > 1800 ?
            axiosResourcesAPI(user).post(url, data) :
            axiosResourcesAPI(user).get(url, {
                params: {
                    _body: data,
                    _lang: 'teste'
                },
                headers: {
                    'accept-language': lang
                },
                paramsSerializer: function (params) {
                    params._body = JSON.stringify(params._body);
                    let searchParams = new URLSearchParams(params);
                    return searchParams.toString();
                }
            }))
            .then(resolve)
            .catch(reject);
    });

    return from(promise);
};

export const getPost = (data: any) => {
    return sendRequest(PBConfig.classPackageIdentifier, PBConfig.classIdentifier, 'getPost', true, data)
        .pipe(map(res => res?.data?.return));
};

export const getPortal = () : Observable<ServiceDesk.Portal | null> => {
    return sendRequest(SDConfig.classPackageIdentifier, SDConfig.classIdentifier, 'getPortal', true, {
        id: SDConfig.portalId,
        portal: SDConfig.portalIdentifier
    }).pipe(map(res => res?.data?.return));
};

/**
 *
 * Busca uma lista de catalogos de um portal ou um id especifico
 *
 * @param data - obj com os atributos idPortal ou idCatalog
 * @returns catalogs[]
 *
 * Exemplo de input
    {
        idPortal?: "5e98ae95477447324a24fbd9"
        idCatalog?: "600ae1b1725a642683fdbfcb"
    }
*/
export const getCatalogs = (data: any) => {
    return sendRequest(PBConfig.classPackageIdentifier, PBConfig.classIdentifier, 'getCatalogs', true, data)
        .pipe(
            map(response => response?.data?.data)
        );
};

export const getResourceCatalogs = (): Observable<iCatalogByLanguage[]> => {
    return sendRequest(PBConfig.classPackageIdentifier, PBConfig.classIdentifier, 'getResourceCatalogs', true, {})
        .pipe(
            map(response => response?.data?.data)
        );
};

export const getLeafs = (data: any) => {
    return sendRequest('com.sydle.processbox', "processBoxAPI", 'getLeafs', true, data)
        .pipe(
            map(response => response.data)
        );
};

export const getImgURL = (postId: string, iconId: string, user?: User): string => {
    let baseURL = process.env.REACT_APP_SYDLE_ONE_API + 'processBox/';
    let accessToken = user?.token || process.env.REACT_APP_SYDLE_ONE_SITE_TOKEN;

    return `${baseURL}_classId/${SDConfig.servicoId}/_download/${postId}/${iconId}?accessToken=${accessToken}`;
};

export const isCategoryOrPost = (resourceId: string) => {
    return sendRequest(PBConfig.classPackageIdentifier, PBConfig.classIdentifier, 'isCategoryOrPost', true, { resourceId })
        .pipe(
            map(response => response.data)
        );
};

export const getObjects = (data: any) => {
    return sendRequest(SDConfig.classPackageIdentifier, 'service', '_search', true, data)
        .pipe(
            map(response => (response?.data?.hits?.hits?.map(hit => hit._source)) || [])
        );
};

export const extractPendingContent = (classId?: string, objectId?: string, content?: SydleOneBase.PendingContent, user?: User): string => {
    let baseURL = process.env.REACT_APP_SYDLE_ONE_API + 'processBox/';
    let accessToken = user?.token || process.env.REACT_APP_SYDLE_ONE_SITE_TOKEN;

    return `${baseURL}_classId/${classId}/_download/${objectId}/${content?._id}?accessToken=${accessToken}`;
};
