import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Image, Row, Spinner, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import utils from "../../utils";
import ReviewLoader from "./reviewLoader";
import StarRatings from "react-star-ratings";
import { Icon } from '@iconify/react';
import trashF from '@iconify/icons-jam/trash-f';
import ShowMoreText from 'react-show-more-text';
import { useAuth } from "../../auth/Auth";
import * as api from "../../services/sendRequest";
import { useTranslation } from 'react-i18next'
import { useHistory } from "react-router-dom";
//TODO: rever o que não está sendo utilizado
const Review = (props) => {

  const pageSize = 2;

  const [optionFilter, setOptionFilter] = useState("Mais recentes");
  const [pageIndex, setPageIndex] = useState(0);
  const [reviews, setReviews] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [processId] = useState(props.processId);
  const [author] = useState<any>(props.author);
  const [userId, setUserId] = useState<any>(null);

  const [rating, setRating] = useState<any>(0);
  const [loadingSend, setLoadingSend] = useState<boolean>(false);
  const [loadingRemove, setLoadingRemove] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const { register, handleSubmit } = useForm();
  const { user, isAuthenticated } = useAuth();
  const [t] = useTranslation();
  const history = useHistory();

  useEffect(() => {
    let userIdText = window.localStorage.getItem('processBoxUserId')
    if (userIdText) setUserId(JSON.parse(userIdText))
    else setUserId({})
  }, [props.author, props.processId])


  useEffect(() => {
    async function fetchData() {
      let sortParams = utils.getOptionFilterField(optionFilter);
      let filters = {
        query: {
          "term": {
            "process._id": processId
          }
        },
        sort: {
          [sortParams.optionField]: { order: sortParams.optionSort },
        },
      };

      const params = {
        pageIndex: pageIndex,
        pageSize: pageSize
      }

      const reviews = await getAssessmentsFromProcess(filters, params);
      setReviews(reviews)
    }
    fetchData()
  }, [optionFilter, pageSize, processId])

  function setOptionFilterr(option, callback) { //TODO: rever esse nome
    setOptionFilter(option)
    callback()
  }

  function getAssessmentStar(assessment) {
    let stars: JSX.Element[] = [];
    for (let i = 0; i < 5; i++) {
      if (i < assessment.rating) {
        stars.push((<img className="stars" src={require("../../assets/custom/svg/yellowStar.svg")} alt="Star" key={i} />))
      }
      else {
        stars.push((<img className="stars" src={require("../../assets/custom/svg/greyStar.svg")} alt="Star" key={i} />))
      }
    }
    return stars;
  }

  async function getAssessmentsFromProcess(filters, params) {
    try {
      const res = await api.getAssessmentsFromProcess(filters, params);
      const reviews = res.result;
      return reviews;
    } catch (error) {
      console.log(error);
    }
  }

  async function getMoreAssessments(sorting) {

    let sortParams = utils.getOptionFilterField(optionFilter);
    // Define parâmetros para requisição supondo que irá apenas carregar mais posts
    let tempPageIndex = pageIndex + 1;
    const filters = {
      query: {
        "term": {
          "process._id": processId
        }
      },
      sort: {
        [sortParams.optionField]: { order: sortParams.optionSort },
      },
    };

    const params = {
      pageIndex: tempPageIndex,
      pageSize: pageSize
    }


    // Caso seja pra ordenar, nova requisição retorna a mesma quantidade de
    // avaliações que estavam sendo exibidas anteriormente, a partir da pageSize 0
    if (sorting) {
      params.pageIndex = 0;
      params.pageSize = (pageIndex + 1) * pageSize;
      setReviews(null)
    }

    let currentReviews = reviews;

    // Realiza requisição das avaliações
    setIsLoading(true)
    let newReviews = await getAssessmentsFromProcess(filters, params);


    if (sorting) {// Substitui avaliações antigas pelas novas
      setReviews(newReviews)
      setPageIndex(pageIndex)
      setIsLoading(false)

    }
    else { // Ver mais, adiciona novas avaliações as já existentes
      newReviews.assessments.forEach(assessment => {
        currentReviews.assessments.push(assessment);
      })
      setReviews(currentReviews)
      setPageIndex(tempPageIndex)
      setIsLoading(false)
    }
  }

  async function sendReview(obj) {

    if (rating === 0) {
      setModalMessage("Avalie entre 1 e 5 estrelas!")
      setError(true)
      handleShow()
    } else {
      setLoadingSend(true)
      obj.rating = rating
      obj.author = user?.code
      obj.process = processId
      const response = await api.sendReview(obj, user);
      if (response) {
        setModalMessage(t('PROCESS_SEND_REVIEW'))
        setError(false)
        handleShow()
      } else {
        setModalMessage(t('PROCESS_SEND_REVIEW_ERROR'));
        setError(true);
        handleShow();
        setLoadingSend(false);
      }
    }
  }

  async function removeReview(obj) {

    setLoadingRemove(true)
    const response = await api.removeReview(obj, user);
    if (response) {
      /* alert('Comentario removido com sucesso!'); */
      setModalMessage(t('PROCESS_REMOVE_REVIEW'));
      setError(false)
      handleShow()
    }
  }

  function checkAuth() {
    if (!isAuthenticated) {
      history.push(`/login?redirect=${window.location.pathname}`)
    }
  }

  function changeRating(newRating) {
    setRating(newRating)
  }

  const optionsArr = [t('OPTION_FILTER_KEY_MOST_RECENT'), t('OPTION_FILTER_KEY_BETTER'), t('OPTION_FILTER_KEY_WORST')];

  let handleChange = (event) => {
    let fieldVal = event.target.value;
    setOptionFilterr(fieldVal, () => getMoreAssessments(true))
  };

  const handleClose = () => {
    setShowModal(false)
    if (!error) window.location.reload()
  };
  const handleShow = () => setShowModal(true);

  return (
    <div id="review">
      <div className="review-background mt-4">
        <div className="review-wrapper">
          {author?._id !== userId?._id ?
            (<form onSubmit={handleSubmit(sendReview)}>
              <p>{t('PROCESS_NEW_REVIEW')}</p>
              <Form.Group>
                <Form.Control
                  onClick={() => { checkAuth() }}
                  id="uploadFormProcessName"
                  as="textarea"
                  className="form-fields form-field-name"
                  ref={register()}
                  name="review"
                />
              </Form.Group>
              <Form.Group className="d-flex flex-row align-content-center" onClick={() => { checkAuth() }}>
                <StarRatings
                  rating={rating}
                  starRatedColor="rgb(214, 215, 32)"
                  starDimension="2em"
                  changeRating={changeRating}
                  numberOfStars={5}
                  name='rating'
                  className="star-ratings"
                />
                <Button
                  type="submit"
                  className="d-block ml-auto send-btn"
                  disabled={loadingSend}
                >
                  {loadingSend ? t('PROCESS_SENDING_REVIEW_TEXT') : t('PROCESS_SEND_REVIEW_TEXT')}
                </Button>
              </Form.Group>
            </form>) : null
          }
          {reviews && reviews.total !== 0 ?
            (<div className="animated reviews-container"
            >
              <div
                className="tab-pane pt-6 review-header"
                id="pills-two-example1"
                role="tabpanel"
                aria-labelledby="pills-two-example1-tab"
              // style={{ width: "1166px", margin: "auto" }}
              >
                <Container fluid>
                  {/* Início Cabeçalho dos comentários */}
                  <Row className="justify-content-between reviews-option-filter">
                    <Col xs={12} sm={6} className="d-flex align-items-center mb-2 mb-sm-0 p-0">
                      <span className="text-f">{t('PROCESS_RATINGS_REVIEWS')}</span>
                      <small className="text-muted ml-3" style={{ marginBottom: "0.8rem" }}>({reviews.total})</small>
                    </Col>
                    <Col xs={12} sm={6} className="d-flex justify-content-end p-0">
                      {/* Início dos filtros para comentários */}
                      <Form.Control
                        as="select"
                        size="sm"
                        custom
                        className="main-drop form-control-main-drop"
                        id={"searchOrder"}
                        value={optionFilter}
                        onChange={handleChange}
                      >
                        {optionsArr.map((option, index) => {
                          return (
                            <option
                              value={option}
                              key={index}
                            >
                              {option}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                  </Row>
                </Container>
                {/* Fim dos filtros para comentários */}
              </div>

              <hr className="my-3" />

              {/* Fim Cabeçalho dos comentários */}
              {(reviews.assessments).map((assessment, index) => {
                return (
                  <div key={index}>
                    <div className="media review-area"
                    >
                      <div className="avatar avatar-circle mr-3">
                        {/* Se usuário possui foto, busca, senão pega imagem padrão */}
                        {assessment.author.photo ? (
                          <Image roundedCircle
                            className="circular-avatar-review clickable"
                            src={utils.getPersonImageUrl(assessment.author._id)}
                            alt={assessment.author.name}
                          />
                        ) : (
                          <Image roundedCircle
                            className="circular-avatar-review clickable"
                            src={require("../../assets/custom/svg/user_icon.svg")}
                            alt={assessment.author.name}
                          />
                        )}
                      </div>

                      <div className="media-body border-bottom pb-6 mb-6">
                        {/* Comentário */}
                        <div className="review-stars-container">
                          <h6
                            className="author-name">{assessment.author.name}
                          </h6>
                          <div
                            className="review-stars-item"
                          >
                            {getAssessmentStar(assessment)}
                          </div>

                          {
                            assessment.author._id === userId._id ?
                              <div
                                className="trashIcon"
                                onClick={() => {
                                  removeReview({
                                    process: processId,
                                    review: assessment._id
                                  })
                                }}
                              >
                                {loadingRemove ?
                                  <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </Spinner>
                                  : <Icon icon={trashF} />
                                }
                              </div>
                              : null
                          }

                        </div>

                        <div className="textAssessment">
                          <ShowMoreText
                            lines={3}
                            more='Ler mais'
                            less='Ler menos'
                            anchorClass=''
                            className='review-description-text'
                            expanded={false}
                            width={280}
                          >
                            {assessment.review}
                          </ShowMoreText>
                        </div>
                        {/* {
                            assessment.author._id !== user._id ?
                            <>
                              <small className="review-description-text mr-2 ">
                                Essa avaliação foi útil?
                              </small>
                              <Link className="small mr-2" to="/">
                                Sim
                              </Link>
                              <Link className="small" to="/">
                                Não
                              </Link>
                            </>
                            : null
                        } */}

                        {/* Resposta do autor */}
                        {assessment.reply ?
                          <div className="card shadow-none border p-3 mt-4">
                            <div className="media">
                              <div className="avatar avatar-circle mr-3">
                                {author.photo ? (
                                  <Image roundedCircle
                                    className="circular-avatar-review clickable"
                                    src={utils.getPersonImageUrl(author._id)}
                                    alt={author.name}
                                  />
                                ) : (
                                  <Image roundedCircle
                                    className="circular-avatar-review clickable"
                                    src={require("../../assets/custom/svg/user_icon.svg")}
                                    alt={author.name}
                                  />
                                )}
                              </div>
                              <div className="media-body">
                                <span className="d-block text-dark font-weight-bold">
                                  {author.name}
                                </span>
                                <p className="review-description-text">
                                  {assessment.reply}
                                </p>
                              </div>
                            </div>
                          </div>
                          : null}
                      </div>
                      {/* Fim da resposta do autor */}
                    </div>
                  </div>
                );
              })}

              {isLoading ?
                <div style={{ marginTop: "-8%" }}>
                  <ReviewLoader showFilter={false} showTitle={false} />
                </div>
                : null}

              <div className="text-center">
                <span className="font-weight-bold clickable" onClick={() => getMoreAssessments(false)} >
                  {reviews.assessments.length !== reviews.total ?
                    (<div
                      className="review-link-see-more-container"
                    >
                      <span className="link-see-more">{t('PROCESS_READ_MORE_REVIEWS')}</span>
                    </div>) : null}
                </span>
              </div>
            </div>
            ) :
            /* <ReviewLoader showFilter={true} showTitle={true} /> */
            <small className="review-description-text mr-2 ">
              {t('PROCESS_NO_REVIEW')}
            </small>
          }
          {/* <Modal show={showModal} onHide={handleClose}>
            <Modal.Body>{modalMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Sair
              </Button>
            </Modal.Footer>
          </Modal> */}
          <Modal
            size="sm"
            show={showModal}
            onHide={handleClose}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                {error ? "Erro ao enviar" : "Sucesso!"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalMessage}</Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default Review;
