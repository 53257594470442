import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IPost } from '../../_models/iPost';
import { getObjects } from '../../_services/api';
import { post2IPost } from '../../_services/utils';

const RelatedPosts = (props: any) => {

    const {
        relatedItems
    } = props;

    const [t, i18n] = useTranslation();
    const [posts, setPosts] = useState<IPost[]>([]);

    useEffect(() => {

        let s;
        if (relatedItems) {
            const relatedPostsIds = relatedItems.map(relatedPost => relatedPost._id);

            let body = {
                'query': {
                    'terms': {
                        '_id': relatedPostsIds
                    }
                }
            }

            s = getObjects(body)
                .subscribe((post) => setPosts(post
                    .map(post2IPost)
                    .sort((a, b) => {
                        return relatedPostsIds.indexOf(a._id) - relatedPostsIds.indexOf(b._id);
                    })));
        }

        return () => s?.unsubscribe();

    }, [i18n.language, relatedItems]);

    if(!relatedItems) {
        return <></>;
    }

    return (
        <div className="related-posts">
            <div className="mb-5">
                <h3 className="font-weight-medium">{t('RELATED_STORIES')}</h3>
            </div>

            <div className="row">

                {posts.map(post => (
                    <div className="col-md-6" key={post._id}>

                        <article className="border-bottom h-100 py-5">
                            <div className="row justify-content-between">
                                <div className="col-6">
                                    <Link className="d-inline-block text-muted font-weight-medium text-uppercase small"
                                        to={`/blog/${post.category.identifier}-${post.category._id}`}
                                    >{post.category.name}</Link>
                                    <h4 className="h6 font-weight-medium mb-0">
                                        <Link to={`/blog/${post.identifier}-${post._id}`}>
                                            {post.name}
                                        </Link>
                                    </h4>
                                </div>

                                <div className="col-5">
                                    <Link to={`/blog/${post.identifier}-${post._id}`}>
                                        <img
                                            className="img-fluid"
                                            src={post.iconURL} alt={post.name} />
                                    </Link>
                                </div>
                            </div>
                        </article>
                    </div>
                ))}

            </div>
        </div>
    );
}

export default RelatedPosts;