import facebookIcon from "@iconify/icons-jam/facebook";
import linkedinIcon from "@iconify/icons-jam/linkedin";
import twitterIcon from "@iconify/icons-jam/twitter";
import whatsappIcon from "@iconify/icons-jam/whatsapp";
import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { getSocialNetworkConfig } from "../../services/sendRequest";
import { useTranslation } from "react-i18next";

type P = {
  process: { [key: string]: any };
  [key: string]: any;
};
type S = {
  socialNetworks: any[];
  [key: string]: any;
};

const Icons: { [key: string]: typeof facebookIcon } = {
  facebook: facebookIcon,
  twitter: twitterIcon,
  linkedin: linkedinIcon,
  whatsapp: whatsappIcon,
};

const sharingsUris: { [key: string]: any } = {
  whatsapp: "https://api.whatsapp.com/send?text=",
  twitter: "https://twitter.com/intent/tweet?text=",
};

const ShareButtons = (props: any) => {
  const [t] = useTranslation();
  const { processId } = props;

  const [socialNetworks, setSocialNetworks] = useState<any[]>([]);

  useEffect(() => {
    getSocialNetworkConfig({ processId: processId })
      .then((response) => setSocialNetworks(response.socialNetworks))
      .catch((err) => console.log(err));
  }, [processId]);

  const popover = (
    <Popover id="popover-share-buttons">
      <Popover.Content>
        <div className="d-flex flex-column">
          {socialNetworks.map((network: any) => {
            if (network.permissionToShare) {
              let CustomIcon = Icons[network.identifier];

              let contentMediaShare = encodeURIComponent(
                network.sharingMessage + " " + window.location.href
              );
              let shareLink;
              if (network.name === "Facebook") {
                shareLink = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&quote=${network.sharingMessage}`;
              } else if (network.name === "LinkedIn") {
                shareLink = `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${network.sharingMessage}&summary=&source=`;
              } else {
                shareLink = `${
                  sharingsUris[`${network.identifier}`] + contentMediaShare
                }`;
              }
              return (
                <div className="share-icon" key={network.identifier}>
                  <a href={shareLink} target="_blank" rel="noopener noreferrer">
                    <Icon className="icon" icon={CustomIcon}></Icon>
                    <span className="follow-text">{network.name}</span>
                  </a>
                </div>
              );
            } else {
              return <></>;
            }
          })}
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <div>
      {socialNetworks.length > 0 ? (
        <OverlayTrigger
          trigger={["click"]}
          placement="bottom"
          overlay={popover}
        >
          <div className="contorn-button header-process-info-itens-share clickable ml-2">
            <span className="follow-text">{t("SHARE")}</span>
            <svg height="20" width="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <defs/>
              <title>share</title>
              <path d="M8.074,10.212a4,4,0,1,0,0,3.574" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M19.5,8.5a4.01,4.01,0,1,0-3.575-2.213" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M15.925,17.712A4,4,0,1,0,19.5,15.5" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M19.5,8.5a4,4,0,0,1-3.575-2.213L8.074,10.212a3.963,3.963,0,0,1,0,3.575l7.851,3.926A4,4,0,0,1,19.5,15.5" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
        </OverlayTrigger>
      ) : (
        <> </>
      )}
    </div>
  );
};

export default ShareButtons;
