import React from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DynamicHead from "../../components/dynamicHead/dynamicHead";
import Footer from "../../components/footer/footer";
import MoreInformation from "../../components/moreInformation/moreInformation";
import NavBar from "../../components/navBar/navBar";

const ErrorPage = () => {

  const [t] = useTranslation();

  return (
    <>
      <DynamicHead
        title={t('ERROR_404') + ' | ProcessBox'}
        description={t('ERROR_404_PAGE_NOT_FOUND')}
        url={window.location.origin + window.location.pathname}
      />

      <meta name="prerender-status-code" content="404"></meta>

      <div id="home">
        
        <div className="navbar-background">
          <NavBar type={"Process"} searchURL="/search" hasSearchBox />
        </div>

        <div className="mt-5">
          <h1 className="text-center">{t('ERROR_404')}</h1>
          <h2 className="text-center">{t('ERROR_404_PAGE_NOT_FOUND')}</h2>
        </div>

        <div className="more-information-background">
          <Container>
            <MoreInformation />
          </Container>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default ErrorPage;
