import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { from, fromEvent, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import DynamicHead from "../../components/dynamicHead/dynamicHead";
import Footer from "../../components/footer/footer";
import MoreInformation from "../../components/moreInformation/moreInformation";
import NavBar from "../../components/navBar/navBar";
import ProcessesGrid from "../../components/processesGrid/processesGrid";
import utils from "../../utils";
import { MarkdownParser } from "../../components/markdown-editor/markdown-editor";
import {
  getCategories,
  getSiteConfig,
  getAllProcess,
} from "../../services/sendRequest";
import { useTranslation } from "react-i18next";

const Categories = (props) => {
  const pageSize = 9;
  const categoryId = props.match.params.cId;

  const [pageIndex, setPageIndex] = useState<number>(0);
  const [processesObj, setProcessesObj] = useState<any>(null);
  const [oldProcessesObj, setOldProcessesObj] = useState<any>(null);

  const [sortRef, setSortRef] = useState<HTMLSelectElement | null>(null);
  const [filterSort, setFilterSort] = useState("Mais recentes");
  const [category, setCategory] = useState<any>(null);

  const [refContent, setRefContent] = useState<HTMLDivElement | null>(null);
  const [alternativeUrls, setAlternativeUrls] = useState<Array<any>>([]);

  const [title, setTitle] = useState("");
  const [t, i18n] = useTranslation();

  function loadProcesses() {
    let sortParams = utils.getOptionFilterField(filterSort);
    let filters: any = {
      sort: {
        [sortParams.optionField]: { order: sortParams.optionSort },
      },
      query: {
        bool: {
          must: [
            { term: { "category.enabled": true } },
            { term: { isPublished: true } },
            { term: { "language.code": i18n.language.substr(0, 2) || "en" } },
          ],
        },
      },
    };

    if (categoryId) {
      filters.query.bool.must.push({
        term: {
          "category._id": categoryId,
        },
      });
    }

    let params = {
      pageIndex: pageIndex,
      pageSize: pageSize,
    };

    from(getAllProcess(filters, params))
      .pipe(map((response) => response.result || {}))
      .subscribe((processesObj) => {
        if (oldProcessesObj) {
          processesObj.processes = oldProcessesObj.processes.concat(
            processesObj.processes
          );
        }
        setProcessesObj(processesObj);
      });
  }

  function seeMore() {
    setOldProcessesObj(processesObj);
    setPageIndex(pageIndex + 1);
  }

  useEffect(() => {
    if (categoryId) {
      getCategories(categoryId).then((categories) => {
        setCategory(categories[0]);
      });
    }
  }, [categoryId, i18n.language]);

  useEffect(() => {
    async function fetchData() {
      let siteConfig = await getSiteConfig({
        string: category.name,
        identifier: "default",
      });

      if (siteConfig) {
        setTitle(siteConfig.title);
      }

      setAlternativeUrls(
        await utils.setAlternativeUrls(i18n.language, category.identifiers)
      );
    }

    if (category) {
      fetchData();
    }
  }, [category, i18n.language]);

  // Carrega os processos de acordo com os dados dos filtros
  useEffect(() => {
    if (categoryId || filterSort) {
      loadProcesses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, filterSort, i18n.language]);

  useEffect(() => {
    if (sortRef) {
      fromEvent<Event>(sortRef, "change")
        .pipe(
          switchMap(() => of(sortRef)),
          map((el: HTMLSelectElement) => el.value)
        )
        .subscribe((value) => {
          if (value.length < 1) return;
          setOldProcessesObj(null);
          setPageIndex(0);
          setFilterSort(value);
        });
    }
  }, [sortRef]);

  useEffect(() => {
    if (category?.description && refContent) {
      refContent.innerHTML = MarkdownParser(category.description);
    }
  }, [category, refContent]);

  const optionsArr = [
    {
      value: "Mais recentes",
      key: t("OPTION_FILTER_KEY_MOST_RECENT"),
    },
    {
      value: "Mais vistos",
      key: t("OPTION_FILTER_KEY_MOST_VIEWED"),
    },
    {
      value: "Melhores avaliações",
      key: t("OPTION_FILTER_KEY_BEST_RATED"),
    },
    {
      value: "Mais downloads",
      key: t("OPTION_FILTER_KEY_MORE_DOWNLOADS"),
    },
  ];

  const sortBar = (
    <Col xs={12} sm={4}>
      <Form.Group>
        <Form.Control
          as="select"
          size="sm"
          custom
          className="form-control-main-drop"
          ref={(el) => setSortRef(el)}
          value={filterSort}
          onChange={() => {}}
        >
          {optionsArr.map((option) => {
            return (
              <option key={option.key} value={option.value}>
                {t(utils.getOptionFilterTitleTag(option.value))}
              </option>
            );
          })}
        </Form.Control>
      </Form.Group>
    </Col>
  );

  return (
    <div id="categories">
      <DynamicHead
        title={title}
        description={refContent?.textContent}
        url={window.location.origin + window.location.pathname}
        imgUrl=""
        alternativeUrlsData={alternativeUrls}
      />

      <NavBar type={"Process"} searchURL="/search" hasSearchBox alternativeUrlsData={alternativeUrls} />
      <hr />
      <div className="header-background">
        <Container>
          <div className="header-category">
              <Row className="teste">
                <Col>
                  {category ? (
                    <>
                      <h1 className="mb-4">{category.name}</h1>
                      <p ref={setRefContent}></p>
                    </>
                  ) : null}
                </Col>
              </Row>
            </div>
        </Container>
        <hr />
        <Container>
          <div className="header-category">
            {category ? (
              <>
                <Row className="process-view-header-container">
                  <Col xs={12} sm={8} className="most-recents-text mb-2">
                    {t(utils.getOptionFilterTitleTag(filterSort))}
                  </Col>
                  {sortBar}
                </Row>
              </>
            ) : null}
          </div>
        </Container>
      </div>
      <Container>
        <div className="processes-background">
          <ProcessesGrid
            processesObj={processesObj}
            message="PROCESS_GRID_MGS_NO_PROCESS"
          />
        </div>

        <Row className="mt-5">
          <Col>
            {processesObj?.processes.length !== processesObj?.total &&
            (pageIndex + 1) * pageSize < processesObj?.total ? (
              <span className="link-see-more clickable" onClick={seeMore}>
                {t("LOAD_MORE")}
              </span>
            ) : null}
          </Col>
        </Row>

      </Container>
      <div className="more-information-background">
        <Container>
          <MoreInformation />
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Categories;
