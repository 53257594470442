import React from "react";
import { Button } from "react-bootstrap";
import { useAuth } from '../../auth/Auth';
import { getFileDownload } from "../../services/sendRequest";
import { useHistory } from "react-router-dom";

const DownloadProcessButton = (props) => {
  
  const { processId, format } = props;
  const { user } = useAuth();
  const history = useHistory();

  async function downloadProcess() {

    const downloadResponse = await getFileDownload(processId, user, format);

    // Se resposta retornada não for para abrir o modal, link foi gerado com sucesso.
    if (downloadResponse && downloadResponse !== 'openSignInModal') {
      window.location.replace(downloadResponse);
    }
    // Basta abrir o modal
    else if (downloadResponse === 'openSignInModal') {
      history.push(`/login?redirect=${window.location.pathname}`);
    }
  }

  return (
    <Button
      block
      variant="primary"
      size="sm"
      className="download-button"
      onClick={() => downloadProcess()}
    >
      <span className="mr-2">Download {format ? format.toUpperCase() : ""}</span>
      <svg height="14" width="14" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="d-inline align-middle">
        <defs/>
        <title>download-bottom</title>
        <path d="M23,18.218v1.913A2.87,2.87,0,0,1,20.131,23H3.869A2.869,2.869,0,0,1,1,20.131V18.218" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round"/>
        <line fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" x1="12" x2="12" y1="18.108" y2="1"/>
        <polyline fill="none" points="19 11.108 12 18.108 5 11.108" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </Button>
  );
}
export default DownloadProcessButton;
