import { getPortal } from "./services/sendRequest";
import { kebabCase } from "./pages/resources/_services/utils";

export const ClassId = {
  process: "5e7e5a8ece0014567de5c190",
  person: "59136a2c116a1f0f8819ecbb",
  portal: "5edaa23c7f7f6474ee97bbf4",
};

export const PortalId = process.env.REACT_APP_CONFIG_OBJECT;

const utils = {
  // Retorna a url de uma imagem no SYDLE ONE
  getImageUrl: (classId, objectId, photoId, userToken = null) => {
    let accessToken = userToken || process.env.REACT_APP_SYDLE_ONE_SITE_TOKEN;
    let baseURL = `${process.env.REACT_APP_SYDLE_ONE_API}processBox`;
    let link = `${baseURL}/_classId/${classId}/_download/${objectId}/${photoId}?accessToken=${accessToken}`;
    return link;
  },
  getPersonImageUrl: (personId, userToken = null) => {
    const accessToken = userToken || process.env.REACT_APP_SYDLE_ONE_SITE_TOKEN;
    const link = `${process.env.REACT_APP_SYDLE_ONE_API}processBox/com.sydle.processbox/processBoxAPI/getPersonImage/${personId}?accessToken=${accessToken}`;
    return link;
  },
  // Retorna a url de uma imagem de processo
  getProcessImageUrl: (processIdentifier, processId) => {
    let link = `/images/${processIdentifier}-${processId}.svg`;
    return link;
  },
  // Retorna o campo do filtro correspondente no backend
  getOptionFilterField: (optionFilter) => {
    let fields = {
      optionField: "",
      optionSort: "",
    };

    if (optionFilter === "Mais vistos") {
      fields = {
        optionField: "viewCount",
        optionSort: "desc",
      };
    } else if (
      optionFilter === "Melhores avaliações" ||
      optionFilter === "Melhores"
    ) {
      fields = {
        optionField: "rating",
        optionSort: "desc",
      };
    } else if (optionFilter === "Mais downloads") {
      fields = {
        optionField: "downloadCount",
        optionSort: "desc",
      };
    } else if (optionFilter === "Mais recentes") {
      fields = {
        optionField: "_creationDate",
        optionSort: "desc",
      };
    } else if (optionFilter === "Piores") {
      fields = {
        optionField: "rating",
        optionSort: "asc",
      };
    }
    return fields;
  },
  // Converte arquivo para base64
  toBase64: (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    }),
  // Retorna o campo do título a ser mostrado no componente "processView", com base no filtro
  getOptionFilterTitleTag: (optionFilter) => {
    let title;
    if (optionFilter === "Mais vistos") {
      title = "OPTION_FILTER_KEY_MOST_VIEWED";
    } else if (
      optionFilter === "Melhores avaliações" ||
      optionFilter === "Melhores"
    ) {
      title = "OPTION_FILTER_KEY_BEST_RATED";
    } else if (optionFilter === "Mais downloads") {
      title = "OPTION_FILTER_KEY_MORE_DOWNLOADS";
    } else if (optionFilter === "Mais recentes") {
      title = "OPTION_FILTER_KEY_MOST_RECENT";
    } else if (optionFilter === "Piores") {
      title = "OPTION_FILTER_KEY_WORST";
    } else if (optionFilter === "Relacionados") {
      title = "OPTION_FILTER_TITLE_RELATED_PROCESS";
    }
    return title;
  },

  randomKey: () => {
    return (Math.random() * 10 ** 10).toFixed(0);
  },

  setAlternativeUrls: async (lang: string, identifiers?) => {
    let altUrls: any[] = [];

    let portal = await getPortal();
    let canonicalUrl = {
      link: "",
      lang: "",
    };

    const pathname = window.location.pathname === "/" ? "" : window.location.pathname;

    if (identifiers) {
      let r = /(.*)(\/[\w-]+)(?=-[a-zA-Z0-9]{24})(.*)/;

      portal.languagesAndCountries.forEach((langAndCountry) => {
        langAndCountry.countries.forEach((country) => {
          let hlLang =
            langAndCountry.language.code.substr(0, 2) + "-" + country.domain;

          if (
            langAndCountry.language.code.substr(0, 2) === lang?.substr(0, 2)
          ) {
            canonicalUrl.link =
              window.location.origin +
              pathname.replace(
                r,
                `$1/${kebabCase(
                  identifiers[langAndCountry.language.code]
                )}$3?hl=${hlLang}`
              );
          }

          let result = {
            link: "",
            lang: "",
          };

          let link =
            window.location.origin +
            pathname.replace(
              r,
              `$1/${kebabCase(identifiers[langAndCountry.language.code])}$3`
            ) +
            `?hl=${hlLang}`;

          result.link = link;
          result.lang = langAndCountry.language.code;

          altUrls.push(result);
        });
      });
    } else {
      portal.languagesAndCountries.forEach((langAndCountry) => {
        langAndCountry.countries.forEach((country) => {
          let hlLang = langAndCountry.language.code + "-" + country.domain;

          let result = {
            link: "",
            lang: "",
          };

          let link = window.location.origin + pathname + `?hl=${hlLang}`;

          if (
            langAndCountry.language?.code?.substr(0, 2) === lang?.substr(0, 2)
          ) {
            canonicalUrl.link = link;
          }

          result.link = link;
          result.lang = langAndCountry.language.code;

          altUrls.push(result);
        });
      });
    }
    altUrls.push(canonicalUrl);
    return altUrls;
  },
};

export default utils;
