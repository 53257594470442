import React, { useEffect, useState } from "react";
import DynamicHead from "../../../components/dynamicHead/dynamicHead";
import Footer from '../../../components/footer/footer';
import NavBar from "../../../components/navBar/navBar";
import ResourcesPostGrid from "../_components/post-grid/post-grid";
import ResourcesPostNav from "../_components/post-nav/post-nav";
import { IPost } from '../_models/iPost';
import { getLeafs, SDConfig, getResourceCatalogs } from '../_services/api';
import { post2IPost } from '../_services/utils';
import { iCatalogByLanguage } from "../_models/iCatalogByLanguage";
import { useLocation } from 'react-router-dom';
import { getSiteConfig } from "../../../services/sendRequest";
import { useTranslation } from "react-i18next";
import utils from "../../../utils";

const ResourcesSearch = (props: any) => {

    const pageSize = 3;
    const location = useLocation();

    const [pageIndex, setPageIndex] = useState(0);
    const [totalPosts, setTotalPosts] = useState(0);
    const [posts, setPosts] = useState<IPost[]>([]);
    const [filterQuery, setFilterQuery] = useState('');
    const [catalogs, setCatalogs] = useState<iCatalogByLanguage | null>(null);
    const [headerTitle, setHeaderTitle] = useState('');
    const [alternativeUrls, setAlternativeUrls] = useState<Array<any>>([]);
    const [, i18n] = useTranslation();

    useEffect(() => {
        getResourceCatalogs()
            .subscribe(catalogs => {
                if (catalogs.length) {
                    setCatalogs(catalogs[0]);
                }
            });

        getSiteConfig({
            identifier: 'home'
        }).then(siteConfig => setHeaderTitle(siteConfig.title));
    }, [i18n.language]);

    useEffect(() => {
        if (catalogs && filterQuery) {
            const postReq = {
                itemId: catalogs.catalog._id,
                portal: SDConfig.portalId,
                params: {
                    pageSize: pageSize,
                    pageIndex: pageIndex,
                    sort: [{
                        publishedDate: {
                            order: 'desc'
                        }
                    }]
                },
                query: {
                    bool: {
                        must: [
                            {
                                multi_match: {
                                    "query": filterQuery,
                                    "fields": ["name", "contents", "tag", "roles", "author", "content"]
                                }
                            }
                        ]
                    }
                },
                returnFields: ['_id', 'icon', 'identifier', 'description', 'name', 'author', 'publishedDate']
            };

            getLeafs(postReq)
                .subscribe(obj => {
                    obj.leafs = obj.leafs.map(post2IPost);
                    setPosts(obj.leafs);
                    setTotalPosts(obj.length);
                });
        }
    }, [catalogs, filterQuery, i18n.language, pageIndex]);

    useEffect(() => {
        var queryParams = new URLSearchParams(location.search);
        console.log(location.search)

        let q = '';
        if (queryParams.has('q')) {
            q = queryParams.get('q') as string;
            setFilterQuery(q);
        }

    }, [location.search]);

    useEffect(() => {
        utils.setAlternativeUrls(i18n.language)
            .then(setAlternativeUrls);
    }, [i18n.language]);

    return (
        <div className="resources-home">
            <DynamicHead
                title={headerTitle}
                description=""
                url={window.location.origin + window.location.pathname}
                imgUrl=""
                alternativeUrlsData={alternativeUrls}
            />

            <NavBar type={"Process"} searchURL="/blog/search" hasSearchBox alternativeUrlsData={alternativeUrls} />

            <hr style={{ margin: '0px' }} />

            {/* Cards */}
            <div className="container space-2 space-bottom-lg-3">
                <ResourcesPostGrid posts={posts} message={'RESOURCES_POST_GRID_MGS_NO_PROCESS'} />

                <div className="space-bottom-2"></div>

                <ResourcesPostNav {...({ pageIndex, pageSize, totalPosts, setPageIndex })} />
            </div>

            <Footer />
        </div>
    )
}

export default ResourcesSearch;
