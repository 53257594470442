import axios from 'axios';
import config from './config';
import { User } from './IAuthState';
import { isPayloadExpired } from './utils';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import history from './history';
declare let dataLayer: any;

export enum SocialProvider {
    Google = 'google',
    Facebook = 'facebook',
    LinkedIn = 'linkedin',
    Null = 0
};

const AuthClient = {

    buildAuthorizeUrl(options: {
        provider: SocialProvider,
        redirect: string,
        [key: string]: any
    }): string {

        let getIdentifierAuthenticator = (provider: SocialProvider) => {
            switch (provider) {
                case SocialProvider.Facebook:
                    return 'processBox.facebookSignIn';
                case SocialProvider.Google:
                    return 'processBox.googleSignIn';
                case SocialProvider.LinkedIn:
                    return 'processBox.linkedinSignIn';
                default:
                    return '';
            }
        }

        let identifierAuthenticator = getIdentifierAuthenticator(options.provider);
        let baseUrl = config.sydle_one_api;
        let env = config.sydle_one_env;

        let URLI_pos_redirect = window.location.origin + options.redirect;
        let urlSignIn = `${baseUrl}processBox/sys/auth/sso/signin/${env}/${identifierAuthenticator}?one_redirect=${URLI_pos_redirect}`;

        return urlSignIn;
    },

    getUser(options?: any): User{
        let processBoxUser: any = window.localStorage.getItem('processBoxUser') || '';
        
        let user = JSON.parse(processBoxUser);
        if (isPayloadExpired(user.exp)) {
            AuthClient.logout(options);
            return null;
        }
        return user;
    },

    loginWithRedirect(options: {
        provider: SocialProvider,
        redirect: string,
        [key: string]: any
    }): void {
        const url = AuthClient.buildAuthorizeUrl(options);
        window.localStorage['providerSignIn'] = options.provider;
        // deve usar o assign ou outra função que permita sair para uma url externa
        // history.push(url);
        window.location.assign(url);
    },

    async renewToken(accessToken: string
    ): Promise<User> {
        const user = await axios.create({
            baseURL: config.sydle_one_api,
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json-patch+json"
            },
            crossdomain: true
        } as any)
            .get('processBox/sys/auth/renewToken')
            .then(response => {
                return response.data;
            })
            .catch(err => {
                throw new Error('Erro ao realizar requisição do renewToken: ' + JSON.stringify(err));
            });
        if (user && user.accessToken) {
            window.localStorage.setItem('processBoxUser', JSON.stringify({ 
                token: user.accessToken.token,
                ...user.accessToken.payload
             }));

            return user;

        } else {
            throw new Error('Erro: usuário retornado inválido');
        }
    },

    async login(
    ): Promise<User> {
        const obj_url = new URL(window.location.href);
        var queryParams = new URLSearchParams(obj_url.search);

        const accessToken = queryParams.get('accessToken') || '';

        let provider = window.localStorage['providerSignIn'] || null;
        dataLayer.push({
            'event': 'formSubmitted',
            'eventCategory': 'Home',
            'eventAction': 'Login',
            'eventLabel': `Autenticação ${provider}`
        });

        return AuthClient.renewToken(accessToken);
    },

    isAuthenticated(options?: any) {
        const user = AuthClient.getUser(options);
        return !!user;
    },

    logout(options?: any): void {
        window.localStorage.removeItem('processBoxUserId');
        window.localStorage.removeItem('processBoxUser');
        window.localStorage.removeItem('providerSignIn');
        // history.push('/');
        // resolve por hora o problema do popper popover de erro ao apagar o user 
        window.location.assign('/')
    }
}

export default AuthClient;