import React  from "react";
import { Col, Row } from "react-bootstrap";

const ProcessNavBarLoader = () => {
  return (
    <div id="process-navbar">
      <header className="header header-process mb-2">
        <div id="processNavBarLoader" className="header-process-default">
          <Row>
            <Col>
              <div className="header-process-breadcrumbs animated-background path">
              </div>
              <div className="object-text animated-background title"></div>
            </Col>
            <Col className="d-flex flex-row justify-content-end align-items-center">
                <div>
                  {/* Visualizações */}
                  <div className="animated-background counts ml-2"></div>
                </div>
                  {/* Downloads */}
                <div>
                  <div className="animated-background counts ml-2"></div>
                </div>
                {/* Seguir */}
                <div>
                  <div className="animated-background follow ml-2"></div>
                </div>
                {/* Compartilhar */}
                <div>
                  <div className="animated-background share ml-2"></div>
                </div>
            </Col>
          </Row>
        </div>
      </header>
    </div>
  );
}

export default ProcessNavBarLoader;
