
import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import ResourcesCategory from './category/category';
import ResourcesPost from './post/post';
import { isCategoryOrPost } from './_services/api';

const RouteResources = (props: any) => {
  
  const rId = props?.computedMatch?.params?.rId || null;

  const [isCategory, setIsCategory] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    
    let s: any = null;
    if (rId) {
      s = isCategoryOrPost(rId)
        .subscribe(res => {
          if (res.isCategory) {
            setIsCategory(true);
            setIsLoading(false);
          }
          else {
            setIsCategory(false);
            setIsLoading(false);
          }
        })
    }
    else {
      setIsLoading(false);
    }

    return () => s?.unsubscribe();
  }, [rId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  else {
    if (isCategory) {
      return <Route {...props} component={ResourcesCategory} />;
    }
    else {
      return <Route {...props} component={ResourcesPost} />;
    }
  }
}

export default RouteResources;