import marked from "marked";
import { kebabCase, post2IPost } from "../_services/utils";
import { IPostConcrete } from '../_models/iPost';
import { SDConfig, getImgURL, getPost } from '../_services/api';
import { map } from "rxjs/operators";

export class CustomPostRenderer extends marked.Renderer {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(private post: IPostConcrete, options?: marked.MarkedOptions) {
        super(options);
    }

    

    heading(text: string, level: 1 | 2 | 3 | 4 | 5 | 6, raw: string, slugger: any): string {
        const escapedText = kebabCase(text);

        return `<h${level}>
                    <span id="${escapedText}"></span>
                    ${text}
                </h${level}>`;
    }

    link(href: string | null, title: string | null, text: string): string {
        // Expressão regular para string correspondente a um Id
        let results = new RegExp('([0-9a-f]{24})(#[^#]+)?$|(^#[^#]+)').exec(href || '');
        if (results) {
            // Link para outro post
            if (results[1] && results[1] !== this.post._id) {

                const postConfig = {
                    _id: results[1],
                    portal: SDConfig.portalId,
                }

                let kebab_results2 = '';
                if(results[2]) {
                    kebab_results2 = kebabCase(results[2]);
                }

                getPost(postConfig)
                    .pipe(map(post2IPost))
                    .subscribe(post => {
                        let new_href = `${post.identifier}-${post._id}`;
                        
                        if (kebab_results2) {
                            let anchor = '#' + kebab_results2;
                            new_href = new_href + anchor;
                        }

                        let domain = window.location.origin;
                        const elems = document.querySelectorAll(`.ref-${post._id}${kebab_results2}`)
                        elems.forEach(elem => elem.setAttribute('href', `${domain}/blog/${new_href}`));
                    });

                // Retorna primeiro, href será atribuído após a requisição acima for realizada
                return `<a href="" class="ref-${results[1]}${kebab_results2}">${text}</a>`;
            }

            // Link de âncora para o mesmo post
            else {
                return `<a class="nav-link sy-scroll-to" data-offset="100"
                href="#${kebabCase(results[0])}"
                data-target="#${kebabCase(results[0])}">${text}</a>`;
            }
        }

        // Link externo ao blog
        else {
            return `<a href="${href}" target="_blank">${text}</a>`;
        }
    }
    
    image(href: string | null, title: string | null, text: string): string {

        let results = new RegExp('[/]').exec(href || '');
        if (results) {
            return `<img src="${href}" alt="${text || ''}" title="${title || ''}" />`
        }
        else {
            let image = (this.post.images || []).find(img => img.name === href);
            let imageUrl = image ? getImgURL(this.post._id, image._id) : '';
            let out = `<img src="${imageUrl}" alt="${text || ''}" title="${title || ''}" />`
            return out;
        }
    }

    blockquote(quote: string) {
        // tag de author que vai debaixo da quote
        // <span class="d-block text-muted font-size-1 mt-2">- Adrian Teh</span>
        return `
        <blockquote class="card bg-soft-primary border-0 text-center font-size-2 font-weight-medium text-lh-md p-5 my-5" style="color: #1e2022;">
            ${quote}
        </blockquote>
        `;
    }
}