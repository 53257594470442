import { Row, Col } from 'react-bootstrap';
import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const MoreInformation = () => {

  const [t] = useTranslation();

  return (
    <div id="more-information">

      <Row className="justify-content-center">
        <Col xs={12}>
          <h2 className="mi-title">{t('MORE_INFO_TITLE')}</h2>
          <p className="mi-sub-title">{t('MORE_INFO_SUB_TITLE')}</p>
        </Col>
      </Row>

      <Row className="justify-content-center">

        <div className="flex-gap-column-3">
          <Col xs={12} md className="">
            <div className="mi-card fit-content">
              <h3 className="mi-blue-title">
                {t('MORE_INFO_SHOW_WORK')}
              </h3>
              <div className="mi-description">
                {t('MORE_INFO_SHOW_WORK_TEXT')}
              </div>
            </div>
          </Col>

          <Col xs={12} md className="">
            <div className="mi-card fit-content">
              <h3 className="mi-blue-title">
                {t('MORE_INFO_LINKEDIN_SHARE')}
              </h3>
              <div className="mi-description">
                {t('MORE_INFO_LINKEDIN_SHARE_TEXT')}
              </div>
            </div>
          </Col>

          <Col xs={12} md className="">
            <div className="mi-card fit-content">
              <h3 className="mi-blue-title">
                {t('MORE_INFO_EXCHANGE_EXP')}
              </h3>
              <div className="mi-description">
                {t('MORE_INFO_EXCHANGE_EXP_TEXT')}
              </div>
            </div>
          </Col>

        </div>

      </Row>

      <Row>

        <Col xs={12}>
          <Link 
            to={{
              pathname: `/bpmn-editor`,
              state: {
                isUpload: true
              }
          }}>
            <button className="p-3 download-button-home">
              <div className="m-auto d-flex flex-row flex-wrap justify-content-center">
                <span className="download-text">
                  {t('MORE_INFO_BTN_TEXT')}
                </span>
                <svg height="16" width="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <defs/>
                  <title>add</title>
                  <line fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" x1="12" x2="12" y1="1" y2="23"/>
                  <line fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" x1="23" x2="1" y1="12" y2="12"/>
                </svg>
              </div>
            </button>
          </Link>
        </Col>

      </Row>
    </div>
  );
}

export default MoreInformation;
