
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './Auth';

const RoutePrivate = ({ component: Component, ...rest }) => {
  const { isAuthenticated, isLoading } = useAuth();

  return (
    <Route
      {...rest}
      render={() => isAuthenticated
        ? <Component {...rest} />
        : isLoading
          ? <div>Loading...</div>
          : <Redirect to={`/login?redirect=${rest.location.pathname}`} />
      }
    />
  )
}

export default RoutePrivate;