import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import { useTranslation } from "react-i18next";
import './_iframe.scss';
import { axiosAPI } from "../../services/api";
import { getProcess } from "../../services/sendRequest";

interface IframeProps {
  processId: string;
  isUpload: Boolean;
}

const Iframe = ({processId, isUpload}: IframeProps) => {
  // const [checksum, setChecksum] = useState(0);
  const [url, setUrl] = useState('');
  const [t, i18n] = useTranslation();
  const { user } = useAuth();
  const history = useHistory();

  // Timeout usado para recarregar o iframe em caso de espera indefinida de carregamento do workspace
  const [timeoutID, setTimeoutID] = useState<ReturnType<typeof setTimeout>>();
  const [iframeKey, setIframeKey] = useState<number>(0);

  const [workspaceLoaded, setWorkspaceLoaded] = useState<Boolean>(false);
  const [diagramLoaded, setDiagramLoaded] = useState<Boolean>(false);
  const [exporting, setExporting] = useState<Boolean>(false);
  const [printing, setPrinting] = useState<Boolean>(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  function b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode(parseInt('0x' + p1));
    }));
  }

  useEffect(() => {
    if (processId){
      let temp = `https://${process.env.REACT_APP_SYDLE_ONE_ENV}.sydle.one/ws1/#/app/processBox/workspace/60eed20ee06b3238e593c24f?language=${i18n.language.substring(0,2)}`
      setUrl(temp);
    }
  }, [i18n, processId]);


  useEffect(() => {
    if (workspaceLoaded && timeoutID) {
      clearTimeout(timeoutID);
    }
    return () => {
      if (timeoutID) clearTimeout(timeoutID);
    }
  }, [workspaceLoaded, timeoutID])

  useEffect(()=>{

    const openProcessDiagram = () => {
      sendMessage('_open', { // Abre o processo de volta
        queryParameters: {
          cid: "5e7e5a8ece0014567de5c190", // id da classe diagrama de modelagem
          id: processId, // id do diagrama a ser exibido
          vid: "6138b2e591191348236b6b7c" // id da view utilizada no workspace
        },
        view: "modelingProcessBox", // nome da view utilizada no workspace
        stack: "push"
      }, {
        slot: "explorer_object_details",
        target: "_workspace"
      });
    }

    const controller = {
      ready: (body) => {
        sendMessage('authenticate', {token: user?.token});
      },
      viewSize: (body) => { // Workspace iniciou o carregamento
        if (!workspaceLoaded && timeoutID === undefined) {
          let newTimeout = setTimeout(() => {
            setIframeKey(iframeKey+1);
            setTimeoutID(undefined);
          }, 45000);
          setTimeoutID(newTimeout);
        }
      },
      setLoadingView: (body) => {
        if (!diagramLoaded && processId) {
          openProcessDiagram();
        }
      },
      getViewToken: (body) => {
        if (body.accessToken && !workspaceLoaded) { // O workspace carregou com sucesso
          setWorkspaceLoaded(true);
        }
      },
      concludeEdition: (body) => {
        sendMessage('navigate', 'print', {
          slot: "_all",
          target: "_all"
        });
        setPrinting(true);
      },
      diagramLoaded: (body) => {
        setDiagramLoaded(true);
        if (printing) {
          sendMessage('snapshot', false, {
            slot: "_all",
            target: "_all"
          });
        }
      },
      snap: (body) => {
        let svgBase64 = b64EncodeUnicode(body.image);
        axiosAPI(user).post("editProcess", {
          processFields: {
            images: [{
              name: processId + ".svg",
              type: "image/svg+xml",
              base64: svgBase64
            }],
            exportDiagram: true
          },
          _id: processId,
        }).then(response => {
          if (isUpload) {
            history.push(`/upload/${processId}`);
          } else {
            history.push(`/edit/${processId}`);
          }
        }).catch();
      },
      _setTitle: (body) => {
        if (body.title.match(/export/i)) setExporting(true);
      },
      _close: (body) => {
        if (body.cause.type === 'cancel' && navigator.userAgent.match(/firefox\/.+/i)) { // Necessário para conseguir recarregar o iframe no Firefox (Bug do Workspace não pedir o viewAccessToken)
          reloadIframe();
        }
      },
      _open: (body) => {
        if (diagramLoaded && body.queryParameters.cid === '5e7e5a8ece0014567de5c190' && body.queryParameters.id !== processId) { // Está abrindo um subprocesso referenciado
          let linkedProcessId = body.queryParameters.id;
          getProcess(linkedProcessId)
          .then((process) => {
            window.open(`${window.location.origin}/p/${process.identifier}-${process._id}`, '_blank');
          });
          openProcessDiagram();
        }
      }
    };

    const eventCallback = (event) => {
      if (event.data && typeof event.data === 'string') {
        try {
          const data: any = JSON.parse(event.data);
          console.log(data);
          controller[data.subject](data.body);
        } catch (e) { }
      }
    }

    window.addEventListener('message', eventCallback, false);

    // Remover listener no rerender da página (porque será adicionado um novo, com um eventCallback diferente)
    return () => {
      window.removeEventListener('message', eventCallback, false);
    }
  }, [processId, diagramLoaded, isUpload, user, history, printing, exporting, url, workspaceLoaded, iframeKey, timeoutID]);

  function sendMessage(subject, body, recipient?) {
    recipient = recipient || {
      slot: null,
      target: null
    };

    const message = {
      source: 'SYDLE_EXPLORER',
      subject,
      delay: 0,
      body,
      recipient
    };

    iframeRef?.current?.contentWindow?.postMessage(JSON.stringify(message), '*');
  }

  function reloadIframe() {
    setIframeKey(iframeKey => iframeKey+1);
    setWorkspaceLoaded(false);
    setDiagramLoaded(false);
    setExporting(false);
    setPrinting(false);
  }

  return (
    <div id="editor-container" className="d-flex flex-column align-items-center">
      <div className={printing || !diagramLoaded ? "loader w-100 h-100 text-center d-flex flex-column justify-content-end" : "loader d-none"}><div>{printing ? t("EDITOR_SAVING") : t("EDITOR_LOADING")}</div></div>
      <iframe className={printing || !diagramLoaded ? "invisible" : undefined} id="process-editor" key={iframeKey} ref={iframeRef} src={url} width="100%" height="100%" title="description"></iframe>
    </div>
  )
}

export default Iframe;
