import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { from } from "rxjs";
import { map } from "rxjs/operators";
import * as yup from "yup";
import { useAuth } from "../../auth/Auth";
import DynamicHead from "../../components/dynamicHead/dynamicHead";
import Footer from "../../components/footer/footer";
import GenericCarousel from "../../components/generic-carousel/generic-carousel";
import MarkdownEditor from "../../components/markdown-editor/markdown-editor";
import NavBar from "../../components/navBar/navBar";
import ProcessNavBar from "../../components/processNavBar/processNavBar";
import { axiosAPI } from "../../services/api";
import * as api from "../../services/sendRequest";
import { getTags } from "../../services/sendRequest";
import utils from "../../utils";
import InputImages from "./inputImages";
import InputTags, { convertTags2Tagify } from "./inputTags";
import RemoveProcessModal from "./removeProcessModal";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

type ParamsType = {
  pId: string
}

const isEditing = (url = window.location.href) => {
  const regex = /\/edit\/([0-9a-f]{24})/;

  if (!regex.test(url)) {
    return false;
  }

  return regex.exec(url)?.[1] || false;
};

export declare interface IFile {
  url: string;
  name: string;
  type: string;
  alt: string;
}
export declare interface File {
  name: string;
  type: string;
  base64: string;
}

// Recebe array de arquivos e gera objeto para ser criado no sydle-one
export async function getFileObj(data, fileMaxSize = 0) {
  let list = Object.values(data || {}) as any[];

  return Promise.all(
    list.map((file) => {
      return new Promise<File>(async (resolve, reject) => {
        try {
          let rawBase64 = await (utils.toBase64(file) as Promise<string>);
          const fileSizeMB = file.size / 1024 / 1024;

          if (fileMaxSize && fileSizeMB > fileMaxSize) {
            // eslint-disable-next-line no-throw-literal
            throw `Tamanho do arquivo excedido. Favor inserir um arquivo com até ${fileMaxSize} MB.`;
          }

          const fileObj: File = {
            name: file.name,
            type: file.type || "application/octet-stream",
            base64: rawBase64,
          };
          resolve(fileObj);
        } catch (error) {
          reject(error);
        }
      });
    })
  );
}

const UploadProcess = () => {
  const [isSending, setIsSending] = useState(false);
  const [isSendingDraft, setIsSendingDraft] = useState(false);
  const [categories, setCategories] = useState<any[]>([]);
  const [fileMaxSize, setFileMaxSize] = useState(0);
  const [lastUpdate] = useState<string>("");
  const [imagesCarousel, setImagesCarousel] = useState<any[]>([]);
  const [imagesFileList, setImagesFileList] = useState<IFile[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [process, setProcess] = useState<any>(null);
  const [formDefaults, setFormDefaults] = useState<{} | null>(null);
  const [title, setTitle] = useState("");
  const [languages, setLanguages] = useState<any>(null);
  const [alternativeUrls, setAlternativeUrls] = useState<Array<any>>([]);
  const [t, i18n] = useTranslation();

  const [placeholder, setPlaceholder] = useState<string>(
    t("UPLOAD_PROCESS_DESCRIPTION")
  );

  const { pId } = useParams<ParamsType>();

  const { user } = useAuth();
  const history = useHistory();

  const validationShape: any = {
    description: yup.string().min(1).required(),
    isPublished: yup.boolean(),
    isEditingDiagram: yup.boolean().default(false),
    category: yup
      .string()
      .when(["isPublished"], {
        is: true,
        then: yup.string().min(1).required()
      }
      )
      .test(
        "selectedCategory",
        "Selecione uma categoria",
        (value) => value !== "selecione"
      ),
    informations: yup
      .string()
      .nullable()
      .when(["isPublished"], {
        is: true,
        then: yup.string().min(1).required()
      }
      ),
    roles: yup
      .array()
      .of(
        yup.object({
          value: yup.string().min(1),
        })
      )
      .min(1)
      .required(),
    tags: yup.array().of(
      yup.object({
        value: yup.string().min(1),
      })
    ),
  };
  const validationSchema = yup.object().shape(validationShape);

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    errors,
    setError,
    clearErrors,
  } = useForm({
    // mode: "onChange",
    defaultValues: {
      roles: [{ value: "" }],
    },
    resolver: yupResolver(validationSchema),
  });

  const watchImages: any[] = watch("images");

  const roles = useFieldArray({
    control,
    name: "roles",
  });

  const onEditting = (processId: string) => {
    api.getProcess(processId)
      .then((process) => setProcess(process))
      .catch((error) => console.log(error));
  };

  // []
  useEffect(() => {
    const fn = async () => {
      //TODO - adicionar verificação de usuário logado após concluido BUG001741

      const categories = api.getCategories();
      let siteConfig = await api.getSiteConfig({
        string: "Upload",
        identifier: "default",
      });
      if (siteConfig) {
        setFileMaxSize(siteConfig.fileMaxSize);
        setTitle(siteConfig.title);
        setLanguages(siteConfig.languagesRaw);
      }
      setCategories(await categories);

      setAlternativeUrls(await utils.setAlternativeUrls(i18n.language));
    };
    fn();

    setIsLoading(true);
    onEditting(pId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (process) setTitle(`${process.description} - ProcessBox`)
  }, [process])

  useEffect(() => {
    if (process && isLoading) {
      if (process.author._id !== user?.code) {
        history.push("/");
      }

      let images: (IFile)[] = process?.images ? [{
        url: utils.getProcessImageUrl(process.identifier, process._id),
        name: process.images[0].name,
        type: process.images[0].contentType,
        alt: `${t("BPMN_PROCESS")} - ${process.description}`
      }] : [];

      const fn = async () => {

        let tags: any = getTags((process.tags || []).map((tag) => tag._id))
          .pipe(map(convertTags2Tagify))
          .toPromise();

        tags = await tags;
        setFormDefaults({
          description: process.description,
          category: process.category?._id,
          informations: process.informations,
          roles: process.roles.map((value) => ({ value })),
          images: (images || []),
          tags: tags,
          isPublished: process.isPublished
        });
        setIsLoading(false);
      };

      fn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process]);

  useEffect(() => {
    if (formDefaults) {
      for (let key of Object.keys(formDefaults)) {
        setValue(key, formDefaults[key]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDefaults]);

  //watchImages
  useEffect(() => {
    if (watchImages?.length) {
      setImagesFileList(watchImages || []);
    }
  }, [watchImages]);

  // imagesFileList
  useEffect(() => {
    if (imagesFileList) {
      setValue("images", imagesFileList);
    }
  }, [imagesFileList, setValue]);

  // imagesFileList
  useEffect(() => {
    if (imagesFileList) {
      setImagesCarousel(imagesFileList.map((file: IFile) => {
        return {
          src: file.url, alt: file.alt
        }
      })
      );
    }
  }, [imagesFileList]);

  useEffect(() => {
    if (t("UPLOAD_PROCESS_DESCRIPTION") !== placeholder)
      setPlaceholder(t("UPLOAD_PROCESS_DESCRIPTION"));
  }, [t, placeholder]);

  // Envio do formulário
  async function submitHandler(obj) {

    obj.author = user;

    if (obj.category) {
      obj.category = {
        name: categories.find((val) => val._id === obj.category).name,
        _id: obj.category,
      };
    }

    obj.roles = obj.roles.map((role) => role.value);

    obj.tags = (obj.tags || []).map((tag) => tag._id);

    obj.language = languages.find(
      (lang) => lang.code === i18n.language.substr(0, 2)
    );

    if (obj.isPublished && !obj.isEditingDiagram) {
      setIsSending(true);
    } else if (!obj.isPublished && !obj.isEditingDiagram) {
      setIsSendingDraft(true);
    }
    editProcess(obj);
  }

  function editProcess(obj) {
    obj.images = null; // Não enviar a imagem; ela é atualizada sempre que se conclui a edição do diagrama.
    from(
      axiosAPI(user).post("editProcess", {
        processFields: obj,
        _id: process._id,
      })
    )
      .pipe(
        map((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            throw new Error("Update error.");
          }
        })
      )
      .subscribe(
        (process) => {
          let identifier = process.identifier;
          let id = process._id;
          if (obj.isEditingDiagram) {
            history.push({
              pathname: `/bpmn-editor/${id}`,
              state: {
                isUpload: !isEditing(),
                name: process.description
              }
            });
          } else {
            history.push(`/p/${identifier}-${id}`);
          }
        },
        (err) => console.log("Erro ao realizar requisição uploadProcess:", err)
      );
  }

  function removeProcess() {
    from(
      axiosAPI(user).post("removeProcess", {
        _id: process._id,
      })
    )
      .pipe(
        map((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            throw new Error("Remove error.");
          }
        })
      )
      .subscribe(
        () => {
          history.push("/");
        },
        (err) => console.log(err)
      );
  }

  function invalidTagCallback() {
    setError("tags", { type: "invalid_tag", message: t("INVALID_TAG") });
    setTimeout(() => {
      clearErrors("tags");
    }, 3000);
  }

  const UploadImages = (
    <div className={"upload-images " + (errors.images ? "is-invalid" : "")}>
      <Card>
        <Controller
          control={control}
          name="images"
          defaultValue={[]}
          render={({ onChange, value }) => (
            <InputImages
              value={value}
              onChange={onChange}
              fileMaxSize={fileMaxSize}
            >
            </InputImages>
          )}
        />
        {imagesCarousel?.length > 0 ? (<>
          <GenericCarousel
            images={imagesCarousel}
          />
          <Card.ImgOverlay className="d-flex justify-content-end align-items-end">
            <Button
              type="button"
              variant="outline-secondary"
              className="edit-process-button"
              size="lg"
              onClick={() => {
                setValue('isEditingDiagram', true)
                handleSubmit(submitHandler)();
              }}
              {...register("isEditingDiagram")}
            >
              <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <path d="M9.55,6.5h-7a2,2,0,0,0-2,2v13a2,2,0,0,0,2,2h19a2,2,0,0,0,2-2V8.5a2,2,0,0,0-2-2H19.45" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6,10H4.5a1,1,0,0,0-1,1v5a1,1,0,0,0,1,1H11a1,1,0,0,0,1-1V14" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12,14h1.5a1,1,0,0,1,1,1v4.5a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V17" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M20.5,11.5a1,1,0,0,1-1,1h-1a1,1,0,0,1,0-2h1A1,1,0,0,1,20.5,11.5Z" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M20.06.94a1.49,1.49,0,0,0-2.12,0L8.85,10,8,13l3-.85,9.09-9.09A1.49,1.49,0,0,0,20.06.94Z" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" />
                  <rect height="6" width="3" fill="none" rx="1" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" x="17.5" y="14.5" />
                </g>
              </svg>
            </Button>
          </Card.ImgOverlay>
        </>) : (
          <div className="no-image">
            <Button
              type="button"
              variant="outline-secondary"
              block
              className="edit-process-button d-flex align-items-center justify-content-center"
              onClick={() => {
                setValue('isEditingDiagram', true)
                handleSubmit(submitHandler)();
              }}
              {...register("isEditingDiagram")}
            >
              <svg height="40" width="40" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <path d="M9.55,6.5h-7a2,2,0,0,0-2,2v13a2,2,0,0,0,2,2h19a2,2,0,0,0,2-2V8.5a2,2,0,0,0-2-2H19.45" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6,10H4.5a1,1,0,0,0-1,1v5a1,1,0,0,0,1,1H11a1,1,0,0,0,1-1V14" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12,14h1.5a1,1,0,0,1,1,1v4.5a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V17" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M20.5,11.5a1,1,0,0,1-1,1h-1a1,1,0,0,1,0-2h1A1,1,0,0,1,20.5,11.5Z" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M20.06.94a1.49,1.49,0,0,0-2.12,0L8.85,10,8,13l3-.85,9.09-9.09A1.49,1.49,0,0,0,20.06.94Z" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" />
                  <rect height="6" width="3" fill="none" rx="1" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" x="17.5" y="14.5" />
                </g>
              </svg>
              {t("EDIT_DIAGRAM_BUTTON")}
            </Button>
          </div>
        )}
      </Card>
    </div>
  );

  const UploadCard = (
    <div className="upload-card p-absolute-lg">
      <Card>
        <Card.Body>
          <div className="author-box d-flex flex-row mb-4">
            {user?.photo && user?.photo._id ? (
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="tooltip-user-photo">{user.name}</Tooltip>}
              >
                <Image
                  roundedCircle
                  className="clickable"
                  src={utils.getPersonImageUrl(user.code)}
                  alt={user.name}
                />
              </OverlayTrigger>
            ) : (
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip id="tooltip-user-photo">{t("LITERAL_USER")}</Tooltip>
                }
              >
                <Image
                  roundedCircle
                  className="clickable"
                  src={require("../../assets/custom/svg/user_icon.svg")}
                  alt="Usuário"
                />
              </OverlayTrigger>
            )}

            <div>
              <p className="name">{user?.name}</p>
              <p className="job-title">{user?.profession}</p>
            </div>
          </div>

          {lastUpdate ? (
            <div className="last-update mb-4">
              <p className="title">{t("UPLOAD_PROCESS_CARD_LAST_UPDATE")}</p>
              <p className="date">
                {moment(new Date(lastUpdate)).format("DD/MM/YYYY")}
              </p>
            </div>
          ) : null}

          {
            //Manter comentado. Até o momento, são exibidos nas buscas apenas processos no mesmo idioma do site. Dessa forma,
            // caso o usuário queira cadastrar um processo em um idioma diferente, ele deve trocar o idioma de todo o site.
            // Caso tenha futuros problemas de usabilidade, rever o uso do box
            /* <Form.Group>
            <Form.Control
              as="select"
              size="sm"
              custom
              className={'form-fields form-field-select w-100  ' +
                (errors.language ? 'is-invalid' : '')}
              ref={register()}
              name="language"
            >
              <option
                key="select"
                value="selecione"
              >
                {t('UPLOAD_PROCESS_SELECT_LANGUAGE')}
              </option>

              {languages?.map((language) =>
                <option
                  id={language._id}
                  key={language.code.toUpperCase()}
                  value={language.code.toUpperCase()}
                  data-name={language.name}
                >
                  {language.name}
                </option>
                )

              }
            </Form.Control>

            <div className="invalid-feedback">
              {t('UPLOAD_PROCESS_SELECT_LANGUAGE_ERROR')}
            </div>
          </Form.Group> */
          }
          <Form.Group>
            <Button
              type="button"
              variant="outline-secondary"
              block
              className="edit-process-button d-flex align-items-center justify-content-center"
              size="lg"
              onClick={() => {
                setValue('isEditingDiagram', true)
                handleSubmit(submitHandler)();
              }}
              {...register("isEditingDiagram")}
            >
              <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <path d="M9.55,6.5h-7a2,2,0,0,0-2,2v13a2,2,0,0,0,2,2h19a2,2,0,0,0,2-2V8.5a2,2,0,0,0-2-2H19.45" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6,10H4.5a1,1,0,0,0-1,1v5a1,1,0,0,0,1,1H11a1,1,0,0,0,1-1V14" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12,14h1.5a1,1,0,0,1,1,1v4.5a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V17" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M20.5,11.5a1,1,0,0,1-1,1h-1a1,1,0,0,1,0-2h1A1,1,0,0,1,20.5,11.5Z" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M20.06.94a1.49,1.49,0,0,0-2.12,0L8.85,10,8,13l3-.85,9.09-9.09A1.49,1.49,0,0,0,20.06.94Z" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" />
                  <rect height="6" width="3" fill="none" rx="1" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" x="17.5" y="14.5" />
                </g>
              </svg>
              {t("EDIT_DIAGRAM_BUTTON")}
            </Button>
          </Form.Group>

          <Form.Group>
            <Button
              type="button"
              variant="primary"
              block
              size="lg"
              className="submit-button d-flex align-items-center justify-content-center"
              onClick={() => {
                setValue('isPublished', true)
                handleSubmit(submitHandler)();
              }}
            >
              <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <defs />
                <title>network-upload</title>
                <line fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" x1="1.897" x2="9.5" y1="17.5" y2="17.5" />
                <line fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" x1="2.51" x2="21.495" y1="5.5" y2="5.5" />
                <line fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" x1="11" x2="0.509" y1="11.5" y2="11.5" />
                <line fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" x1="17.5" x2="17.5" y1="20.5" y2="14.5" />
                <line fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" x1="17.5" x2="15.25" y1="14.5" y2="16.75" />
                <line fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" x1="17.5" x2="19.75" y1="14.5" y2="16.75" />
                <path d="M11.376,23.484A11.5,11.5,0,1,1,23.336,10" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.268.526c-6,6.5-5.892,14.958.108,22.958" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12.729.526A16.134,16.134,0,0,1,16.994,9" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" />
                <circle cx="17.5" cy="17.5" fill="none" r="6" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              {isSending
                ? t("UPLOAD_PROCESS_SENDING_BUTTON")
                : t("UPLOAD_PROCESS_SAVE_BUTTON")}
            </Button>
          </Form.Group>
          <hr />
          {process?.isPublished === false ?
            <Button
              type="button"
              variant="link"
              block
              size="sm"
              className="save-draft-button d-flex align-items-center justify-content-start"
              onClick={() => {
                setValue('isPublished', false)
                handleSubmit(submitHandler)();
              }}
              {...register("isPublished")}
            >
              <svg height="14" width="14" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <defs />
                <title>logout-1</title>
                <path d="M13.5,5V1.5a1,1,0,0,0-1-1H1.5a1,1,0,0,0-1,1v21a1,1,0,0,0,1,1h11a1,1,0,0,0,1-1V19" fill="none" stroke="#18a0fb" strokeLinecap="round" strokeLinejoin="round" />
                <line fill="none" stroke="#18a0fb" strokeLinecap="round" strokeLinejoin="round" x1="23.5" x2="4.5" y1="12" y2="12" />
                <polyline fill="none" points="18.5 17 23.5 12 18.5 7" stroke="#18a0fb" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span>
                {isSendingDraft
                  ? t("UPLOAD_PROCESS_SENDING_BUTTON")
                  : t("SAVE_DRAFT_BUTTON")}
              </span>
            </Button>
            : null
          }

          {isEditing() && (
            <RemoveProcessModal
              onConfirm={removeProcess}
              render={({ handleShow }) => {
                return (
                  <Button
                    type="button"
                    variant="link"
                    className="remove-button d-flex align-items-center justify-content-start"
                    size="sm"
                    block
                    onClick={handleShow}
                  >
                    <svg height="14" width="14" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <defs />
                      <title>bin</title>
                      <path d="M20.5,3.5v19a1,1,0,0,1-1,1H4.5a1,1,0,0,1-1-1V3.5" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M7.5,3.5v-2a1,1,0,0,1,1-1h7a1,1,0,0,1,1,1v2" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" />
                      <line fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" x1="0.5" x2="23.5" y1="3.5" y2="3.5" />
                      <line fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" x1="7.5" x2="7.5" y1="7" y2="19" />
                      <line fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" x1="12" x2="12" y1="7" y2="19" />
                      <line fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" x1="16.5" x2="16.5" y1="7" y2="19" />
                    </svg>
                    <span>
                      {t("UPLOAD_PROCESS_REMOVE_BUTTON")}
                    </span>
                  </Button>
                );
              }}
            ></RemoveProcessModal>
          )}
        </Card.Body>
      </Card>
    </div>
  );

  // if(isLoading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <div id="upload-diagram-process">
      <DynamicHead
        title={title}
        description=""
        url={window.location.origin + window.location.pathname}
        imgUrl=""
        alternativeUrlsData={alternativeUrls}
      />

      <NavBar type={"Process"} searchURL="/search" hasSearchBox alternativeUrlsData={alternativeUrls} />

      <Container>
        <ProcessNavBar name={t("UPLOAD_TITLE")} category={categories?.find(c => c._id === process?.category?._id)} isPublished={process?.isPublished} />

        <Row>
          {categories ? (
            <form onSubmit={handleSubmit(submitHandler)}>
              <Container>
                <Row className="pb-2">
                  <div className="flex-gap-column-2">
                    <Col xs={12} md={8} lg={5} className="order-0">
                      <Form.Group>
                        <Form.Label>{t("UPLOAD_PROCESS_NAME")}</Form.Label>
                        <Form.Control
                          size="sm"
                          className={
                            "form-fields form-field-name " +
                            (errors.description ? "is-invalid" : "")
                          }
                          ref={register()}
                          name="description"
                        // readOnly={isLoading}
                        />
                        <div className="invalid-feedback">
                          {t("UPLOAD_PROCESS_NAME_PROCESS_ERROR")}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={4} lg={3} className="order-1">
                      <Form.Group>
                        <Form.Label>{t("UPLOAD_PROCESS_CATEGORY")}</Form.Label>
                        <Form.Control
                          as="select"
                          size="sm"
                          custom
                          className={
                            "form-fields form-field-select w-100  " +
                            (errors.category ? "is-invalid" : "")
                          }
                          ref={register()}
                          name="category"
                        >
                          <option key="select" value="selecione">
                            {t("UPLOAD_PROCESS_SELECT_CATEGORY")}
                          </option>

                          {categories?.map((category) =>
                            category.enabled ? (
                              <option
                                id={category._id}
                                key={category._id}
                                value={category._id}
                                data-name={category.name}
                              >
                                {category.name}
                              </option>
                            ) : null
                          )}
                        </Form.Control>
                        <div className="invalid-feedback">
                          {t("UPLOAD_PROCESS_SELECT_CATEGORY_ERROR")}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col
                      xs={12}
                      lg={4}
                      className="p-static p-sticky-lg-top order-5 order-lg-2"
                    >
                      {UploadCard}
                    </Col>

                    <Col xs={12} lg={8} className="order-2 order-lg-3">
                      <Form.Group className="m-0">
                        {UploadImages}
                        <div className="invalid-feedback">
                          {t("UPLOAD_PROCESS_SELECT_IMAGE_ERROR")}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col xs={12} lg={8} className="order-3 order-lg-4">
                      <Controller
                        control={control}
                        name="informations"
                        defaultValue=""
                        render={({ onChange, value }) => (
                          <MarkdownEditor
                            className={errors.informations ? "is-invalid" : ""}
                            onChange={onChange}
                            value={value}
                            placeholder={placeholder}
                          />
                        )}
                      />
                      <div className="invalid-feedback">
                        {t("UPLOAD_PROCESS_DESCRIPTION_ERROR")}
                      </div>
                    </Col>

                    <Col xs={12} lg={8} className="order-4 order-lg-5">
                      <Form.Group>
                        <Form.Label className="d-flex flex-row align-content-center">
                          <div>{t("UPLOAD_PROCESS_ROLES")}</div>
                        </Form.Label>

                        <Row className="flex-fill m-n2">
                          {roles.fields.map((role, index) => (
                            <Col
                              xs={12}
                              sm={6}
                              md={4}
                              className="d-flex input-group p-2"
                              key={role.id}
                            >
                              <Form.Control
                                className={
                                  "form-fields flex-fill " +
                                  (errors.roles?.[index]?.value
                                    ? "is-invalid"
                                    : "")
                                }
                                size="sm"
                                key={role.id}
                                name={`roles[${index}].value`}
                                ref={register()}
                                defaultValue={role.value}
                                readOnly
                              />
                            </Col>
                          ))}
                        </Row>
                        <div className={errors.roles ? "is-invalid" : ""}></div>
                        <div className="invalid-feedback">
                          {t("UPLOAD_PROCESS_ROLES_ERROR")}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col xs={12} lg={8} className="order-4 order-lg-5">
                      <Form.Group className="input-tags">
                        <Form.Label className="d-flex flex-row align-content-center">
                          <div>Tags</div>
                        </Form.Label>

                        <Controller
                          control={control}
                          name="tags"
                          defaultValue={[]}
                          render={({ onChange, value }) => (
                            <InputTags
                              value={value}
                              onChange={onChange}
                              invalidCallback={invalidTagCallback}
                            />
                          )}
                        />
                        <div className={errors.tags ? "is-invalid" : ""}></div>
                        <div className="invalid-feedback">
                          {errors.tags?.message}
                        </div>
                        <small>{t("UPLOAD_PROCESS_TAGS_LIMIT_WARNING")}</small>
                      </Form.Group>
                    </Col>

                    <Col xs={12} lg={8} className="order-4 order-lg-5">
                      <Form.Group>
                        <Form.Label className="d-flex flex-row align-content-center">
                          <div>{t("PROCESS_LINKED_PROCESSES")}</div>
                        </Form.Label>
                      </Form.Group>
                      <div className="">
                        {process?.linkedProcesses?.map((linkedProcess, index) =>
                          <span key={linkedProcess._id}>
                            <Link className="d-inline-block ml-1 mb-2" to={`/p/${linkedProcess.identifier}-${linkedProcess._id}`}>
                              <span className="referred-linkedProcess-link">{linkedProcess.description}</span>
                            </Link>{index < process.linkedProcesses.length - 1 ? "," : ""}
                          </span>
                        )}
                      </div>
                    </Col>
                  </div>
                </Row>
              </Container>
            </form>
          ) : null}
        </Row>

        <Footer />
      </Container>
    </div>
  );
};

export default UploadProcess;
