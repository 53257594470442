import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import DynamicHead from '../../components/dynamicHead/dynamicHead';
import Footer from '../../components/footer/footer';
import NavBar from '../../components/navBar/navBar';
import { Spinner, Container } from 'react-bootstrap';
import { SocialProvider } from '../../auth/AuthClient';
import { useAuth } from '../../auth/Auth';
import { getSiteConfig } from '../../services/sendRequest';
import { useTranslation } from 'react-i18next';
import utils from '../../utils';

const Login = (props: any) => {
    const [t, i18n] = useTranslation();
    const [googleLoading, setGoogleLoading] = useState(false);
    const [facebookLoading, setFacebookLoading] = useState(false);
    const [linkedinLoading, setLinkedinLoading] = useState(false);
    const [alternativeUrls, setAlternativeUrls] = useState<Array<any>>([]);
    const { login } = useAuth();

    const [title, setTitle] = useState('')
    

    useEffect(()=>{
        async function fetchData() {
            let siteConfig = await getSiteConfig({
				identifier: 'default',
				string: 'Login'
			});


            if (siteConfig) {
                setTitle(siteConfig.title)
                setAlternativeUrls(await utils.setAlternativeUrls(i18n.language));
            }
        }
        fetchData()
    }, [i18n.language]);

    const query_params = new URLSearchParams(props.location.search);

    function handleLogin(provider: SocialProvider) {
        let redirect = query_params.get('redirect') || '';

        switch (provider) {
            case 'facebook':
                setFacebookLoading(true);
                break;
            case 'linkedin':
                setLinkedinLoading(true);
                break;
            case 'google':
                setGoogleLoading(true);
                break;
        }

        login({
            provider,
            redirect
        });
    }
    return (
        <div id="login">
            <DynamicHead
                title={title}
                description="Login"
                url={window.location.origin + window.location.pathname}
                imgUrl=''
                alternativeUrlsData={alternativeUrls}
            />

            <div className="login-flex d-flex flex-column justify-content-between">

                <NavBar type="Clean" alternativeUrlsData={alternativeUrls} />

                <Container>

                    <div className="login-card">
                        <h2>{t('LOGIN_CARD_TITLE')}</h2>

                        <div className="social-login-buttons">
                            <button
                                className="sy-ga-event-click facebook"
                                data-category="Home"
                                data-action="Click"
                                data-label="Autenticação Facebook"
                                disabled={facebookLoading}
                                onClick={() => { handleLogin(SocialProvider.Facebook) }}
                            >
                                <img src={require("../../assets/custom/svg/social-networks-logo/icon-login-facebook.svg")} alt="Login Facebook" />
                                {facebookLoading ? <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner> : 'Facebook' }
                            </button>
                            <button
                                className="sy-ga-event-click linkedin"
                                data-category="Home"
                                data-action="Click"
                                data-label="Autenticação LinkedIn"
                                disabled={linkedinLoading}
                                onClick={() => { handleLogin(SocialProvider.LinkedIn) }}
                            >
                                <img src={require("../../assets/custom/svg/social-networks-logo/icon-login-linkedin.svg")} alt="Login LinkedIn" />
                                {linkedinLoading ?
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner> : 'LinkedIn'}
                            </button>
                            <button
                                className="sy-ga-event-click google"
                                data-category="Home"
                                data-action="Click"
                                data-label="Autenticação Google"
                                onClick={() => { handleLogin(SocialProvider.Google) }}
                            >
                                <img src={require("../../assets/custom/svg/social-networks-logo/icon-login-google.svg")} alt="Login Google" />
                                {googleLoading ?
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner> : 'Google'}
                            </button>
                        </div>

                        <div className="line"></div>
                        {/* VER COM OS MESTRES SOBRE ESSE "E" */}
                        <p>{t('LOGIN_CARD_TEXT')}<Link to="/blog/terms-of-use-5fdcae8a725a642683661353" target="_blank" rel="noopener noreferrer">{t('LOGIN_TERMS_LINK')}</Link> {t('AND')} <Link to="/blog/privacy-policy-5fdcb10e725a642683663e09" target="_blank" rel="noopener noreferrer">{t('LOGIN_PRIVACY_POLICY_LINK')}</Link>.</p>

                    </div>

                </Container>

                <Footer className="footer" disableLogo />
            </div>
        </div>
    );
}

export default Login;

