import axios from 'axios';
import { from, Observable, of } from "rxjs";
import { catchError, map } from 'rxjs/operators';
import Tag from '../models/tag';
import { axiosAPI } from "./api";

const sendRequest = (method: string, useGet: boolean, data: any) => {

  const lang = localStorage.getItem('i18nextLng') || 'en';

  return (!useGet || JSON.stringify(data).length > 1800 ?
    axiosAPI().post(method, data) :
    axiosAPI().get(method, {
      params: {
        _body: data,
        _lang: lang
      },
      headers: {
        'accept-language': lang
      },
      paramsSerializer: function (params) {
        params._body = JSON.stringify(params._body);
        let searchParams = new URLSearchParams(params);
        return searchParams.toString();
      }
    }));
};

export const getAllProcess = async (filters, params) => {

  // gera um novo valor a cada dia pra recarregar o cache com possiveis novos processos
  let _dataCache = Math.floor((new Date()).valueOf() / 86400000);

  const result = await sendRequest('getAllProcess', true, { filters, params, _dataCache })
    .then(response => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch(err => {
      console.log('Erro ao realizar requisição getAllProcess:', err);
    });
  return result;
}
export const getAssessmentsFromProcess = async (filters, params) => {
  const result = await sendRequest('getAssessmentsFromProcess', true, { filters, params })
    .then(response => {
      if (response.status === 200) {
        return response.data
      }
    })
    .catch(err => {
      console.log('Erro ao realizar requisição getAssessmentsFromProcess:', err);
    });
  return result;
}
export const getSiteConfig = async (config) => {
  const result = await sendRequest('getSiteConfig', true,
    { _id: process.env.REACT_APP_CONFIG_OBJECT, config: config })
    .then(response => {
      if (response.status === 200) {
        return response.data.config;
      }
    })
    .catch(err => {
      console.log('Erro ao realizar requisição getSiteConfig:', err);
      return null;
    });
  return result;
}
export const getSocialNetworkConfig = async (data: any) => {

  data.sdPortalId ? data = Object.assign(data, { portalId: data.sdPortalId })
    : data = Object.assign(data, { portalId: "5edaa28c7f7f6474ee97c3ce" })

  const result = await sendRequest('getSocialNetworkConfig', true, data)
    .then(response => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch(err => {
      console.log('Erro ao realizar requisição getSocialNetworkConfig:', err);
      return null;
    });
  return result;
}
export const getFileDownload = async (objectId, user, format?) => {

  const baseURL = process.env.REACT_APP_SYDLE_ONE_API;

  const result = await axiosAPI(user).post('getFileDownloadLink', { objectId, baseURL, format })
    .then(response => {
      if (response.status === 200) {
        // Retorna link para download
        return response.data.result;
      }
    })
    .catch(err => {
      if (err.response) {
        // Requisição foi enviada e servidor retornou um status code diferente de 200
        // Usuário não autorizado
        if (err.response.status === 403) {
          return "openSignInModal";
        }
        console.log(err.response);
      }
      else if (err.request) {
        // Requisição foi enviada porém resposta não foi recebida.
        console.log(err.request);
      }
      else {
        // Algum problema nas configurações da requisição
        console.log(err.message)
      }
    });
  return result;
}
export const registerView = async (processId, user, navigator, url, queryParameters) => {
  const result = await axiosAPI(user).post('registerView', { processId, user, navigator, url, queryParameters })
    .then(response => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch(err => {
      console.log('Erro ao realizar requisição registerView:', err);
      return null;
    });
  return result;
}
export const uploadProcess = async (processFields, user) => {
  const result = await axiosAPI(user).post('uploadProcess', { processFields })
    .then(response => {
      if (response.status === 200) {
        // Processo criado com sucesso
        return response.data;
      }
    })
    .catch(err => {
      console.log('Erro ao realizar requisição uploadProcess:', err);
      return null;
    });
  return result;
}
export const getCategories = (categoryId?: string) => {
  let data = {
    categoryId: categoryId
  }

  return sendRequest('getCategories', true, data)
    .then(response => {
      if (response.status === 200) {
        if (response.data.error) {
          throw new Error(response.data.error);
        }

        // Categorias retornadas com sucesso
        return response.data.categories;
      }
    })
    .catch(err => {
      console.log('Erro ao realizar requisição getCategories:', err);
      return null;
    });
}

export const getUser = (userId: string) => {
  return from(sendRequest('getPerson', true, { personId: userId }))
    .pipe(
      map(response => response.data),
      catchError(err => {
        console.log('Erro ao buscar autor:', err)
        return of(null);
      })
    );
}

export const sendReview = async (reviewFields, user) => {
  const result = await axiosAPI(user).post('sendReview', { reviewFields })
    .then(response => {
      if (response.status === 200) {
        // Avaliação criada com sucesso
        return response.data;
      }
    })
    .catch(err => {
      console.log('Erro ao realizar requisição sendReview:', err);
      return null;
    });
  return result;
}
export const removeReview = async (removeReviewFields, user) => {
  const result = await axiosAPI(user).post('removeReview', { removeReviewFields })
    .then(response => {
      if (response.status === 200) {
        // Avaliação removida com sucesso
        return response.data;
      }
    })
    .catch(err => {
      console.log('Erro ao realizar requisição removeReview:', err);
      return null;
    });
  return result;
}
export const getTags = function (ids?: string[]): Observable<Tag[]> {
  return from(sendRequest('getTags', true, { ids }))
    .pipe(
      map(response => response.data?.data),
      catchError(err => {
        console.log('Erro ao buscar tags:', err)
        return of([]);
      })
    );
}
export const getLanguagePackage = async () => {
  const result = sendRequest('getLanguagePackage', true, {})
    .then(response => {
      if (response.status === 200) {
        if (response.data.error) {
          throw new Error(response.data.error);
        }

        // Categorias retornadas com sucesso
        return response.data;
      }
    })
    .catch(err => {
      console.log('Erro ao realizar requisição getCategories:', err);
      return null;
    });
  return result;
}
export const getTerms = async (body: any) => {

  const req = axios.create({
    baseURL: process.env.REACT_APP_SYDLE_ONE_API,
    headers: {
      "Authorization": `Bearer ${process.env.REACT_APP_SYDLE_ONE_SITE_TOKEN}`,
      "Content-Type": "application/json",
    }
  });

  const result = await req.post('processBox/com.sydle.servicedesk/portalApi/getItem', body)
    .then(response => {
      if (response.status === 200) {
        if (response.data.error) {
          throw new Error(response.data.error);
        }

        // Categorias retornadas com sucesso
        return response.data;
      }
    })
    .catch(err => {
      console.log('Erro ao realizar requisição getCategories:', err);
      return null;
    });
  return result;
}
export async function getPortal() {
  return sendRequest('getPortal', true, {})
    .then(response => {
      if (response.status === 200) {
        return response.data.portal;
      }
    })
    .catch(err => {
      console.log('Erro ao realizar requisição getPortal:', err);
      return null;
    });
}

export const getProcess = async (pId) => {
  return sendRequest('getProcess', true, { _id: pId })
    .then(response => response.status === 200 ? response.data : null)
    .catch(err => {
      console.log('Erro ao realizar requisição getProcess: ', err);
      return null;
    });
}
