import React from 'react';
import Routes from './routes';
import AuthProvider from './auth/Auth';
import I18nextProvider from './services/i18n';
import SimpleReactLightbox from 'simple-react-lightbox';
import './services/i18n'
import './global.scss';
import "animate.css";

function App() {
  return (
    <I18nextProvider>
      <AuthProvider>
        <SimpleReactLightbox>
          <Routes />
        </SimpleReactLightbox>
      </AuthProvider>
    </I18nextProvider>
  );
}

export default App;
