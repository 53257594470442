import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import utils from "../../utils";
import ProcessesGrid from "../processesGrid/processesGrid";
import ProcessViewLoader from "./processViewLoader";
import { getAllProcess } from "../../services/sendRequest";
import { useTranslation } from "react-i18next";

const ProcessView = (props) => {
  const [optionFilter, setOptionFilter] = useState("Mais downloads");
  const [processesObj, setProcessesObj] = useState<any>(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(9);
  const [t, i18n] = useTranslation();

  const { name, categoryId, processId } = props;

  useEffect(() => {
    if (props.pageSize) {
      setPageSize(props.pageSize);
    }
  }, [props.pageSize]);

  useEffect(() => {
    let isMounted = true;
    const f = async () => {
      let filters: any = {
        query: {
          bool: {
            must: [
              { term: { "category.enabled": true } },
              { term: { isPublished: true } },
              { term: { "language.code": i18n.language.substr(0, 2) || "en" } },
            ],
          },
        },
      };

      let params = {
        pageIndex: pageIndex,
        pageSize: pageSize,
      };
      // Se existe categoryId, está na página de processos, e portanto busca processos relacionados
      if (categoryId) {
        setOptionFilter("Relacionados");

        let query = {
          bool: {
            must: [
              { term: { "category._id": categoryId } },
              { term: { "category.enabled": true } },
              { term: { isPublished: true } },
              { term: { "language.code": i18n.language.substr(0, 2) || "en" } },
            ],
            must_not: [
              {
                term: {
                  _id: processId,
                },
              },
            ],
          },
        };
        filters.query = query;
        params.pageSize = 3; // Sempre mostrará apenas três processos relacionados
      }

      let sortParams = utils.getOptionFilterField(optionFilter);
      if (sortParams.optionField !== "") {
        filters.sort = {
          [sortParams.optionField]: { order: sortParams.optionSort },
        };
      }

      const processesObject = await getProcesses(filters, params);
      if (isMounted) {
        setProcessesObj(processesObject);
      }
    };
    f();
    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionFilter, processId, i18n.language]);

  async function getProcesses(filters, params) {
    try {
      const res = await getAllProcess(filters, params);
      const processObject = res.result;
      return processObject;
    } catch (error) {
      console.log(error);
    }
  }

  async function loadMoreProcess(sorting) {
    const sortParams = utils.getOptionFilterField(optionFilter);
    // Define parâmetros para requisição supondo que irá apenas carregar mais posts
    let tempPageIndex = pageIndex + 1;
    let filters = {
      query: {
        bool: {
          must: [
            { term: { "category.enabled": true } },
            { term: { isPublished: true } },
            { term: { "language.code": i18n.language.substr(0, 2) || "en" } },
          ],
        },
      },
      sort: {
        [sortParams.optionField]: { order: sortParams.optionSort },
      },
    };

    let params = {
      pageIndex: tempPageIndex,
      pageSize: pageSize,
    };

    // Caso seja pra ordenar, nova requisição retorna a mesma quantidade de
    // processos que estavam sendo exibidos anteriormente, a partir do pageSize 0
    if (sorting) {
      params.pageIndex = 0;
      params.pageSize = (pageIndex + 1) * pageSize;
      setProcessesObj(null);
    }

    let currentProcessObj = processesObj;
    // Realiza requisição dos processos
    // setIsLoading(true);
    let newProcesses = await getProcesses(filters, params);
    if (sorting) {
      // Substitui processos antigos pelos novos
      setProcessesObj(newProcesses);
      setPageIndex(pageIndex);
      // setIsLoading(false);
    } else {
      // Ver mais, adiciona novos processos aos já existentes

      newProcesses.processes.forEach((process) => {
        currentProcessObj?.processes.push(process);
      });
      setProcessesObj(currentProcessObj);
      setPageIndex(tempPageIndex);
      // setIsLoading(false);
    }
  }

  function setFilterOption(option, callback) {
    setOptionFilter(option);
    callback();
  }

  const optionsArr = [
    {
      value: "Mais recentes",
      key: t("OPTION_FILTER_KEY_MOST_RECENT"),
    },
    {
      value: "Mais vistos",
      key: t("OPTION_FILTER_KEY_MOST_VIEWED"),
    },
    {
      value: "Melhores avaliações",
      key: t("OPTION_FILTER_KEY_BEST_RATED"),
    },
    {
      value: "Mais downloads",
      key: t("OPTION_FILTER_KEY_MORE_DOWNLOADS"),
    },
  ];

  let handleChange = (event) => {
    let fieldVal = event.target.value;
    setFilterOption(fieldVal, () => loadMoreProcess(true));
  };

  if (name !== "Processos relacionados") {
    return (
      <div id="process-view">
        <Container className="process-view">
          {processesObj ? (
            processesObj.total !== 0 ? (
              <>
                <Row className="process-view-header-container">
                  <Col xs={12} sm={8} className="most-recents-text mb-2">
                    {t(utils.getOptionFilterTitleTag(optionFilter))}
                  </Col>

                  <Col xs={12} sm={4}>
                    <Form>
                      <Form.Control
                        as="select"
                        size="sm"
                        custom
                        className="form-control-main-drop"
                        onChange={handleChange}
                        value={optionFilter}
                      >
                        {optionsArr.map((option) => {
                          return (
                            <option key={option.key} value={option.value}>
                              {option.key}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form>
                  </Col>
                </Row>

                <ProcessesGrid
                  processesObj={processesObj}
                  pageSize={pageSize}
                  message="HOME_NO_PROCESS"
                />

                <div className="process-card-view-see-more">
                  {processesObj.processes?.length !== processesObj.total &&
                  !categoryId ? (
                    <span
                      className="link-see-more clickable"
                      onClick={() => loadMoreProcess(false)}
                    >
                      {t("LOAD_MORE")}
                    </span>
                  ) : null}
                </div>
              </>
            ) : (
              t("HOME_NO_PROCESS")
            )
          ) : categoryId ? (
            <ProcessViewLoader
              pageSize={3}
              showTitle={true}
              showFilter={false}
              showSeeMore={false}
            />
          ) : (
            <ProcessViewLoader
              pageSize={pageSize}
              showTitle={true}
              showFilter={true}
              showSeeMore={true}
            />
          )}
        </Container>
      </div>
    );
  } else {
    return (
      <div id="process-view">
        <div className="process-view">
          {processesObj ? (
            processesObj.total !== 0 ? (
              <>
                <div className="related-text mb-2">
                  {t(utils.getOptionFilterTitleTag(optionFilter))}
                </div>

                <ProcessesGrid
                  processesObj={processesObj}
                  pageSize={9}
                  message="HOME_NO_PROCESS"
                />

                <div className="process-card-view-see-more">
                  {processesObj.processes?.length !== processesObj.total &&
                  !categoryId ? (
                    <span
                      className="link-see-more clickable"
                      onClick={() => loadMoreProcess(false)}
                    >
                      {t("LOAD_MORE")}
                    </span>
                  ) : null}
                </div>
              </>
            ) : null
          ) : categoryId ? (
            <ProcessViewLoader
              pageSize={3}
              showTitle={true}
              showFilter={false}
              showSeeMore={false}
            />
          ) : (
            <ProcessViewLoader
              pageSize={9}
              showTitle={true}
              showFilter={true}
              showSeeMore={true}
            />
          )}
        </div>
      </div>
    );
  }
};

export default ProcessView;
