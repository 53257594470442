import React, { useEffect, useState } from "react";
import Tags from "../../components/tags/tags";
import Tag from "../../models/tag";
import { map } from "rxjs/operators";
import hash from "object-hash";
import { getTags } from "../../services/sendRequest";
import { useTranslation } from "react-i18next";

export function convertTags2Tagify(tags: Tag[]) {
  return tags.map((tag) => {
    tag.value = tag.name;
    return tag;
  });
}

const InputTags = (props) => {
  const { value, onChange, className, invalidCallback } = props;
  const [t, i18n] = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [whitelist, setWhitelist] = useState<any[]>([]);

  const tagifySettings = {
    blacklist: [],
    maxTags: 10,
    backspace: true,
    placeholder: t("TAGS_PLACEHOLDER"),
    classname: "form-fields form-field-name form-control form-control-sm",
    dropdown: {
      enabled: 0,
      maxItems: 5,
      closeOnSelect: true,
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const callback = function (e) {
    console.log(`%c ${e.type}: `, "background: #222; color: #bada55", e.detail);
  };

  let mapTagifyCallbacks = {
    // add: callback,
    // remove: callback,
    // input: callback,
    // edit: callback,
    invalid: invalidCallback,
    // click: callback
  };

  const [settings, setSettings] = useState<any>({
    ...tagifySettings,
    callbacks: mapTagifyCallbacks,
    whitelist: whitelist || [],
    validate: (tag) => {
      if (whitelist.length) {
        let temp = whitelist.find((wTag) => wTag.value === tag.value);
        return temp;
      }
      return true;
    },
  });

  useEffect(() => {
    let s = getTags()
      .pipe(map(convertTags2Tagify))
      .subscribe((tags) => setWhitelist(tags));

    return () => s.unsubscribe();
  }, [i18n.language]);

  useEffect(() => {
    setSettings({
      ...tagifySettings,
      callbacks: mapTagifyCallbacks,
      whitelist: whitelist || [],
      validate: (tag) => {
        if (whitelist.length) {
          return !!whitelist.find((wTag) => wTag.value === tag.value);
        }
        return true;
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whitelist]);

  return (
    <Tags
      key={hash(settings)}
      settings={settings}
      value={value}
      onChange={onChange}
      className={className}
    />
  );
};

export default React.memo(InputTags);
