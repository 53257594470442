/* eslint-disable jsx-a11y/alt-text */
import React from "react";

// Por hora só funciona com aspect ratio >= 1

const ImageResponsive = (props: {
    aspect: number,
    src: string,
    alt?: string,
    [key: string]: any
}) => {

    const { aspect, ...imageProps } = props;

    const containerStyle = {
        position: 'relative',
        width: '100%',
        paddingTop: `calc(100% * (1/${aspect || 1}))`
    } as React.CSSProperties;

    const imgStyle = {
        position: 'absolute',
        top: '0',    
        width: '100%',
        height: '100%',
    } as React.CSSProperties;

    return <div style={containerStyle}>
        <img {...imageProps} style={imgStyle}/>
    </div>;
};

export default ImageResponsive;