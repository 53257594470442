import moment from "moment";
import React, { useEffect } from "react";
import { Button, Card, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import { registerView } from "../../services/sendRequest";
import utils from "../../utils";
import DownloadProcessButton from "../downloadProcessButton/downloadProcessButton";

const ProcessCard = (props) => {
  //Props
  const processId = props.processId;
  const author = props.author;
  const categoryName = props.categoryName;
  const rating = props.rating;
  const lastUpdate = props.lastUpdate;
  const ratingPercentage = props.ratingPercentage;
  const hasDownload = props.hasDownload;
  const { user, isAuthenticated } = useAuth();
  const [t] = useTranslation();

  useEffect(() => {
    // Pega todos os query params da url
    const url = new URL(window.location.href);
    var queryParams = new URLSearchParams(url.search);

    // Remove o parâmetro de accessToken e downloadProcess vindo do linkedin
    let uri = window.location.toString();
    if (uri.indexOf("?") > 0) {
      let clean_uri = uri.substring(0, uri.indexOf("?"));
      window.history.replaceState({}, document.title, clean_uri);
    }

    let queryParameters: any[] = []

    //Iterate the search parameters.
    for (let p of queryParams) {
      queryParameters.push({ key: p[0], value: p[1] });
    }

    let GEO_LOCATION = [0, 0];

    if (processId) {
      (function (processId, user, url, queryParameters) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function onSuccess(position) {
            GEO_LOCATION = [position.coords.longitude, position.coords.latitude];
            let siteNavigator = { coords: { lon: GEO_LOCATION[0], lat: GEO_LOCATION[1] }, userAgent: navigator.userAgent }
            registerView(processId, user, siteNavigator, url, queryParameters);
          }, function onError() {
            let siteNavigator = { userAgent: navigator.userAgent }
            registerView(processId, user, siteNavigator, url, queryParameters);
          }, {
            timeout: 10000,
            enableHighAccuracy: false
          });
        }
      })(processId, user, url.origin + url.pathname, JSON.stringify(queryParameters));
    }
  }, [processId, user]);

  function getProcessStars(maxStars) {
    let stars: JSX.Element[] = [];
    for (let i = 0; i < maxStars; i++) {
      stars.push(
        <img
          className="stars-image"
          src={require("../../assets/custom/svg/processCardGreyStar.svg")}
          alt="Star"
          key={i}
        />
      );
    }
    return stars;
  }

  return (
    <div id="process-card">
      <Card className="process-card-description">
        {/* Autor */}
        <div className="process-card-author-container p-3">
          <div className="process-card-author-itens d-flex flex-row align-items-center">

            <div className="avatar-box">
              {/* Se autor não possui imagem, adiciona imagem padrão */}
              {author.photo ? (
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip id="tooltip-user-photo">{author.name}</Tooltip>}
                >
                  <Image roundedCircle className="circular-avatar-card clickable"
                    src={utils.getPersonImageUrl(author._id)}
                    alt={author.name}
                  />
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip id="tooltip-user-photo">{t('LITERAL_USER')}</Tooltip>}
                >
                  <div className="circular-avatar-card avatar clickable">
                    <svg height="20" id="Light" width="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <defs/>
                      <title>single-neutral</title>
                      <circle cx="12" cy="6.75" fill="none" r="5.5" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M3,22.75a9,9,0,0,1,18,0Z" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </div>
                </OverlayTrigger>
              )}
            </div>

            <div className="process-card-author-info ml-2">
              <p className="author"><a className="align-middle" href={`/user-${author._id}`}>{author.name}</a></p>
              <p className="jobTitle">{author.profession}</p>
            </div>

          </div>
        </div>

        <hr />

        {/* Avaliação */}
        <div className="process-card-review-container p-3">
          <div className="review">
            <span className="review-main-text">{t('PROCESS_CARD_RATING')}</span>
            <div className="process-card-review-ratings">
              <div className="d-flex score-box">
                <div className="score-totalscore flex-fill">
                  <h1 className="score">{rating || '-'}</h1>
                  <p className="totalscore">de 5</p>
                </div>

                <div className="stars-and-bars align-self-end">
                  <div className="star-bar5">
                    <div className="stars-container">
                      {ratingPercentage ? getProcessStars(5) : null}
                    </div>
                    <svg
                      className="svg-rating-bar"
                      width="133px"
                      height="3"
                      viewBox="0 0 133 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0)">
                        <rect
                          x="0.5"
                          width="133px"
                          height="3px"
                          rx="1.5"
                          fill="#DCE1E6"
                        />
                        {ratingPercentage ? (
                          <rect
                            x="0.5"
                            width={ratingPercentage[4] * 133 + "px"}
                            height="3px"
                            fill="#8997A2"
                          />
                        ) : (
                          <rect
                            x="0.5"
                            width={0 + "px"}
                            height="3px"
                            fill="#8997A2"
                          />
                        )}
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect
                            x="0.5"
                            width="133px"
                            height="3px"
                            rx="1.5"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="star-bar5">
                    <div className="stars-container">
                      {ratingPercentage ? getProcessStars(4) : null}
                    </div>
                    <svg
                      className="svg-rating-bar"
                      width="134px"
                      height="3px"
                      viewBox="0 0 134 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0)">
                        <rect
                          x="0.5"
                          width="133px"
                          height="3px"
                          rx="1.5"
                          fill="#DCE1E6"
                        />
                        {ratingPercentage ? (
                          <rect
                            x="0.5"
                            width={ratingPercentage[3] * 133 + "px"}
                            height="3px"
                            fill="#8997A2"
                          />
                        ) : (
                          <rect
                            x="0.5"
                            width={0 + "px"}
                            height="3px"
                            fill="#8997A2"
                          />
                        )}
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect
                            x="0.5"
                            width="133px"
                            height="3px"
                            rx="1.5"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="star-bar5">
                    <div className="stars-container">
                      {ratingPercentage ? getProcessStars(3) : null}
                    </div>
                    <svg
                      className="svg-rating-bar"
                      width="134px"
                      height="3px"
                      viewBox="0 0 134 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0)">
                        <rect
                          x="0.5"
                          width="133px"
                          height="3px"
                          rx="1.5"
                          fill="#DCE1E6"
                        />
                        {ratingPercentage ? (
                          <rect
                            x="0.5"
                            width={ratingPercentage[2] * 133 + "px"}
                            height="3px"
                            fill="#8997A2"
                          />
                        ) : (
                          <rect
                            x="0.5"
                            width={0 + "px"}
                            height="3px"
                            fill="#8997A2"
                          />
                        )}
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect
                            x="0.5"
                            width="133px"
                            height="3px"
                            rx="1.5"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="star-bar5">
                    <div className="stars-container">
                      {ratingPercentage ? getProcessStars(2) : null}
                    </div>
                    <svg
                      className="svg-rating-bar"
                      width="134px"
                      height="3px"
                      viewBox="0 0 134 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0)">
                        <rect
                          x="0.5"
                          width="133px"
                          height="3px"
                          rx="1.5"
                          fill="#DCE1E6"
                        />
                        {ratingPercentage ? (
                          <rect
                            x="0.5"
                            width={ratingPercentage[1] * 133 + "px"}
                            height="3px"
                            fill="#8997A2"
                          />
                        ) : (
                          <rect
                            x="0.5"
                            width={0 + "px"}
                            height="3px"
                            fill="#8997A2"
                          />
                        )}
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect
                            x="0.5"
                            width="133px"
                            height="3px"
                            rx="1.5"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="star-bar5">
                    <div className="stars-container">
                      {ratingPercentage ? getProcessStars(1) : null}
                    </div>
                    <svg
                      className="svg-rating-bar"
                      width="134px"
                      height="3px"
                      viewBox="0 0 134 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0)">
                        <rect
                          x="0.5"
                          width="133px"
                          height="3px"
                          rx="1.5"
                          fill="#DCE1E6"
                        />
                        {ratingPercentage ? (
                          <rect
                            x="0.5"
                            width={ratingPercentage[0] * 133 + "px"}
                            height="3px"
                            fill="#8997A2"
                          />
                        ) : (
                          <rect
                            x="0.5"
                            width={0 + "px"}
                            height="3px"
                            fill="#8997A2"
                          />
                        )}
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect
                            x="0.5"
                            width="133px"
                            height="3px"
                            rx="1.5"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Última atualização */}
          <div className="info-box mb-2">
            <p className="review-main-text">{t('PROCESS_CARD_LAST_UPDATE')}</p>
            <div className="simple-text">
              <p className="review-text">
                {moment(new Date(lastUpdate)).format("DD/MM/YYYY")}
              </p>
            </div>
          </div>
          {/* Categoria */}
          <div className="info-box">
            <p className="review-main-text">{t('PROCESS_CARD_CATEGORY')}</p>
            <div className="simple-text">
              <p className="review-text">{categoryName}</p>
            </div>
          </div>

          {isAuthenticated && user?.code === author._id ?
            <Link to={`/edit/${processId}`}>
              <Button
                block
                variant="primary"
                size="sm"
                className="edit-button mb-2"
              >
                <span className="mr-2">{t('PROCESS_CARD_EDIT')}</span>
                <svg className="d-inline align-middle" height="14" width="14" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <defs/>
                  <title>pencil-1</title>
                  <path d="M6.966,21.983.5,23.5l1.517-6.465L17.964,1.086a2,2,0,0,1,2.829,0l2.122,2.121a2,2,0,0,1,0,2.829Z" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round"/>
                  <line fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" x1="22.21" x2="17.257" y1="6.745" y2="1.793"/>
                  <line fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" x1="20.783" x2="15.842" y1="8.172" y2="3.207"/>
                  <line fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" x1="7.218" x2="2.268" y1="21.733" y2="16.783"/>
                </svg>
              </Button>
            </Link>
            : null}
          {hasDownload ?
            <DownloadProcessButton
              name="download"
              processId={processId}
              format="bpmn"
            />
            : null
          }
          {hasDownload ?
            <DownloadProcessButton
              name="download"
              processId={processId}
              format="xpdl"
            />
            : null
          }

        </div>
      </Card>
    </div>
  );
}

export default ProcessCard;
