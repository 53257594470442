import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const ProcessViewLoader = (props) => {

  const {
    pageSize,
    showFilter,
    showTitle,
    showSeeMore
  } = props;

  // const [pageSize, setPageSize] = useState(0);
  // const [showFilter, setShowFilter] = useState();
  // const [showTitle, setShowTitle] = useState();
  // const [showSeeMore, setShowSeeMore] = useState();

  // useEffect(() => {
  //   setPageSize(props.pageSize)
  //   setShowFilter(props.showFilter)
  //   setShowTitle(props.showTitle)
  //   setShowSeeMore(props.showSeeMore)
  // }, [props.pageSize, props.showFilter, props.showSeeMore, props.showTitle])

  let cards: JSX.Element[] = [];

  for (let i = 0; i < pageSize; i++) {
    cards.push(
      <Col key={Math.random().toString(36).substring(5)} xs={12} sm={12} md={6} lg={4} className="p-5">
        <Card className="process-card-loader m-auto">
          <div className="process-view-card-image animated-background"></div>
          <Card.Body className="card-footer-proc">
            <div className="process-view-card-body">
              <div className="proc-text animated-background cardTitle"></div>
              <div className="pac-text animated-background cardCategory"></div>
            </div>
            {/* TODO: Divider */}
            <div></div>
            <div className="loader-footer-container d-flex flex-row">
              <div className="assessment">
                <div className="animated-background cardStars"></div>
              </div>
              <div className="assessment">
                <div className="animated-background cardViews"></div>
              </div>
              <div className="assessment">
                <div className="animated-background cardViews"></div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    )
  }

  return (
    <div id="process-view">
      <div id="processViewLoader">
        <div>
          <Row
            className="process-view-header-container align-content-end"
          >
            {showTitle ? (
              <>
                <Col
                  xs={12}
                  sm={7}
                  className="header-processes animated-background title"
                ></Col>
              </>
            ) : null}
            {/* Está na home, insere filtro */}
            {showFilter ? (
              <Col className="main-drop-loader-container" xs={12} sm={5} >
                <div className="main-drop-loader animated-background"></div>
              </Col>
            ) : (
                <></>
              )}
          </Row>
        </div>
        <Row
          className="process-view-cards-container m-n5"
        >
          {cards}
        </Row>
        {showSeeMore ? (
          <div className="process-card-view-see-more">
            <span className="link-see-more clickable animated-background loadMore"></span>
          </div>) : null}
      </div>
    </div>
  );
}

export default ProcessViewLoader;
