import React, { useEffect, useState } from "react";
import { MarkdownParser } from "../../components/markdown-editor/markdown-editor";
import { getTags } from '../../services/sendRequest';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
export interface IContent {
  title: string;
  description: string;
}

const Description = (props) => {

  const {
    content,
    roles,
    tags,
    linkedProcesses
  } = props;

  const [refContent, setRefContent] = useState<HTMLDivElement | null>(null);
  const [t, i18n] = useTranslation();

  /* useEffect(() => {
    setContent(props.content);
    setRoles(props.roles);
  }, [props.content, props.contents, props.roles]); */

  const [tagsConcret, setTagsConcret] = useState<any>([]);

  useEffect(() => {
    if (content && refContent) {
      refContent.innerHTML = MarkdownParser(content);
    }
  }, [content, refContent]);

  useEffect(() => {
    getTags((tags || []).map(tag => tag._id))
      .subscribe(setTagsConcret);
  }, [i18n.language, tags]);

  return (
    <div id="description">
      <div className="description-background">
        <div className="description-body">

          <div ref={setRefContent}></div>

          <div className="text-f my-2">{t('PROCESS_ROLES_ INVOLVED')}</div>
          <div className="">
            {(roles || []).map((role) => {
              return (
                <span className="d-inline-block mr-1 mb-2" key={role}>
                  <button disabled className="btn btn-xs btn-soft-secondary btn-disabled">{role}</button>
                </span>
              );
            })}
          </div>

          <div className="text-f my-2">Tags</div>
          <div className="">
            {tagsConcret.map((tag, index) => {
              return (
                <a className="d-inline-block mr-1 mb-2" key={tag._id} href={`/t/${tag.identifier}-${tag._id}`}>
                  <span className="btn btn-xs btn-soft-secondary">{tag.name}</span>
                </a>
              );
            })}
          </div>
          <div className="text-f my-2">{t('PROCESS_LINKED_PROCESSES')}</div>
          <div className="">
            {linkedProcesses?.map((process, index) =>
              <span key={process._id}>
                <Link className="d-inline-block ml-1 mb-2" to={`/p/${process.identifier}-${process._id}`}>
                  <span className="referred-process-link">{process.description}</span>
                </Link>{index < linkedProcesses.length -1 ? "," : ""}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
