import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next/";
import { from, fromEvent, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import DynamicHead from "../../components/dynamicHead/dynamicHead";
import Footer from "../../components/footer/footer";
import MoreInformation from "../../components/moreInformation/moreInformation";
import NavBar from "../../components/navBar/navBar";
import ProcessesGrid from "../../components/processesGrid/processesGrid";
import utils from "../../utils";
import { useHistory, useLocation } from 'react-router-dom';
import { getAllProcess, getCategories, getSiteConfig } from '../../services/sendRequest';

const Search = (props) => {
    const pageSize = 9;
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [processesObj, setProcessesObj] = useState<any>(null);
    const [oldProcessesObj, setOldProcessesObj] = useState<any>(null);
    const [categories, setCategories] = useState<any[] | null>(null);
    const [filterRoles] = useState<string[] | null>(null);
    const [filterQuery, setFilterQuery] = useState('');
    const [categoryRef, setCategoryRef] = useState<HTMLSelectElement | null>(null);
    const [filterCategory, setFilterCategory] = useState<string>('');
    const [sortRef, setSortRef] = useState<HTMLSelectElement | null>(null);
    const [filterSort, setFilterSort] = useState("Mais recentes");
    const [title, setTitle] = useState('');
    const [alternativeUrls, setAlternativeUrls] = useState<Array<any>>([]);


    const location = useLocation();
    const [t, i18n] = useTranslation();
    const history = useHistory();

    function loadProcesses() {
        let sortParams = utils.getOptionFilterField(filterSort);
        let filters: any = {
            sort: {
                [sortParams.optionField]: { order: sortParams.optionSort },
            },
            query: {
                bool: {
                    must: [
                        { term: { "category.enabled": true } },
                        { term: { "isPublished": true } },
                        { term: { "language.code": i18n.language.substr(0, 2) || "en" } }
                    ]
                }
            }
        };

        if (filterQuery) {
            filters.query.bool.must.push({
                multi_match: {
                    "query": filterQuery,
                    "fields": ["description", "informations", "tag", "roles", "author"]
                }
            });
        }

        if (filterCategory) {
            filters.query.bool.must.push({
                term: {
                    "category._id": filterCategory
                }
            });
        }

        let params = {
            pageIndex: pageIndex,
            pageSize: pageSize,
        };

        from(getAllProcess(filters, params))
            .pipe(
                map(response => (response.result || {}))
            )
            .subscribe(processesObj => {
                if (oldProcessesObj) {
                    processesObj.processes = oldProcessesObj.processes.concat(processesObj.processes);
                }
                setProcessesObj(processesObj);
            });
    }

    function seeMore() {
        setOldProcessesObj(processesObj);;
        setPageIndex(pageIndex + 1);
    }

    // Carrega os processos de acordo com os dados dos filtros
    useEffect(() => {
        if (filterQuery || filterCategory || filterRoles) {
            loadProcesses();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex, filterQuery, filterCategory, filterRoles, filterSort]);

    // Carrega os parâmetros da url, termo e categoria
    useEffect(() => {
        var queryParams = new URLSearchParams(location.search);

        let q = '', sc = '';
        if (queryParams.has('q')) {
            q = queryParams.get('q') as string;
            setFilterQuery(q);
        }

        if (queryParams.has('sc')) {
            sc = queryParams.get('sc') as string;
            setFilterCategory(sc);
        }

        if (q || sc) {
            setOldProcessesObj(null);
        }

        from(getCategories())
            .pipe(map(categories => categories.filter(category => category.enabled)))
            .subscribe(categories => setCategories(categories));


    }, [i18n.language, location.search]);

    useEffect(() => {

        const url = new URL(window.location.href);
        var queryParams = new URLSearchParams(url.search);

        let q = '';
        if (queryParams.has('q')) {
            q = queryParams.get('q') as string;
        }

        async function fetchData() {
            let siteConfig = await getSiteConfig({
                string: q,
                identifier: 'search'
            });

            if (siteConfig) {
                setTitle(siteConfig.title)
            }
            setAlternativeUrls(await utils.setAlternativeUrls(i18n.language));
        }
        fetchData()
    }, [filterQuery, i18n.language])

    useEffect(() => {
        if (categoryRef) {
            fromEvent<Event>(categoryRef, 'change')
            .pipe(
                switchMap((e: Event) => of(categoryRef)),
                map((el: HTMLSelectElement) => el.value)
                )
                .subscribe(value => {
                    let url = new URL(window.location.href);
                    let queryParams = new URLSearchParams(url.search);
                    queryParams.set('sc', value);
                    url.search = '?' + queryParams.toString();
                    history.push({pathname: url.pathname, search: url.search });
                });
        }
    }, [categoryRef, history]);

    useEffect(() => {

        if (sortRef) {
            fromEvent<Event>(sortRef, 'change')
                .pipe(
                    switchMap((e: Event) => of(sortRef)),
                    map((el: HTMLSelectElement) => el.value)
                )
                .subscribe(value => {
                    if (value.length < 1) return;
                    setOldProcessesObj(null);
                    setPageIndex(0);
                    setFilterSort(value);
                });
        }
    }, [sortRef]);

    const optionsArr = [
        {
            value: "Mais recentes",
            key: t('OPTION_FILTER_KEY_MOST_RECENT')
        },
        {
            value: "Mais vistos",
            key: t('OPTION_FILTER_KEY_MOST_VIEWED')
        },
        {
            value: "Melhores avaliações",
            key: t('OPTION_FILTER_KEY_BEST_RATED')
        },
        {
            value: "Mais downloads",
            key: t('OPTION_FILTER_KEY_MORE_DOWNLOADS')
        }
    ];

    const filterBar = (
        <Col xs={12} sm={4}>
            <Form.Group>
                <Form.Control
                    as="select"
                    size="sm"
                    custom className="form-control-main-drop"
                    value={filterCategory}
                    ref={el => setCategoryRef(el)}
                    onChange={() => { }}
                >
                    <option value="">{t('CATEGORY_FILTER_ALL')}</option>
                    {categories?.map((category, index) => category.enabled ? (
                        <option
                            id={category._id}
                            key={category._id}
                            value={category._id}
                            data-name={category.name}
                        >
                            {category.name}
                        </option>) : null
                    )}
                </Form.Control>
            </Form.Group>
        </Col>
    );

    const sortBar = (
        <Col xs={12} sm={4}>
            <Form.Group>
                <Form.Control
                    as="select"
                    size="sm"
                    custom className="form-control-main-drop"
                    ref={el => setSortRef(el)}
                    value={filterSort}
                    onChange={() => { }}
                >
                    {optionsArr.map((option) => {
                        return (
                            <option
                                key={option.key}
                                value={option.value}
                            >
                                {t(utils.getOptionFilterTitleTag(option.value))}
                            </option>
                        );
                    })}
                </Form.Control>
            </Form.Group>
        </Col>
    );

    return (
        <div id="search">
            <DynamicHead
                title={title}
                description=""
                url={window.location.origin + window.location.pathname}
                imgUrl=""
                alternativeUrlsData={alternativeUrls}
            />

            <NavBar type={"Process"} searchURL="/search" hasSearchBox alternativeUrlsData={alternativeUrls} />

            <Container>
                <Row className="process-view-header-container">
                    {sortBar}
                    {categories ? filterBar : null}
                </Row>

                <ProcessesGrid processesObj={processesObj} message="PROCESS_GRID_MGS_NO_PROCESS" />

                <Row className="mt-5">
                    <Col>
                        {processesObj?.processes.length !== processesObj?.total && (pageIndex + 1) * pageSize < processesObj?.total ?
                            <span
                                className="link-see-more clickable"
                                onClick={seeMore}
                            >
                                {t('LOAD_MORE')}
                            </span>
                            : null}
                    </Col>
                </Row>

                <MoreInformation />
            </Container>

            <Footer />
        </div>
    );
}

export default Search;