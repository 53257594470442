import React from "react";
import { Card, Col, Image, Row, Container } from "react-bootstrap";
const ProcessCardLoader = () => {

  return (
    <div id="process-card">
      <Card id="processCardLoader" className="process-card-description animated">
        {/* Autor */}
        <Container className="process-card-author-container">
          <Row className="process-card-author-itens">
            <Col xs={3}>
              {/* Se autor não possui imagem, adiciona imagem padrão */}
                <Image roundedCircle className="circular-avatar animated-background"/>
            </Col>
            <Col xs={8} className="process-card-author-info-loader">
              <div className=" animated-background authorName"></div>
              <div className="animated-background job"></div>
            </Col>

            <Col xs={1} className="animated-background moreInfo"></Col>
          </Row>
        </Container>
        {/* Avaliação */}
        <div className="process-card-review-container">
          <div className="review">
            <span className="review-main-text animated-background reviewText"></span>
            <div className="process-card-review-ratings">
              <Row className="score-box">
                <Col className="score-totalscore animated-background"></Col>
                <Col className="stars-and-bars">
                  <div className="star-bar5 animated-background starsBarsLoader"></div>
                  <div className="star-bar5 animated-background starsBarsLoader"></div>
                  <div className="star-bar5 animated-background starsBarsLoader"></div>
                  <div className="star-bar5 animated-background starsBarsLoader"></div>
                  <div className="star-bar5 animated-background starsBarsLoader"></div>
                </Col>
              </Row>
            </div>
          </div>
          {/* Última atualização */}
          <div className="info-box">
            <div className="review-text animated-background lastUpdate"></div>
              <div className="simple-text">
              <div className="animated-background innerText"> </div>
            </div>
          </div>
          {/* Categoria */}
          <div className="info-box">
            <div className="review-text animated-background category"></div>
            <div className="simple-text">
              <div className="animated-background innerText"></div>
            </div>
          </div>
          {/* Download */}
          <div className="button-container">
            <div className="download-button animated-background" >
              <div className="download-button-container">
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default ProcessCardLoader;
