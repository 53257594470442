import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const RemoveProcessModal = (props) => {
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onConfirm = () => {
        if(props.onConfirm) {
            props.onConfirm();
        }
        else {
            throw new Error('Função de ação de confirmação não foi definida.')
        }
    }

    const renderChildren = () => {
        if(props.render)
        {
            // Função que recebe como parametro um objeto com métodos e atributos e retorna um JSX.
            return props.render({
                handleShow: handleShow
            });
        }

        return <></>;
    }

    return (
        <>
            {renderChildren()}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar remoção do processo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Esta ação não pode ser desfeita.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={onConfirm}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default RemoveProcessModal;