import { createContext } from "react";
import { IAuthContext } from "./IAuthContext";
import { initialAuthState } from "./IAuthState";

const stub = (): never => {
    throw new Error('You forgot to wrap your component in <AuthProvider>.');
};

const initAuthContext: IAuthContext = {
    ...initialAuthState,
    login: stub,
    logout: stub,
};

const AuthContext = createContext<IAuthContext>(initAuthContext);

export default AuthContext;