export type User = {
    code: string,
    [key: string]: any
} | null;

export interface IAuthState {
    error?: Error;
    isAuthenticated: boolean;
    isLoading: boolean;
    user?: User;
};

export const initialAuthState: IAuthState = {
    isAuthenticated: false,
    isLoading: typeof window !== 'undefined',
};