import facebookIcon from "@iconify/icons-jam/facebook";
import linkedinIcon from "@iconify/icons-jam/linkedin";
import twitterIcon from "@iconify/icons-jam/twitter";
import whatsappIcon from "@iconify/icons-jam/whatsapp";
import { Icon } from "@iconify/react";
import marked from "marked";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { map } from "rxjs/operators";
import DynamicHead from "../../../components/dynamicHead/dynamicHead";
import Footer from "../../../components/footer/footer";
import NavBar from "../../../components/navBar/navBar";
import utils from "../../../utils";
import RelatedPosts from "../_components/related-posts/related-posts";
import { IPostConcrete } from "../_models/iPost";
import { getPost, SDConfig } from "../_services/api";
import { post2IPost } from "../_services/utils";
import { CustomPostRenderer } from "./markdownRenderer";
import {
  getSiteConfig,
  getSocialNetworkConfig,
} from "../../../services/sendRequest";
import { useTranslation } from "react-i18next";

const Icons: { [key: string]: typeof facebookIcon } = {
  facebook: facebookIcon,
  twitter: twitterIcon,
  linkedin: linkedinIcon,
  whatsapp: whatsappIcon,
};

const sharingsUris: { [key: string]: any } = {
  whatsapp: "https://api.whatsapp.com/send?text=",
  twitter: "https://twitter.com/intent/tweet?text=",
};

const ResourcesPost = (props: any) => {
  const { rId } = useParams<{ [key: string]: any }>();

  const [post, setPost] = useState<IPostConcrete | null>(null);
  const [refContent, setRefContent] = useState<HTMLDivElement | null>(null);
  const [headerTitle, setHeaderTitle] = useState("");
  const [socialNetworks, setSocialNetworks] = useState<any[]>([]);
  const [alternativeUrls, setAlternativeUrls] = useState<Array<any>>([]);
  const [t, i18n] = useTranslation();

  useEffect(() => {
    if (post) {
      getSiteConfig({
        string: post.name,
        identifier: "default",
      }).then((siteConfig) => setHeaderTitle(siteConfig.title));

      getSocialNetworkConfig({
        sdPortalId: SDConfig.portalId,
        postId: post._id,
      })
        .then((response) => setSocialNetworks(response.socialNetworks))
        .catch((err) => console.log(err));
    }
  }, [post]);

  useEffect(() => {
    if (post?.contentMarkdown && refContent) {
      refContent.innerHTML = marked(post.contentMarkdown, {
        renderer: new CustomPostRenderer(post),
      });
    }
  }, [i18n.language, post, refContent]);

  useEffect(() => {
    const postConfig = {
      _id: rId,
      portal: SDConfig.portalId,
      returnFields: [
        "_id",
        "icon",
        "identifier",
        "description",
        "name",
        "tags",
        "contentFormat",
        "contentHtml",
        "contentMarkdown",
        "relatedItems",
        "images",
        "author",
        "publishedDate",
        "lastUpdateDate",
      ],
    };
    const s = getPost(postConfig)
      .pipe(map((post) => post2IPost(post) as IPostConcrete))
      .subscribe(setPost);

    return () => s.unsubscribe();
  }, [i18n.language, rId]);

  useEffect(() => {
    utils
      .setAlternativeUrls(i18n.language, post?.identifiers)
      .then(setAlternativeUrls);
  }, [i18n.language, post]);

  if (!post) {
    return <></>;
  }

  return (
    <div className="resources-post">
      <DynamicHead
        title={headerTitle}
        description={post?.description}
        url={window.location.origin + window.location.pathname}
        imgUrl={post?.iconURL}
        alternativeUrlsData={alternativeUrls}
      />

      <NavBar type={"Process"} searchURL="/blog/search" hasSearchBox alternativeUrlsData={alternativeUrls} />

      <hr style={{ margin: "0px" }} />

      <div className="container space-2">
        <div className="w-lg-60 mx-lg-auto">
          <div className="mb-4">
            <h1 className="h2 font-weight-medium">{post.name}</h1>
          </div>

          {/* share */}
          <div className="py-3 mb-5">
            <div className="row justify-content-end align-items-md-center">
              <div className="col-md-5">
                <div className="d-flex justify-content-end align-items-center">
                  <h2 className="text-secondary font-size-1 font-weight-medium text-uppercase mb-0 mr-3">
                    {t("SHARE")}:
                  </h2>

                  {socialNetworks.map((network: any) => {
                    if (network.permissionToShare) {
                      let CustomIcon = Icons[network.identifier];

                      let contentMediaShare = encodeURIComponent(
                        network.sharingMessage + " " + window.location.href
                      );
                      let shareLink;
                      if (network.name === "Facebook") {
                        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&quote=${network.sharingMessage}`;
                      } else if (network.name === "LinkedIn") {
                        shareLink = `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${network.sharingMessage}&summary=&source=`;
                      } else {
                        shareLink = `${
                          sharingsUris[`${network.identifier}`] +
                          contentMediaShare
                        }`;
                      }
                      return (
                        <div className="share-icon" key={network.identifier}>
                          <a
                            className="btn btn-sm btn-icon btn-soft-dark btn-bg-transparent rounded-circle mr-2"
                            href={shareLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Icon className="icon" icon={CustomIcon}></Icon>
                          </a>
                        </div>
                      );
                    } else {
                      return <></>;
                    }
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="post-content" ref={setRefContent}></div>

          <div>
            {/* tags */}
            {/* <div className="mt-5">
                            <span className="d-inline-block mr-1 mb-2"><a className="btn btn-xs btn-soft-secondary" href="#fill">Ecommerce</a></span>
                            <span className="d-inline-block mr-1 mb-2"><a className="btn btn-xs btn-soft-secondary" href="#fill">Website</a></span>
                            <span className="d-inline-block mr-1 mb-2"><a className="btn btn-xs btn-soft-secondary" href="#fill">Bootstrap</a></span>
                            <span className="d-inline-block mr-1 mb-2"><a className="btn btn-xs btn-soft-secondary" href="#fill">Startup</a></span>
                            <span className="d-inline-block mr-1 mb-2"><a className="btn btn-xs btn-soft-secondary" href="#fill">Free</a></span>
                        </div> */}

            <div className="row justify-content-sm-between align-items-sm-center mt-5">
              <div className="col-sm-6">
                <div className="d-flex align-items-center">
                  <h4 className="font-size-1 font-weight-medium text-uppercase mb-0 mr-3">
                    {t("SHARE")}:
                  </h4>

                  {socialNetworks.map((network: any) => {
                    if (network.permissionToShare) {
                      let CustomIcon = Icons[network.identifier];

                      let contentMediaShare = encodeURIComponent(
                        network.sharingMessage + " " + window.location.href
                      );
                      let shareLink;
                      if (network.name === "Facebook") {
                        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&quote=${network.sharingMessage}`;
                      } else if (network.name === "LinkedIn") {
                        shareLink = `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${network.sharingMessage}&summary=&source=`;
                      } else {
                        shareLink = `${
                          sharingsUris[`${network.identifier}`] +
                          contentMediaShare
                        }`;
                      }
                      return (
                        <div className="share-icon" key={network.identifier}>
                          <a
                            className="btn btn-sm btn-icon btn-soft-dark btn-bg-transparent rounded-circle mr-2"
                            href={shareLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Icon className="icon" icon={CustomIcon}></Icon>
                          </a>
                        </div>
                      );
                    } else {
                      return <></>;
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="w-lg-75 border-top space-2 mx-lg-auto">
          <RelatedPosts relatedItems={post.relatedItems} />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ResourcesPost;
