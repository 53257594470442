import { IPost } from '../_models/iPost';
import { getImgURL } from './api';

export const post2IPost = (post: any): IPost => {
    let category = (Object.keys(post.parent || {}).length && post.parent)
    // ||
        // post.path[post.path.length - 2];
    let iconURL = post.icon ? getImgURL(post._id, post.icon._id) : '';

    post.identifier = kebabCase(post.identifier);

    return {
        ...post,
        category,
        iconURL
    }
};

export const kebabCase = (str) => {
    let c = str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
    return c
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(x => x.toLowerCase())
        .join('-');
};
