const TOKEN_RE = /[?&]accessToken=[^&]+/;
const ERROR_RE = /[?&]error=[^&]+/;

export const hasAuthParams = (searchParams = window.location.search): boolean =>
    TOKEN_RE.test(searchParams) ||
    ERROR_RE.test(searchParams);

export const isPayloadExpired = (payloadExp: number): boolean => {
    return new Date().getTime() > payloadExp * 1000;
};

export const lt2DaysToExpire = (payloadExp: number) => {
    let today = new Date().getTime();
    return (today + 2 * 24 * 3600 * 1000 > payloadExp * 1000);
};