import React from "react";
import { Card, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import utils from "../../utils";
import ProcessesGridLoader from "./processesGridLoader";
import { useTranslation } from "react-i18next/";

const ProcessesGrid = (props: any) => {

  const processesObj: {
    processes: any[];
    total: number;
  } | null = props.processesObj || null;
  const showLoader = props.showLoader || true;
  const pageSize = props.pageSize || 6;
  const message = props.message || '';

  const [t] = useTranslation();

  function getProcessStars(process: any) {
    let stars: JSX.Element[] = [];
    if (process.rating) {
      for (let i = 0; i < 5; i++) {
        if (i <= process.rating - 1) {
          stars.push(
            <img
              className="stars"
              src={require("../../assets/custom/svg/yellowStar.svg")}
              alt="Star"
              key={i}
            />
          );
        } else {
          stars.push(
            <img
              className="stars"
              src={require("../../assets/custom/svg/greyStar.svg")}
              alt="Star"
              key={i}
            />
          );
        }
      }
    } else {
      for (let i = 0; i < 5; i++) {
        stars.push(
          <img
            className="stars"
            src={require("../../assets/custom/svg/greyStar.svg")}
            alt="Star"
            key={i}
          />
        );
      }
    }
    return stars;
  }

  return (
    <>
      {processesObj ? (
        <div id="process-view" className="process-grid grid">
          {processesObj.processes?.map((process: any) => {
            return (
              <div key={process._id} className="d-flex justify-content-center">
                <a
                  className="card-link"
                  href={`/p/${process.identifier}-${process._id}`}
                >
                  <Card className="process-card m-auto">
                    {process.images?.length > 0 ?
                      <Card.Img
                        className="process-view-card-image"
                        src={utils.getProcessImageUrl(
                          process.identifier,
                          process._id
                        )}
                        alt={`${t("BPMN_PROCESS")} - ${process.description}`}
                      ></Card.Img>
                    : <Card.Img
                        className="process-view-card-no-image d-flex align-items-center justify-content-center"
                        as="div"
                      >{`${t("PROCESS_IMAGE_NOT_FOUND")}`}</Card.Img>  
                    }
                    <Card.Body className="card-footer-proc d-flex align-items-center">
                      <div className="process-view-card-body px-3">
                        <Card.Title className="proc-text">
                          <span className="mr-2">{process.description}</span>
                        </Card.Title>
                        <Card.Subtitle className="pac-text">
                          {process.category?.name}&nbsp;
                          {process.isPublished ? null :
                            <Badge variant="info">{t("DRAFT_LABEL")}</Badge>
                          }
                        </Card.Subtitle>
                      </div>
                    </Card.Body>

                    <Card.Body className="card-footer-container d-flex align-items-center">
                      <div className="card-footer-items d-flex px-3 justify-content-between w-100">
                        <div className="assessment d-inline">
                          <div className="d-inline">
                            {getProcessStars(process)}
                          </div>
                        </div>

                        <div>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id="tooltip-views">{t("VIEWS")}</Tooltip>
                            }
                          >
                            <div className="assessment d-inline">
                              <svg className="d-inline align-middle" height="14" width="14" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                  <circle cx="12" cy="12" fill="none" r="3.5" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
                                  <path d="M23.38,11.67C22.21,10.35,17.56,5.5,12,5.5S1.79,10.35.62,11.67a.52.52,0,0,0,0,.66C1.79,13.65,6.44,18.5,12,18.5s10.21-4.85,11.38-6.17A.52.52,0,0,0,23.38,11.67Z" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
                                </g>
                              </svg>
                              <div className="card-footer-proc-text d-inline">
                                {process.viewCount}
                              </div>
                            </div>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id="tooltip-downloads">
                                Downloads
                              </Tooltip>
                            }
                          >
                            <div className="assessment ml-2 d-inline">
                              <svg className="d-inline align-middle" height="14" width="14" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <defs/>
                                <title>download-bottom</title>
                                <path d="M23,18.218v1.913A2.87,2.87,0,0,1,20.131,23H3.869A2.869,2.869,0,0,1,1,20.131V18.218" fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
                                <line fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" x1="12" x2="12" y1="18.108" y2="1"/>
                                <polyline fill="none" points="19 11.108 12 18.108 5 11.108" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
                              </svg>
                              <div className="card-footer-proc-text d-inline">
                                {process.downloadCount === null
                                  ? 0
                                  : process.downloadCount}
                              </div>
                            </div>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </a>
              </div>
            );
          })}
          {processesObj.processes?.length === 0 ? (
            <p className="without-process-text py-5">{t(message)}</p>
          ) : null}
        </div>
      ) : showLoader ? (
        <ProcessesGridLoader numCards={pageSize} />
      ) : null}
    </>
  );
};

export default ProcessesGrid;
