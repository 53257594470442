import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  Image,
  OverlayTrigger,
  Popover,
  Row,
  Tooltip,
  Button
} from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { fromEvent, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { useAuth } from "../../auth/Auth";
import utils from "../../utils";
import { useTranslation } from "react-i18next";

const NavBar = (props) => {
  const { type, hasSearchBox, searchURL, alternativeUrlsData } = props;
  const [t, i18n] = useTranslation();

  /* const [type, setType] = useState(''); */
  const [language, setLanguage] = useState("");
  const [searchBoxRef, setSearchBoxRef] = useState<HTMLInputElement | null>(null);
  const [userPopoverShow, setUserPopoverShow] = useState(false);
  const [alternativeUrls, setAlternativeUrls] = useState({});
  const { user, logout } = useAuth();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    let queryParams = new URLSearchParams(location.search);

    if (searchBoxRef) {
      fromEvent<InputEvent>(searchBoxRef, "change")
        .pipe(
          switchMap(() => of(searchBoxRef)),
          map((el: HTMLInputElement) => el.value)
        )
        .subscribe((value) => {
          if (value.length < 1) return;

          queryParams.set("q", value);
          history.push(`${searchURL}?` + queryParams.toString());
        });

      if (queryParams.has("q")) {
        searchBoxRef.value = queryParams.get("q") || "";
      }
    }
  }, [history, location.search, searchBoxRef, searchURL]);

  useEffect(() => {
    setLanguage(i18n.language || "");
  }, [i18n.language]);

  useEffect(() => {
    if (alternativeUrlsData?.length) {
      let obj = {};
      alternativeUrlsData.slice(0, 3).forEach(alt => {
        obj[alt.lang] = alt.link;
      });
      setAlternativeUrls(obj);
    }
  }, [alternativeUrlsData]);

  function getSearchBox() {
    return (
      <Form.Group className="home-process-input-group input-group input-group-merge">
        <div className="input-group-prepend">
          <span className="input-group-text header-search-icon">
          <svg height="15" width="15" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <defs/>
            <title>search</title>
            <circle cx="8.5" cy="8.5" fill="none" r="8" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
            <line fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" x1="14.156" x2="23.5" y1="14.156" y2="23.5"/>
          </svg>
          </span>
        </div>
        <Form.Control
          type="search"
          className="form-control placeholder-text form-control-background"
          aria-label="Search"
          // TODO: Remover underline
          // disableUnderline={true}
          ref={(el) => setSearchBoxRef(el)}
        />
      </Form.Group>
    );
  }

  function getHomePage() {
    return (
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={5}>
            <h1 className="home-title-text mb-2">{t("HOME_TITLE")}</h1>
            <span className="home-subtitle-text mb-3">
              {t("HOME_SUB_TITLE")}
            </span>
            {/* TODO: COMMENT HOM */}
            <div className="mb-2">{getSearchBox()}</div>
          </Col>
          <Col xs={12} md={7}>
            <img
              className="w-100"
              src={require("../../assets/custom/svg/homeImage.svg")}
              alt="Mais"
            />
          </Col>
        </Row>
      </Container>
    );
  }

  // function changeLanguage(newLang: any){
  //   i18n.changeLanguage(newLang)
  //   window.location.reload()
  // }

  let logoClassName = "header-logo ";
  switch (type) {
    case "Clean":
      logoClassName = "header-logo center-logo ";
      break;
    case "Home":
    case "Process":
    default:
      logoClassName = "header-logo ";
      break;
  }

  let headerLogoSizes = [4, 3];
  switch (type) {
    case "Clean":
      headerLogoSizes = [12, 12];
      break;
    case "Home":
    case "Process":
    default:
      headerLogoSizes = [4, 3];
      break;
  }

  return (
    <div id="navbar">
      <header>
        <div className="header-container mx-lg-5 my-1">
          <Row className="header-itens justify-content-between">
            <Col
              xs={headerLogoSizes[0]}
              lg={headerLogoSizes[1]}
              className="order-0 order-lg-0 p-2"
            >
              <div className={logoClassName}>
                <Link to="/">
                  <img
                    className="d-none d-md-block header-logo-img-large"
                    src={require("../../assets/custom/svg/headerLogo.svg")}
                    alt="Logo"
                  />
                  <img
                    className="d-block d-md-none"
                    src={require("../../assets/custom/svg/header-logo-small.png")}
                    alt="Logo"
                  />
                </Link>
              </div>
            </Col>

            {/* TODO: COMMENT HOM */}
            {type === "Process" && hasSearchBox ? (
              <Col xs={12} lg={6} className="order-2 order-lg-1 p-2">
                {getSearchBox()}
              </Col>
            ) : null}

            {!(type === "Clean") ? (
              <Col
                xs={8}
                lg={3}
                className="order-1 order-lg-2 header-right-buttons p-2"
              >
                <div className="d-flex align-content-center justify-content-end">

                  <div className="mr-1">
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip id="tooltip-login">
                          {t("NAVBAR_TOOL_TIP_NEW_PROCESS")}
                        </Tooltip>
                      }
                    >
                      <Link
                        to={{
                          pathname: "/bpmn-editor",
                          state: {
                            isUpload: true
                          }
                        }}>
                        <Button as="div" className="navbar-circular-button">
                          <svg height="20" width="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <defs/>
                            <title>add</title>
                            <line fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" x1="12" x2="12" y1="1" y2="23"/>
                            <line fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" x1="23" x2="1" y1="12" y2="12"/>
                          </svg>
                        </Button>
                      </Link>
                    </OverlayTrigger>
                  </div>
                  {user ?
                    <div className="ml-2" key={user?.code}>
                      <OverlayTrigger
                        trigger={["click"]}
                        placement="bottom"
                        show={userPopoverShow}
                        overlay={
                          <Popover id="popover-user-buttons">
                            <Popover.Content>
                              <Link
                                className="text-user-dropdown clickable"
                                to={`/user-${user?.code}`}
                              >
                                {t("NAVBAR_USER_MENU_MY_PROCESSES")}
                              </Link>
                            </Popover.Content>
                            <Popover.Content>
                              <span className="text-user-dropdown clickable" onClick={() => { /*setUserPopoverShow(false);*/ logout(); }}>
                                {t("NAVBAR_USER_MENU_EXIT")}
                              </span>
                            </Popover.Content>
                          </Popover>
                        }
                        onToggle={setUserPopoverShow}
                      >
                        {user.photo && user.photo._id ? (
                          <Image
                            roundedCircle
                            className="circular-avatar-navbar clickable"
                            src={utils.getImageUrl(
                              user._class._id,
                              user.code,
                              user.photo._id,
                              user.token
                            )}
                            alt={user.photo.name}
                          />
                        ) : (
                          <Image
                            roundedCircle
                            className="circular-avatar-navbar clickable"
                            src={require("../../assets/custom/svg/user_icon.svg")}
                            alt="Usuário"
                          />
                        )}
                      </OverlayTrigger>
                    </div>
                    :
                    <div className="ml-2">
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip id="tooltip-login">
                            {t("NAVBAR_TOOL_TIP_SIGN_IN")}
                          </Tooltip>
                        }
                      >
                        <Link to="/login">
                          <Image
                            roundedCircle
                            className="circular-avatar-navbar drop-profile"
                            src={require("../../assets/custom/svg/user_icon.svg")}
                            alt="Usuário"
                          />
                        </Link>
                      </OverlayTrigger>
                    </div>
                  }

                  {/* TODO: COMMENT HOM */}
                  <div>
                    <Form.Control
                      as="select"
                      className="ml-2 language-dropdown"
                      id={"languageOrder"}
                      value={language}
                      onChange={(event) => {
                        let lang = event.target.value;
                        if (alternativeUrls?.hasOwnProperty(lang.substring(0, 2))) {
                          let url = new URL(alternativeUrls[lang.substring(0, 2)]);
                          history.push(url.href.replace(url.origin, ''));
                        }
                        i18n.changeLanguage(lang);
                      }}
                    >
                      {/*  {

                        //Criar tabela com referencias a um idioma e um país.
                        languages.forEach((lang) => {

                          switch(lang.code){
                            case 'pt':
                              <option value="pt-BR">PT</option>
                            break;
                            case 'en':
                              <option value="en-US">EN</option>
                            break;
                            case 'es':
                              <option value="es-ES">ES</option>
                            break;
                          }
                        })
                      } */}

                      <option value="pt-BR">PT</option>
                      <option value="en-US">EN</option>
                      <option value="es-ES">ES</option>
                    </Form.Control>
                  </div>
                </div>
              </Col>
            ) : null}
          </Row>
        </div>
        {type === "Home" ? getHomePage() : null}
      </header>
    </div>
  );
};
export default NavBar;
