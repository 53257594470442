import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import DynamicHead from "../../../components/dynamicHead/dynamicHead";
import Footer from '../../../components/footer/footer';
import NavBar from "../../../components/navBar/navBar";
import { getPortal as getPortalPB, getSiteConfig } from '../../../services/sendRequest';
import utils from '../../../utils';
import ResourcesPostGrid from "../_components/post-grid/post-grid";
import ResourcesPostNav from "../_components/post-nav/post-nav";
import { iCatalogByLanguage } from '../_models/iCatalogByLanguage';
import { IPost } from '../_models/iPost';
import { ProcessBox } from "../_models/ProcessBox";
import { ServiceDesk } from "../_models/ServiceDesk";
import { extractPendingContent, getLeafs, getPortal, getResourceCatalogs, SDConfig } from '../_services/api';
import { post2IPost } from '../_services/utils';

const ResourcesHome = (props: any) => {

    const pageSize = 9;
    const history = useHistory();

    const [pageIndex, setPageIndex] = useState(0);
    const [totalPosts, setTotalPosts] = useState(0);
    const [posts, setPosts] = useState<IPost[]>([]);
    const [catalogs, setCatalogs] = useState<iCatalogByLanguage | null>(null);
    const [searchText, setSearchText] = useState('');
    const [headerTitle, setHeaderTitle] = useState('');
    const [t, i18n] = useTranslation();
    const [placeholder, setPlaceholder] = useState<string>(t('RESOURCES_HOME_SEARCH_TITLE'));
    const [alternativeUrls, setAlternativeUrls] = useState<Array<any>>([]);
    const [portal, setPortal] = useState<ServiceDesk.Portal | null>(null);
    const [portalPB, setPortalPB] = useState<ProcessBox.Portal | null>(null);

    useEffect(() => {
        setPosts([]);

        getResourceCatalogs()
            .subscribe(catalogs => {
                if (catalogs.length) {
                    setCatalogs(catalogs[0]);
                }
            });

        getPortal()
            .subscribe(setPortal);

        getPortalPB()
            .then(setPortalPB);

        utils.setAlternativeUrls(i18n.language)
            .then(setAlternativeUrls);

    }, [i18n.language]);

    useEffect(() => {
        getSiteConfig({
            identifier: 'default',
            string: portal?.title
        }).then(siteConfig => setHeaderTitle(siteConfig.title));
    }, [portal]);

    useEffect(() => {
        if (catalogs) {
            const postReq = {
                itemId: catalogs.catalog._id,
                portal: SDConfig.portalId,
                params: {
                    pageSize: pageSize,
                    pageIndex: pageIndex,
                    sort: [{
                        publishedDate: {
                            order: 'desc'
                        }
                    }]
                },
              returnFields: ['_id', 'icon', 'identifier', 'description', 'name', 'author', 'publishedDate']
            };

            getLeafs(postReq)
                .subscribe(obj => {
                    obj.leafs = obj.leafs
                        // .map(post => {
                        //     delete post['parent'];
                        //     return post;
                        // })
                        .map(post2IPost);

                    setPosts(posts?.concat(obj.leafs) || obj.leafs);
                    setTotalPosts(obj.length);
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [catalogs, pageIndex]);

    useEffect(() => {
        if (t('RESOURCES_HOME_SEARCH_TITLE') !== placeholder)
            setPlaceholder(t('RESOURCES_HOME_SEARCH_TITLE'))
    }, [t, placeholder]);

    return (
        <div className="resources-home">

            <DynamicHead
                title={headerTitle}
                description={portal?.description}
                url={window.location.origin + window.location.pathname}
                imgUrl={extractPendingContent(portalPB?._class._id, portalPB?._id, portalPB?.imagemPrincipal)}
                alternativeUrlsData={alternativeUrls}
            />

            <NavBar type={"Process"} hasSearchBox={false} alternativeUrlsData={alternativeUrls} />

            <hr style={{ margin: '0px' }} />

            {/* Header and SearchBar */}
            <div className="container space-2">
                <div className="w-md-80 w-lg-75 text-center mx-md-auto">

                    <div className="mb-5">
                        <h1 className="display-4 font-size-md-down-5 font-weight-semi-bold">
                            {/* {t('RESOURCES_HOME_TITLE')} */}
                            {portal?.title}
                        </h1>
                        <p className="lead font-weight-normal">
                            {/* {t('RESOURCES_HOME_SUBTITLE')} */}
                            {portal?.description}
                        </p>
                    </div>

                    <form className="w-lg-75 mx-lg-auto"
                        onSubmit={(event) => {
                            event.preventDefault();
                            history.push(`/blog/search?q=${searchText}`)
                        }}
                    >
                        <div className="d-flex align-items-center search-section">
                            <label className="sr-only" htmlFor="searchBox">{t('RESOURCES_HOME_SEARCH_TITLE')}</label>
                            <div className="input-group">
                                <input type="text" className="form-control" name="search" id="searchBox"
                                    placeholder={placeholder} aria-label="Search Front blog"
                                    value={searchText}
                                    onChange={e => {
                                        setSearchText(e.target.value)
                                    }}
                                />
                            </div>
                            <button type="submit"
                                className="btn btn-primary text-nowrap ml-3 d-flex align-items-center"
                            >
                                {/* <span className="fas fa-search font-size-1 mr-2"></span> */}
                                <div className="mr-2">
                                    <svg height="15" width="15" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <defs/>
                                        <title>search</title>
                                        <circle cx="8.5" cy="8.5" fill="none" r="8" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round"/>
                                        <line fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" x1="14.156" x2="23.5" y1="14.156" y2="23.5"/>
                                    </svg>
                                </div>
                                {t('RESOURCES_HOME_SEARCH_BUTTON')}
                            </button>
                        </div>
                    </form>

                </div>
            </div>

            {/* Cards */}
            <div className="container space-2 space-bottom-lg-3">
                <ResourcesPostGrid posts={posts} />

                <div className="space-bottom-2"></div>

                <ResourcesPostNav {...({ pageIndex, pageSize, totalPosts, setPageIndex, postsCount: posts.length })} />
            </div>

            <Footer />
        </div>
    )
}

export default ResourcesHome;
