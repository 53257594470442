import React, { useState, useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

const ReviewLoader = (props) => {

  const [showFilter, setShowFilter] = useState()
  const [showTitle, setShowTitle] = useState()

  useEffect(() => {
    setShowFilter(props.showFilter)
    setShowTitle(props.showTitle)
  }, [props.showFilter, props.showTitle])

  let reviews: JSX.Element[] = [];

  for (let i = 0; i < 3; i++) {
    reviews.push(
      <div key={Math.random().toString(36).substring(5)}>
        <div className="media review-area"
        >
          <div className="avatar avatar-circle mr-3">
            <Image roundedCircle className="circular-avatar animated-background" />
          </div>

          <div className="media-body border-bottom pb-6 mb-6">
            {/* Comentário */}
            <div className="review-stars-container">
              <div className="author-name animated-background review-author"></div>
              <div className="review-stars-item animated-background review-stars"></div>
            </div>

            <div className="review-description-text animated-background review-line1"></div>
            <div className="review-description-text animated-background review-line1"></div>
            <div className="review-description-text animated-background review-line1"></div>

            <Container className="review-options-container flex-row">
              <div className="review-description-text mr-2 animated-background review-useful"></div>
              <div className="small mr-2 animated-background review-answer"></div>
              <div className="small animated-background review-answer"></div>
            </Container>

            {/* Resposta do autor */}
            {true ?
              <div className="card shadow-none border p-3 mt-4">
                <div className="media">
                  <div className="avatar avatar-circle mr-3">
                    <Image roundedCircle className="circular-avatar animated-background" />
                  </div>
                  <div className="media-body">
                    <span className="d-block text-dark font-weight-bold animated-background review-author"></span>
                    <div className="review-description-text animated-background review-line1"></div>
                    <div className="review-description-text animated-background review-line1"></div>
                    <div className="review-description-text animated-background review-line1"></div>
                  </div>
                </div>
              </div>
              : null}
          </div>
          {/* Fim da resposta do autor*/}
        </div>
      </div>
    )
  }

  return (
    <div id="review">
      <div id="reviewLoader" className="review-wrapper">
        <div className="animated reviews-container">
          <div
            className="tab-pane pt-6 review-header"
            id="pills-two-example1"
            role="tabpanel"
            aria-labelledby="pills-two-example1-tab"
          >
            {/* Início Cabeçalho dos comentários */}
            <Container>
              <Row className="reviews-option-filter justify-content-between">
                {showTitle ? (
                  <Col xs={12} sm={6} className="d-flex align-items-center mb-2 mb-sm-0">
                    <span className="text-f animated-background header-title"></span>
                  </Col>
                ) : null}
                {showFilter ? (
                  <Col xs={12} sm={6}>
                    {/* Início dos filtros para comentários */}
                    <div className="form-control-main-drop">
                      <div className="main-drop-loader animated-background"></div>
                    </div>
                  </Col>
                ) : null}
              </Row>
            </Container>
            {/* Fim dos filtros para comentários */}
          </div>
          {/* Comentário e respostas */}
          {reviews}
          {/* Fim Cabeçalho dos comentários */}
          <div className="text-center">
            <span>
              <div className="review-link-see-more-container">
                <div className="link-see-more animated-background review-see-more"></div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}


export default ReviewLoader;
