import React, { useEffect, useState } from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import GenericCarousel from "../../components/generic-carousel/generic-carousel";
import utils from "../../utils";
import { useTranslation } from "react-i18next";

const ProcessCarousel = ({images, processId, processIdentifier, processDescription}) => {

    const [imagesCarousel, setImagesCarousel] = useState<any[]>([]);
    const [imagesLightBox, setImagesLightBox] = useState<any[]>([]);
    const [, setCarouselRef] = useState<any>(null);
    const [imagesIndex, setImagesIndex] = useState(0);

    const { openLightbox } = useLightbox();

    const [t] = useTranslation();

    const options = {
        settings: {
            disablePanzoom: false,
            slideTransitionSpeed: 0.6,
        },
        buttons: {
            showDownloadButton: false,
            showNextButton: false,
            showPrevButton: false
        },
        caption: {
            showCaption: false
        },
        thumbnails: {
            showThumbnails: false
        }
    };

    useEffect(() => {
        if (images.length) {
            setImagesLightBox([{
                src: utils.getProcessImageUrl(
                    processIdentifier,
                    processId
                ),
                caption: processDescription
            }]);    
            setImagesCarousel([{ src: utils.getProcessImageUrl(
                processIdentifier,
                processId
            ), alt: `${t("BPMN_PROCESS")} - ${processDescription}`}]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [images]);

    return (

        <div id="carousel">
            <Card>
                <GenericCarousel
                    images={imagesCarousel}
                    ref={setCarouselRef}
                    onChange={setImagesIndex}
                    emptyText={t("PROCESS_IMAGE_NOT_FOUND")}
                />
                { images.length ?
                <Card.Body>
                    <div className="d-flex justify-content-end">
                        {images.length ?
                            <div className="d-flex flex-row">
                                {/* icone oculto pra manter o marcador de paginas centralizado */}
                                <div className="mr-2" style={{ visibility: 'hidden' }}>
                                    <svg height="20" width="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <defs/>
                                        <title>expand-1</title>
                                        <line fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" x1="15.5" x2="23.5" y1="8.499" y2="0.499"/>
                                        <line fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" x1="0.5" x2="8.5" y1="23.499" y2="15.499"/>
                                        <line fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" x1="15.5" x2="23.5" y1="15.499" y2="23.499"/>
                                        <line fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" x1="0.5" x2="8.5" y1="0.499" y2="8.499"/>
                                        <polyline fill="none" points="5.5 23.499 0.5 23.499 0.5 18.499" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
                                        <polyline fill="none" points="23.5 5.499 23.5 0.499 18.5 0.499" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
                                        <polyline fill="none" points="0.5 5.499 0.5 0.499 5.5 0.499" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
                                        <polyline fill="none" points="18.5 23.499 23.5 23.499 23.5 18.499" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="tooltip_ALT_MAXIMIZE_IMAGES">{t('ALT_MAXIMIZE_IMAGES')}</Tooltip>}
                                >
                                    <div
                                        className="clickable"
                                        onClick={() => openLightbox(imagesIndex)}
                                    >
                                        <svg height="20" width="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <defs/>
                                            <title>expand-1</title>
                                            <line fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" x1="15.5" x2="23.5" y1="8.499" y2="0.499"/>
                                            <line fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" x1="0.5" x2="8.5" y1="23.499" y2="15.499"/>
                                            <line fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" x1="15.5" x2="23.5" y1="15.499" y2="23.499"/>
                                            <line fill="none" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round" x1="0.5" x2="8.5" y1="0.499" y2="8.499"/>
                                            <polyline fill="none" points="5.5 23.499 0.5 23.499 0.5 18.499" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
                                            <polyline fill="none" points="23.5 5.499 23.5 0.499 18.5 0.499" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
                                            <polyline fill="none" points="0.5 5.499 0.5 0.499 5.5 0.499" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
                                            <polyline fill="none" points="18.5 23.499 23.5 23.499 23.5 18.499" stroke="#34434e" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </OverlayTrigger>
                            </div>
                        : null}
                    </div>
                </Card.Body>
                : null}
            </Card>

            <SRLWrapper options={options}>
                {imagesLightBox.map((images, index) => (
                    <img className="d-none" key={index} src={images.src} alt={images.caption} />
                ))}
            </SRLWrapper>

        </div >
    );
};

export default ProcessCarousel;