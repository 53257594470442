import React from "react";
import { Col, Row,Card } from 'react-bootstrap';

const CarouselLoader = ()=> {
  return (
    <div id="carousel">
      <Card id="carouselLoader" className="mr-auto">
        
        <div className="process-border animated animated-background img"></div>
        
        <Row className="slider-footer animated justify-content-between">
        
          <Col id="footer-item-1" className="slider-footer-item">
        
            <div>
              <div className="circular-button-loader animated-background"></div>
            </div>
              <div className="slider-numbers animated-background counts"></div>
            <div>
              <div className="circular-button-loader animated-background"></div>
            </div>
        
          </Col>
        
          <Col id="footer-item-2" className="slider-footer-item">
              <div className="animated-background counts"></div>
          </Col>
          
          <div className="process-border animated animated-background img"></div>
          
          <Row className="slider-footer animated justify-content-between">
          
            <Col id="footer-item-1" className="slider-footer-item">
          
              <div>
                <div className="circular-button-loader animated-background"></div>
              </div>
                <div className="slider-numbers animated-background counts"></div>
              <div>
                <div className="circular-button-loader animated-background"></div>
              </div>
          
            </Col>
          
            <Col id="footer-item-2" className="slider-footer-item">
                <div className="animated-background counts"></div>
            </Col>
            
            <Col className="animated-background zoom"></Col>
          </Row>
        </Row>
      </Card>
    </div>
  );
}

export default CarouselLoader;
