import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ProcessCarousel from "../../components/carousel/carousel";
import CarouselLoader from "../../components/carousel/carouselLoader";
import Description from "../../components/description/description";
import DescriptionLoader from "../../components/description/descriptionLoader";
import DynamicHead from "../../components/dynamicHead/dynamicHead";
import Footer from "../../components/footer/footer";
import MoreInformation from "../../components/moreInformation/moreInformation";
import NavBar from "../../components/navBar/navBar";
import ProcessCard from "../../components/processCard/processCard";
import ProcessCardLoader from "../../components/processCard/processCardLoader";
import ProcessNavBar from "../../components/processNavBar/processNavBar";
import ProcessNavBarLoader from "../../components/processNavBar/processNavBarLoader";
import ProcessView from "../../components/processView/processView";
import Review from "../../components/review/review";
import ReviewLoader from "../../components/review/reviewLoader";
import utils from "../../utils";
import { getAllProcess, getSiteConfig } from "../../services/sendRequest";
import { useTranslation } from "react-i18next";

const Process = (props) => {
  const [process, setProcess] = useState<any>(null);
  const [jsonld, setJsonld] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [t, i18n] = useTranslation();
  const [alternativeUrls, setAlternativeUrls] = useState<Array<any>>([]);

  //Props
  const processId = props.match.params.pId;

  const [title, setTitle] = useState("");

  useEffect(() => {    
    const filters = {
      query: {
        bool: {
          must: [{ term: { _id: processId } }],
        },
      },
    };
    getAllProcess(filters, {})
      .then(res => res.result.total ? setProcess(res.result.processes[0]) : setNotFound(true))
      .catch(error => setNotFound(true));
  }, [processId])

  useEffect(() => {
    async function fetchData() {
      let jsonld;
      if (process && !notFound) {
        jsonld = {
          "@context": "https://schema.org",
          "@type": "SoftwareApplication",
          name: process.description,
          operatingSystem: "All",
          applicationCategory: "https://schema.org/BusinessApplication",
          offers: {
            "@type": "Offer",
            price: "0",
          },
          author: {
            "@type": "Person",
            name: process.author.name,
          },
          description: process.informations,
        };

        if (process.reviewCount) {
          jsonld.aggregateRating = {
            "@type": "AggregateRating",
            ratingValue: process.rating,
            ratingCount: process.reviewCount,
            bestRating: 5,
            worstRating: 0,
          };
        }
      }
      setJsonld(jsonld);
    }
    if (process) {
      fetchData();
    }
  }, [i18n.language, process, props, notFound]);

  useEffect(() => {
    async function fetchData() {
      let siteConfig = await getSiteConfig({
        identifier: "default",
        string: process.description,
      });

      if (siteConfig) {
        setTitle(siteConfig.title);
      }
      setAlternativeUrls(await utils.setAlternativeUrls(i18n.language));
    }
    if (process) fetchData();
  }, [i18n.language, process]);

  return (
    <div id="process">
      {process && !notFound && jsonld ? (
        <DynamicHead
          title={title}
          description={process.informations}
          url={window.location.origin + window.location.pathname}
          imgUrl={utils.getProcessImageUrl(
            process._id,
            process.identifier
          )}
          jsonld={jsonld}
          alternativeUrlsData={alternativeUrls}
        />
      ) : (
        <DynamicHead title={title} notFound={true} />
      )}

      <div>
        <NavBar type={"Process"} searchURL="/search" hasSearchBox alternativeUrlsData={alternativeUrls} />

        <hr />

        {/* -- Para criar um grid com espaçamento vertical a seguinte estrutura pode ser utilizada
            <div class="row">
                <div class="flex-gap-column-[1-10]">
                  <div class="col"> */}

        <Container>
          {process ? (
            <Row className="my-2">
              <Col>
                <ProcessNavBar
                  name={process.description}
                  category={process.category}
                  viewCount={process.viewCount}
                  downloadCount={process.downloadCount}
                  processId={process._id}
                  isPublished={process.isPublished}
                />
              </Col>
            </Row>
          ) : !notFound ? (
            <Row className="my-2">
              <Col>
                <ProcessNavBarLoader />
              </Col>
            </Row>
          ) : null}

          {process &&
          process.language &&
          process.language.code !== i18n.language.substr(0, 2) ? (
            <p className="alert alert-warning" role="alert">
              {t("PROCESS_DIFFERENT_LANGUAGE")}
            </p>
          ) : null}
        </Container>

        <hr />

        <div className="content-background w-100 pt-3">
          <Container>
            <Row>
              {process ? (
                <div className="flex-gap-column-2">
                  <Col xs={12} lg={8} className="order-0">
                    <ProcessCarousel
                      images={process.images || []}
                      processId={process._id}
                      processIdentifier={process.identifier}
                      processDescription={process.description}
                    />
                  </Col>

                  <Col
                    xs={12}
                    md={5}
                    lg={4}
                    className="p-static p-sticky-md-top process-card order-1 order-md-2 order-lg-1"
                  >
                    <ProcessCard
                      processId={process._id}
                      author={process.author}
                      categoryName={process.category?.name}
                      rating={process.rating}
                      lastUpdate={process._lastUpdateDate}
                      ratingPercentage={process.ratingPercentage}
                      hasDownload={process.isPublished}
                    />
                  </Col>

                  <Col
                    xs={12}
                    md={7}
                    lg={8}
                    className="order-2 order-md-1 order-lg-2"
                  >
                    <Description
                      roles={process.roles}
                      content={process.informations}
                      tags={process.tags}
                      linkedProcesses={process.linkedProcesses}
                    />
                  </Col>

                  <Col
                    xs={12}
                    md={7}
                    lg={8}
                    className="order-3 order-md-2 order-lg-3"
                  >
                    <Review author={process.author} processId={process._id} />
                  </Col>
                </div>
              ) : !notFound ? (
                <>
                  <Col>
                    <CarouselLoader />
                  </Col>
                  <Col>
                    <ProcessCardLoader />
                  </Col>
                  <Col>
                    <DescriptionLoader />
                  </Col>
                  <Col>
                    <ReviewLoader showFilter={true} showTitle={true} />
                  </Col>
                </>
              ) : (
                <div className="text-center mt-4 w-100">
                  <h3 style={{ fontSize: "1.6rem", marginBottom: "1rem" }}>
                    Processo não encontrado
                  </h3>
                  <p> O Processo solicitado não existe ou foi removido</p>

                  <a href="/">
                    <button className="p-3 download-button-home">
                      <div className="m-auto d-flex flex-row flex-wrap justify-content-center">
                        <span className="download-text">
                          Voltar para a página inicial
                        </span>
                      </div>
                    </button>
                  </a>
                </div>
              )}
            </Row>

            <Row>
              <Col>
                {
                  process ? (
                    <div className="related-processes">
                      <ProcessView
                        name="Processos relacionados"
                        categoryId={process.category?._id}
                        processId={process._id}
                      />
                    </div>
                  ) : (
                    // : ( !notFound ?
                    //       <div className="review-wrapper">
                    //         <ProcessViewLoader pageSize={3} showFilter={false} showTitle={true} showSeeMore={false} />
                    //       </div>
                    <ProcessView name="Mais downloads" pageSize={3} />
                  )
                  //)
                }
              </Col>
            </Row>

            <MoreInformation />
          </Container>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Process;
