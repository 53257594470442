import React from "react";
import { useTranslation } from "react-i18next";
import ResourcesPostCard from "../post-card/post-card";

const ResourcesPostGrid = (props: any) => {
    const { posts, message } = props;
    const [t] = useTranslation();
    
    return (
        <div className="row">
            {posts.length !== 0 ? 
                (posts || []).map(post => (
                    <div key={post._id} className="col-md-6 col-lg-4 mb-5">
                        <ResourcesPostCard post={post} />
                    </div>
                ))
                : <p className="without-process-text py-5">{t(message)}</p>
            }
        </div>
    );
}

export default ResourcesPostGrid;