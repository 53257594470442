import React from "react";
import { Link } from "react-router-dom";
import { IPost } from "../../_models/iPost";
import ImageResponsive from "./image-responsive";

declare type PostCardProps = {
    post: IPost;
    [key: string]: any;
};

const ResourcesPostCard = (props: PostCardProps) => {

    const {
        post
    } = props;

    return (
        <article className="post-card card shadow h-100">
            <Link to={`/blog/${post.identifier}-${post._id}`}>
                <ImageResponsive className="card-img-top" src={post.iconURL} alt={post.name} aspect={16/9}></ImageResponsive>
            </Link>
            <div className="card-body p-5">

                <Link to={`/blog/${post.category.identifier}-${post.category._id}`}
                    className="d-inline-block text-muted font-weight-medium text-uppercase small mb-2"
                >{post.category.name}</Link>

                <h2 className="h5 font-weight-medium">
                    <Link to={`/blog/${post.identifier}-${post._id}`}>{post.name}</Link>
                </h2>

                <p className="card-post-description">
                    {post.description}
                </p>

            </div>

            <div className="card-footer border-0 pt-0 pb-1 px-0 mx-5">
                <div className="media align-items-center">
                    {/* author image */}
                    {/* <div className="u-sm-avatar u-sm-avatar--bordered rounded-circle" data-toggle="tooltip" data-placement="top" title="" data-original-title="Nataly Gaga">
                        <img className="img-fluid rounded-circle" src="../../assets/img/100x100/img1.jpg" alt="Image Description" />
                    </div> */}
                    {/* <div className="media-body d-flex justify-content-end text-muted font-size-1 ml-2">
                        {moment(new Date(post._lastUpdateDate)).format("DD/MM/YYYY")}
                    </div> */}
                </div>
            </div>
        </article>
    );
}

export default ResourcesPostCard;