import axios from "axios";

// Objeto axios para realizar requisições no SYDLE ONE a partir da fachada do processBox

export const axiosResourcesAPI = (user?: any) => {
  return axios.create({
    baseURL: process.env.REACT_APP_SYDLE_ONE_API + 'processBox/',
    headers: {
      "Authorization": `Bearer ${user?.token || process.env.REACT_APP_SYDLE_ONE_SITE_TOKEN}`,
      "Content-Type": "application/json",
      'accept-language': 'pt'
    }
  });
}

export function axiosAPI(user?: any) {
  return axios.create({
    baseURL: process.env.REACT_APP_SYDLE_ONE_API + 'processBox/com.sydle.processbox/processBoxAPI/',
    headers: {
      "Authorization": `Bearer ${user?.token || process.env.REACT_APP_SYDLE_ONE_SITE_TOKEN}`,
      "Content-Type": "application/json",
      'accept-language': 'pt'
    }
  })
}
