import React, { useEffect, useState } from "react";
import { animateScroll as scroll } from 'react-scroll';

const ResourcesPostNav = (props: any) => {

    const {
        pageIndex,
        // pageSize,
        totalPosts,
        setPageIndex,
        postsCount
    } = props;

    // const totalPages = Math.ceil(totalPosts / pageSize);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(false);
    }, [postsCount]);

    if(!totalPosts) {
        return <></>
    }

    return (
        <nav aria-label="Page navigation">
            {/* <ul className="pagination mb-0">

                <li className="page-item mr-auto">
                    <div className="page-link" aria-label="Previous"
                        onClick={() => setPageIndex(Math.max(pageIndex - 1, 0))}
                    >
                        <span aria-hidden="true">«</span>
                        <span className="d-none d-sm-inline-block ml-1">Prev</span>
                    </div>
                </li>

                {[...Array(totalPages).keys()].map(i => (
                    <li className={'page-item ' + (i === pageIndex ? 'active' : '')} key={i}>
                        <div className="page-link"
                            onClick={() => setPageIndex(i)}
                        >{i + 1}</div>
                    </li>
                ))}

                <li className="page-item ml-auto">
                    <div className="page-link" aria-label="Next"
                        onClick={() => setPageIndex(Math.min(pageIndex + 1, totalPages - 1))}
                    >
                        <span className="d-none d-sm-inline-block mr-1">Next</span>
                        <span aria-hidden="true">»</span>
                    </div>
                </li>

            </ul> */}

            <div className="d-flex justify-content-center">

            { postsCount === totalPosts ?
                <button className="btn btn-primary"
                    onClick={() => scroll.scrollToTop({ duration: 1500 })}
                >
                    Voltar ao topo
                </button>
            :
                <button className="btn btn-primary"
                    onClick={() => {
                        setPageIndex(pageIndex + 1);
                        setIsLoading(true);
                    }}
                >
                    {isLoading ? 'Loading...' : 'Ver mais'}
                </button>
            }

            </div>
        </nav>
    );
}

export default ResourcesPostNav;