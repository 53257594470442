import React, { useEffect, useState } from "react";
import DynamicHead from "../../../components/dynamicHead/dynamicHead";
import Footer from "../../../components/footer/footer";
import NavBar from "../../../components/navBar/navBar";
import ResourcesPostGrid from "../_components/post-grid/post-grid";
import ResourcesPostNav from "../_components/post-nav/post-nav";
import { IPost } from "../_models/iPost";
import { getLeafs, SDConfig } from "../_services/api";
import { post2IPost } from "../_services/utils";
import { useParams } from "react-router-dom";
import { getSiteConfig } from "../../../services/sendRequest";
import { useTranslation } from "react-i18next";
import utils from "../../../utils";

const ResourcesCategory = (props: any) => {
  const { rId } = useParams<any>();

  const pageSize = 9;

  const [pageIndex, setPageIndex] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0);
  const [posts, setPosts] = useState<IPost[]>([]);
  const [category, setCategory] = useState<any>(null);
  const [headerTitle, setHeaderTitle] = useState("");
  const [alternativeUrls, setAlternativeUrls] = useState<Array<any>>([]);
  const [, i18n] = useTranslation();

  useEffect(() => {
    if (category) {
      getSiteConfig({
        string: category.name,
        identifier: "default",
      }).then((siteConfig) => setHeaderTitle(siteConfig.title));
    }
  }, [category]);

  useEffect(() => {
    const postReq = {
      itemId: rId,
      portal: SDConfig.portalId,
      config: {
        filters: [],
      },
      params: {
        pageSize: pageSize,
        pageIndex: pageIndex,
        sort: [
          {
            publishedDate: {
              order: "desc",
            },
          },
        ],
      },
      returnFields: ['_id', 'icon', 'identifier', 'description', 'name', 'author', 'publishedDate']
    };

    const s = getLeafs(postReq).subscribe((obj) => {
      obj.leafs = obj.leafs.map(post2IPost);
      setPosts(obj.leafs);
      setTotalPosts(obj.length);
    });

    return () => s.unsubscribe();
  }, [rId, pageIndex, i18n.language]);

  useEffect(() => {
    if (posts.length) {
      setCategory(posts[0].category);
    }
  }, [posts]);

  useEffect(() => {
    utils.setAlternativeUrls(i18n.language).then(setAlternativeUrls);
  }, [i18n.language]);

  if (!category || !posts) {
    return <div>Loading...</div>;
  }

  return (
    <div className="resources-category">
      <DynamicHead
        title={headerTitle}
        description={category?.description}
        url={window.location.origin + window.location.pathname}
        imgUrl=""
        alternativeUrlsData={alternativeUrls}
      />

      <NavBar type={"Process"} hasSearchBox={false} alternativeUrlsData={alternativeUrls} />

      <hr style={{ margin: "0px" }} />

      <div className="container space-2 space-bottom-lg-3">
        <div className="mb-4">
          <h1 className="display-4 font-size-md-down-5 font-weight-semi-bold">
            {category?.name}
          </h1>
          <p className="lead font-weight-normal">{category?.description}</p>
        </div>

        {/* Cards */}
        <ResourcesPostGrid posts={posts} />

        <div className="space-bottom-2"></div>

        <ResourcesPostNav
          {...{ pageIndex, pageSize, totalPosts, setPageIndex }}
        />
      </div>

      <Footer />
    </div>
  );
};

export default ResourcesCategory;
