import EasyMDE from 'easymde';
import React, { useEffect, useState } from 'react';
import marked from 'marked';
import SimpleMDEEditor from 'react-simplemde-editor';

class CustomRenderer extends marked.Renderer {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(options?: marked.MarkedOptions) {
    super(options);
  }

  // Funções Mantidas

  // list(body: string, ordered: boolean, start: number): string;
  // listitem(text: string): string;
  // paragraph(text: string): string;
  // strong(text: string): string;
  // em(text: string): string;
  // text(text: string): string;

  code(code: string, language: string | undefined, isEscaped: boolean): string {
    return code.replace(/[\u00A0-\u9999<>&]/gim, function (i) {
      return '&#' + i.charCodeAt(0) + ';';
    });
  }

  blockquote(quote: string): string {
    return quote;
  }

  html(html: string): string {
    return html.replace(/[\u00A0-\u9999<>&]/gim, function (i) {
      return '&#' + i.charCodeAt(0) + ';';
    });
  }

  heading(text: string, level: 1 | 2 | 3 | 4 | 5 | 6, raw: string, slugger: any): string {
    if (level === 2) {
      return super.heading(text, level, raw, slugger);
    }

    return raw;
  }

  hr(): string {
    return '';
  }

  checkbox(checked: boolean): string {
    return `${checked}`;
  }

  table(header: string, body: string): string {
    return `${header}\n${body}`;
  }

  tablerow(content: string): string {
    return content;
  }

  tablecell(content: string, flags: {
    header: boolean;
    align: 'center' | 'left' | 'right' | null;
  }): string {
    return content;
  }

  codespan(code: string): string {
    return code;
  }

  br(): string {
    return '\n';
  }

  del(text: string): string {
    return text;
  }

  link(href: string | null, title: string | null, text: string): string {
    return text;
  }

  image(href: string | null, title: string | null, text: string): string {
    return text;
  }
}

export const MarkdownParser = (text: string) => {
  const options: marked.MarkedOptions = {
    renderer: new CustomRenderer()
  };

  return marked(text, options);
}

const MarkdownEditor = (props) => {

  const [options, setOptions] = useState<EasyMDE.Options>({
    placeholder: '',
    spellChecker: false,
    shortcuts: {
      toggleBlockquote: null,
      cleanBlock: null,
      toggleHeadingSmaller: null,
      drawLink: null,
      toggleCodeBlock: null,
      drawImage: null,
      toggleHeadingBigger: null,
      toggleSideBySide: null,
      toggleFullScreen: null,
      toggleHeading2: 'Ctrl-H'
    },
    initialValue: '',
    renderingConfig: {
      codeSyntaxHighlighting: false
    },
    previewRender: MarkdownParser,
    toolbar: [{
      name: "heading-2",
      action: EasyMDE.toggleHeading2,
      className: "fa fa-header",
      title: "Título",
    }, {
      name: "bold",
      action: EasyMDE.toggleBold,
      className: "fa fa-bold",
      title: "Negrito",
    }, {
      name: "italic",
      action: EasyMDE.toggleItalic,
      className: "fa fa-italic",
      title: "Itálico",
    }, {
      name: "unordered-list",
      action: EasyMDE.toggleUnorderedList,
      className: "fa fa-list-ul",
      title: "Lista",
    }, {
      name: "ordered-list",
      action: EasyMDE.toggleOrderedList,
      className: "fa fa-list-ol",
      title: "Lista Numerada",
    }, "|", {
      name: "preview",
      action: EasyMDE.togglePreview,
      className: "fa fa-eye no-disable",
      title: "Pré-visualizar",
    }]
  });

  const [value, setValue] = useState('');

  window['setValue'] = setValue;

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    if(props.placeholder) {
      let temp = Object.assign({}, options);
      temp.placeholder = props.placeholder;
      setOptions(temp);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.placeholder]);

  useEffect(() => {
    props.onChange(value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className={'mde ' + props.className} key={JSON.stringify(options.placeholder)}>
      <SimpleMDEEditor
        value={value}
        options={options}
        onChange={setValue}
      />
    </div>
  );
}

export default MarkdownEditor;