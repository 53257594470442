import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const DynamicHead = (props) => {
  const {
    title,
    description,
    url,
    imgUrl,
    jsonld,
    notFound,
    alternativeUrlsData,
  } = props;

  const [, i18n] = useTranslation();

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={imgUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={imgUrl} />
        {jsonld ? (
          <script type="application/ld+json">{JSON.stringify(jsonld)}</script>
        ) : null}
        {notFound ? (
          <meta name="prerender-status-code" content="404"></meta>
        ) : null}
        <html lang={i18n.language} />
        {alternativeUrlsData?.slice(0, 3).map((altUrl, i) => {
          return (
            <link
              rel="alternate"
              href={altUrl.link}
              hrefLang={altUrl.lang}
              key={altUrl.lang}
            />
          );
        })}
        {alternativeUrlsData?.length && (
          <link
            rel="canonical"
            href={alternativeUrlsData[3].link}
            key="canonical"
          />
        )}
      </Helmet>
    </>
  );
};

export default DynamicHead;
