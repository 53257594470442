import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { getResourceCatalogs, SDConfig, getLeafs, getCatalogs } from '../../pages/resources/_services/api';
import { iCatalogByLanguage } from '../../pages/resources/_models/iCatalogByLanguage';
import { ServiceDesk } from "../../pages/resources/_models/ServiceDesk";

const Footer = (props) => {

  const [t, i18n] = useTranslation();
  const {
    disableLogo,
    className
  } = props;

  const [catalogs, setCatalogs] = useState<iCatalogByLanguage | null>(null);
  const [terms, setTerms] = useState<any[]>([]);
  const [resourcesCatalog, setResourcesCatalog] = useState<ServiceDesk.Catalog | null>(null);

  useEffect(() => {
    const s1 = getResourceCatalogs()
      .subscribe(catalogs => {
        if (catalogs.length) {
          setCatalogs(catalogs[0]);
        }
      });

    return () => {
      s1.unsubscribe();
    };
  }, [i18n.language]);

  useEffect(() => {
    if (catalogs) {
      const postReq = {
        itemId: catalogs.terms._id,
        portal: SDConfig.portalId,
        params: {},
        returnFields: ['_id', 'name', 'identifier', 'order']
      };

      const s = getLeafs(postReq)
        .subscribe(obj => setTerms(obj.leafs.sort((a, b) => {
          return a.order - b.order;
        })));

      const s2 = getCatalogs({ idCatalog: catalogs.catalog._id })
        .subscribe(catalogArray => setResourcesCatalog(catalogArray?.[0]));

      return () => {
        s.unsubscribe();
        s2.unsubscribe();
      };
    }
  }, [catalogs, i18n.language]);

  return (
    <div id="footer" className={className}>
      <div className={('footer-container mb-3 ' + (disableLogo ? ' smallFooter' : ''))}>
        <div className="footer-content footer-text">
          {!disableLogo ? (
            <Link className="d-inline-flex align-items-center mb-2 clickable" to="/">
              <img src={require("../../assets/custom/svg/footer-logo.svg")} alt="Logo" />
            </Link>
          ) : null}
          <div className=""> © ProcessBox {(new Date()).getFullYear()}. {t('ALL_RIGHTS_RESERVED')}</div>
          <div>
            {terms?.map((term, i) => (
              <Link key={i} className="link" to={`/blog/${term.identifier}-${term._id}`}>
                {i ? <>&nbsp;-&nbsp;</> : null}
                {term.name}
              </Link>
            ))}
            <Link className="link" to="/blog">
              {<>&nbsp;-&nbsp;</>}
              {resourcesCatalog?.name}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
