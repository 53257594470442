import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLanguagePackage } from '../services/sendRequest';
import LanguageDetector from 'i18next-browser-languagedetector';
import { createContext, useEffect, useState } from 'react';
import React from 'react';

const I18nextContext = createContext({})


export function I18nextProvider(opts){
  
  const { children } = opts

  const [isLoading, setIsLoading] = useState(true)

  useEffect(()=>{
    i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'pt',
      debug: false,
      detection:{
        order: ['querystring', 'localStorage', 'navigator'],
        lookupLocalStorage: 'i18nextLng',
        lookupQuerystring: 'hl',
        caches: ['localStorage']
      },
      interpolation: {
        escapeValue: false,
      },
      react: {
        wait: true,
        useSuspense: false
      }
    }, ()=>{

      getLanguagePackage()
        .then((result)=>{
          result.resources.forEach((res: any) => {
            i18n.addResourceBundle(res.lang,'translation',res.resources.translation);
          });

          setIsLoading(false)
          
        })
    });
  }, [])

  return(
    <I18nextContext.Provider
      value={{}}
    >
      {isLoading ? null : children}

    </I18nextContext.Provider>
  )

}

export default I18nextProvider;