import Tagify from '@yaireo/tagify';
import React, { useEffect, useState } from 'react';

declare type TagsProps = {
    settings: any,
    value: string,
    onChange: any,

    showDropdown?: string
    autoFocus?: boolean,
    name?: string,
    className?: string,
    placeholder?: string,
    [key: string]: any
};

const Tags = (props: TagsProps) => {

    const {
        settings,
        value,
        onChange,
        showDropdown,
        ...attr
    } = props;

    const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
    const [tagify, setTagify] = useState<any>(null);

    useEffect(() => {
        if (inputRef && !tagify) {
            document.querySelectorAll('.input-tags tags.tagify').forEach(elem => {
                elem.remove();
            });
            setTagify(new Tagify(inputRef, settings || {}));
        }
    }, [inputRef, settings, tagify]);

    useEffect(() => {
        if(!tagify) return;
    
        tagify.loadOriginalValues(value);

    }, [tagify, value])


    return (
        <input
            ref={setInputRef}
            onChange={onChange}
            {...attr}
        />
    );
}

export default Tags;