import { User, IAuthState, initialAuthState } from './IAuthState';

type Action =
  | { type: 'INITIALISED' }
  | { type: 'COMPLETE'; user: User; }
  | { type: 'ERROR'; error: Error };

/**
 * Handles how that state changes in the `useAuth` hook.
 */
export const reducer = (state: IAuthState, action: Action): IAuthState => {
  switch (action.type) {
    case "INITIALISED":
      return {
        ...initialAuthState,
        isLoading: true,
        isAuthenticated: false
      };

    case "COMPLETE":
      return {
        ...initialAuthState,
        isLoading: false,
        isAuthenticated: !!(action.user),
        user: action.user
      };

    case "ERROR":
      return {
        ...initialAuthState,
        isLoading: false,
        isAuthenticated: false,
        error: action.error
      };
  }
};
