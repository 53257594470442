import React, { useState, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import DynamicHead from "../../components/dynamicHead/dynamicHead";
import Footer from "../../components/footer/footer";
import MoreInformation from "../../components/moreInformation/moreInformation";
import NavBar from "../../components/navBar/navBar";
import ProcessView from "../../components/processView/processView";
import utils, { ClassId, PortalId } from "../../utils";
import { getSiteConfig } from "../../services/sendRequest";
import { useTranslation } from "react-i18next";

const Home = () => {

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [imgUrl, setImgUrl] = useState('')
  const [alternativeUrls, setAlternativeUrls] = useState<Array<any>>([]);
  const [, i18n] = useTranslation();
  const isMounted = useRef(true);

  useEffect(() => {
    async function fetchData() {
      let siteConfig = await getSiteConfig({
        identifier: 'home'
      });
      if (isMounted.current && siteConfig) {
        setTitle(siteConfig.title)
        setDescription(siteConfig.description)
        setImgUrl(utils.getImageUrl(ClassId.portal, PortalId, siteConfig.img._id))
        setAlternativeUrls(await utils.setAlternativeUrls(i18n.language));
      }
    }
    fetchData();
    return () => { isMounted.current = false; }; // Para evitar memory leaks 
  }, [i18n.language]);

  return (
    <>
      <DynamicHead
        title={title}
        description={description}
        url={window.location.origin + window.location.pathname}
        imgUrl={imgUrl}
        alternativeUrlsData={alternativeUrls}
      />

      <div id="home">
        
        <div className="navbar-background">
          <NavBar type={"Home"} searchURL="/search" hasSearchBox alternativeUrlsData={alternativeUrls}/>
        </div>

        <div className="processes-background">
          <ProcessView name="Últimos processos cadastrados" />
        </div>

        <div className="more-information-background">
          <Container>
            <MoreInformation />
          </Container>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Home;
