import React from "react";
import { Badge } from "react-bootstrap";

const DescriptionLoader = ()=> {

  let contents: any[] = [];
  let roles: any[] = [];
  // Gera descrição (títulos e parágrafos)
  for (let i=0; i<2; i++){
    contents.push(
      <div key={Math.random().toString(36).substring(5)}>
        <div className="description-text">
          <div className="description-title">
            <div className="text-f animated-background title"></div>
          </div>
          <div className="animated-background line1"></div>
          <div className="animated-background line2"></div>
          <div className="animated-background line1"></div>
          <div className="animated-background line1"></div>
          <div className="animated-background line1"></div>
          <div className="animated-background line2"></div>
          <div className="animated-background line1"></div>
          <div className="animated-background line1"></div>
        </div>
      </div>
    );
  }

  // Gera papéis
  for (let i=0; i<5; i++){
    roles.push(
      <Badge key={ Math.random().toString(36).substring(5)} className="animated-background roles mr-2">{'     '}</Badge>
    )
  }
  return (
    <>
      <div id="descriptionLoader" className="description-body">
        {contents}
        <div className="text-f animated-background rolesTitle"></div>
        <div className="tag-container-loader">
        {roles}
        </div>
      </div>
    </>
  );
}

export default DescriptionLoader;
